import React from "react";
import Layout from "../../components/common/layout/layout";
import PartnersCornerComponent from "../../components/partners-corner/partnersCorner";

export default function PartnersCorner() {
    return (
        <Layout showBanner={false}>
            <PartnersCornerComponent />
        </Layout>
    );
}

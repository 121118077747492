import React from "react";

export default function Info() {
    return (
        <div className="portfolio_img col-md-12 text-center">
            <img src="images/portfolio_img.png" className="img-fluid" alt="" />
            <h2>Abdullah al mamun</h2>
            <h4>Managing Director</h4>
            <span>Eastern Bank ltd.</span>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of
                the printing and typesetting industry
            </p>
        </div>
    );
}

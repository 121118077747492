import React from "react";
import { Routes } from "./routes/routes";
// import About from "./pages/About/About";
// import AMA from "./pages/AMA/AMA";
// import BecomeAMember from "./pages/Become-A-Member/BecomeAMember";
// import Events from "./pages/Events/Events";
// import Home from "./pages/Home/Home";
// import LogIn from "./pages/Log-In/LogIn";
// import Magazine from "./pages/Magazine/Magazine";
// import MemberRegistration from "./pages/Member-Registration/MemberRegistration";
// import Membership from "./pages/Membership/Membership";
// import Publications from "./pages/Publications/Publications";
// import SignUp from "./pages/Sign-Up/SignUp";
// import SignUp from "./pages/Sign-Up/SignUp";

function App() {
    return <Routes />;
    // return <Home />;
    // return <AMA />;
    // return <About />;
    // return <BecomeAMember />;
    // return <Events />;
    // return <LogIn />;
    // return <Magazine />;
    // return <Membership />;
    // return <MemberRegistration />;
    // return <Publications />;
    // return <SignUp />;
}

export default App;

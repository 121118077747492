import { API } from "aws-amplify";
import { S3Image } from "aws-amplify-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getMember } from "../../../graphql/queries";

export default function Navbar() {
const history = useHistory();
const [userEmail, setUserEmail] = useState("");
const [memberData, setMemberData] = useState();
const [memberType, setMemberType] = useState("");
const [menueOption, setMenueOption] = useState(1);
const [path, setPath] = useState();

useEffect(async () => {
let email = localStorage.getItem("email");
setUserEmail(email);
setMemberType(localStorage.getItem("memberType"));
if (email) {
let userId = localStorage.getItem("userId");
let userData = await API.graphql({
query: getMember,
variables: {
id: userId,
},
});
userData = userData.data.getMember;
if (userData) {
setMemberData(userData);
}
}

}, []);
useEffect(async ()=>{
    setPath(window.location.pathname);
},[path])

const logout = () => {
localStorage.clear();
history.push("/");
};

function openNav() {
document.getElementById("mySidenav").style.width = "100%";
}

function closeNav() {
document.getElementById("mySidenav").style.width = "0";
}

function openNav2() {
document.getElementById("myprofilenav").style.width = "100%";
}

function closeNav2() {
document.getElementById("myprofilenav").style.width = "0";
}

return (
<div className={path == "innovation-hackathon"?"nav_bg innovation-hackathon col-xl-12 col-md-12 col-sm-12 col-xs-12":"nav_bg col-xl-12 col-md-12 col-sm-12 col-xs-12"}>
    <nav className="navbar">
        <div className="left_toggle">
            <img src="images/menu.png" className="icon" alt="" onClick={openNav} />
        </div>
        <div className="container-fluid">
            <div className="col-md-2 col-sm-2">
                <span className="brand_logo">
                    <a href="/">
                        <img src="images/logo.png" alt="" className="logo" />
                    </a>
                    {/* <!-- Button trigger modal --> */}
    {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
  </button> */}

    {/* <!-- Modal --> */}
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl loadingPopUpModal">
            <div className="modal-content">
                <div className="modal-body">
                    <button type="button" className="PopUp_close" >
                        <i class="fas fa-times-circle"></i>
                    </button>
                    <img src="images/pop-up-add.jpg" alt="Poput" className="loading_img" />
                    <a target="_blank" href="http://shorturl.at/guPW7" type="button" className="reg_button" aria-label="reg" rel="noreferrer">reg</a>
                </div>
                {/* <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> */}
            </div>
        </div>
    </div>
                </span>
            </div>

            <div id="mySidenav" className="sidenav">
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
                    ×
                </a>

                <div className="Side_menu_item xs-hidden">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="img">
                                {/* <img src="images/side_navbar.png" alt="images" className="img-fluid w-100" /> */}
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="Menu_item">
                                <h3>Menu</h3>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a onMouseEnter={()=> setMenueOption(2)}>About Us</a>
                                    </li>
                                    <li>
                                        <a href="" onMouseEnter={()=> setMenueOption(3)}>
                                            Activity Timeline
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" onMouseEnter={()=> setMenueOption(4)}
                                            >
                                            Events
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/magazine" onMouseEnter={()=> setMenueOption(5)}
                                            >
                                            Publications
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/member-list" onMouseEnter={()=> setMenueOption(6)}
                                            >
                                            Member
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/partners-corner" onMouseEnter={()=> setMenueOption(7)}
                                            >
                                            Partners
                                        </a>
                                    </li>
                                    <li>
                                        <a  onMouseEnter={()=> setMenueOption(8)}
                                            >
                                            Gallery
                                        </a>
                                    </li>
                                    <li>
                                        <div className="social_icon">
                                            <a target="_blank" href="https://www.facebook.com/bangladeshctoforum" rel="noreferrer">
                                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z"
                                                        fill="black"></path>
                                                </svg>
                                            </a>
                                            <a target="_blank"
                                                href="https://www.youtube.com/channel/UCJ-KHx0LUWWgqz-xujUOFmA " rel="noreferrer">
                                                <svg width="20" height="23" viewBox="0 0 20 23" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18.9789 6.11088C18.7628 5.18713 18.1295 4.45895 17.3264 4.21021C15.8591 3.74854 9.99001 3.74854 9.99001 3.74854C9.99001 3.74854 4.12113 3.74854 2.65388 4.19271C1.86619 4.44118 1.21742 5.18726 1.00136 6.11088C0.615234 7.79808 0.615234 11.2971 0.615234 11.2971C0.615234 11.2971 0.615234 14.8138 1.00136 16.4834C1.21765 17.407 1.85074 18.1352 2.654 18.3839C4.13658 18.8457 9.99023 18.8457 9.99023 18.8457C9.99023 18.8457 15.8591 18.8457 17.3264 18.4015C18.1296 18.1529 18.7628 17.4247 18.9791 16.5011C19.3651 14.8138 19.3651 11.3149 19.3651 11.3149C19.3651 11.3149 19.3806 7.79808 18.9789 6.11088V6.11088ZM8.12141 14.5297V8.06459L13.0019 11.2971L8.12141 14.5297Z"
                                                        fill="black" />
                                                </svg>
                                            </a>
                                            <a target="_blank" href="https://www.linkedin.com/company/79689034/admin/" rel="noreferrer">
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M3.125 14V4.65625H0.21875V14H3.125ZM1.65625 3.40625C2.59375 3.40625 3.34375 2.625 3.34375 1.6875C3.34375 0.78125 2.59375 0.03125 1.65625 0.03125C0.75 0.03125 0 0.78125 0 1.6875C0 2.625 0.75 3.40625 1.65625 3.40625ZM13.9688 14H14V8.875C14 6.375 13.4375 4.4375 10.5 4.4375C9.09375 4.4375 8.15625 5.21875 7.75 5.9375H7.71875V4.65625H4.9375V14H7.84375V9.375C7.84375 8.15625 8.0625 7 9.5625 7C11.0625 7 11.0938 8.375 11.0938 9.46875V14H13.9688Z"
                                                        fill="black"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {menueOption == 2 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>About</h3>
                                <ul>
                                    <li>
                                        <a href="/about">CTO Forum in Brief</a>
                                    </li>
                                    <li>
                                        {/* <a href="/Executive-committee">Executive Committee</a> */}
                                    </li>
                                    <li>
                                        <a href="/inquiry">Inquiry</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        {/* {menueOption == 2 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Activity Timeline</h3>
                                <ul>
                                    <li>
                                        <a href="">CTO Forum in Brief</a>
                                    </li>
                                    <li>
                                        <a href="">Executive Committee</a>
                                    </li>
                                    <li>
                                        <a href="">Gallery</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )} */}
                        {menueOption == 4 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Events</h3>
                                <ul>
                                    <li>
                                        <a target="_blank">
                                            Innovation Hackathon
                                        </a>
                                        <ul className="child_Sub_menu">
                                            <li>
                                                <a href="/innovation-hackathon-2021" target="_blank">
                                                    Innovation Hackathon 2021
                                                </a>
                                            </li>
                                            <li>
                                                {/* <a href="/innovation-hackathon-2022" target="blank"> */}
                                                <a href="https://www.portal.ctoforumbd.org/innovation-hackathon-2022/" target="_blank">
                                                    Innovation Hackathon 2022
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a target="_blank" href="/other-events">
                                            Other Events 
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        {menueOption == 5 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Publications</h3>
                                <ul>
                                    <li>
                                        <a href="/publications">CTO Magazine</a>
                                    </li>
                                    <li>
                                        <a href="">Other Publication</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        {menueOption == 6 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Member</h3>
                                <ul>
                                    <li>
                                        <a href="/member-list">Our Members</a>
                                    </li>
                                    <li>
                                        <a href="/member-registraton">
                                            Membership Registration
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        {menueOption == 7 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Partners</h3>
                                <ul>
                                    <li>
                                        <a href="/partners-corner">Partner's Corner</a>
                                    </li>
                                    <li>
                                        <a href="/partners-corner">
                                            Partnership Registration
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        )}
                        {menueOption == 8 && (
                        <div className="col-lg-4">
                            <div className="Menu_item Sub_menu">
                                <h3>Gallery</h3>
                                <ul>
                                    <li>
                                        <a href="">
                                            Photo Gallery
                                        </a>
                                        <ul className="child_Sub_menu">
                                            <li>
                                                <a href="/photo-gallery" target="blank">
                                                    CTO Tech Summit 2020
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <li>
                                        <a href="">
                                            Video Gallery
                                        </a>
                                        <ul className="child_Sub_menu">
                                            <li>
                                                <a href="/video-gallery" target="blank">
                                                    CTO Tech Summit 2020
                                                </a>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        )}
                    </div>
                </div>


                

                <div className="Side_menu_item xl-hidden">
                    <div className="col-md-2 col-sm-2">
                        <span className="brand_logo">
                            <a href="/">
                                <img src="images/logo.png" alt="" className="logo" />
                            </a>
                        </span>
                    </div>
                    <div className="col-md-3">
                        {/* <ul className="nav_item">
                            <li className="border_nav hidden-xs hidden-sm">
                                <Link to="/comming-soon">AMA</Link>
                            </li>
                            <li className="border_nav">
                                <a href="/comming-soon">resource feed</a>
                            </li>
                        </ul> */}
                    </div>
                    <div className="Menu_item">
                        <h3>Menu</h3>
                        <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link active dropdown" type="button" id="dropdownMenuButton1"
                                    data-toggle="dropdown" aria-expanded="false">
                                    About Us
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.01726 0L2.48438 1.53293L7.45144 6.5L2.48438 11.4671L4.01726 13L10.5173 6.5L4.01726 0Z"
                                            fill="#CCCCCC"></path>
                                    </svg>
                                </a>
                                <ul className="sub_menu dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item" href="/others_pages/about.html">
                                            CTO Forum in Brief
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item"
                                            href="/others_pages/about_what we do.html">
                                            Executive Committee
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item"
                                            href="/others_pages/about_how_we_do.html ">
                                            Gallery
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown" type="button" id="dropdownMenuButton1"
                                    data-toggle="dropdown" aria-expanded="false">
                                    Activity Timeline
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.01726 0L2.48438 1.53293L7.45144 6.5L2.48438 11.4671L4.01726 13L10.5173 6.5L4.01726 0Z"
                                            fill="#CCCCCC"></path>
                                    </svg>
                                </a>
                                <ul className="sub_menu dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item" href="/others_pages/about.html">
                                            1
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item"
                                            href="/others_pages/about_what we do.html">
                                            2
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link dropdown-item"
                                            href="/others_pages/about_how_we_do.html ">
                                            3
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/others_pages/services.html">
                                    Events - Active Class
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/magazine">
                                    Publications
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/member-list">
                                    Member
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/partners-corner">
                                    Partners
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="col-md-3 xs-hidden">
                {/* <ul className="nav_item">
                    <li className="border_nav hidden-xs hidden-sm">
                        <Link to="/comming-soon">AMA</Link>
                    </li>
                    <li className="border_nav">
                        <a href="/comming-soon">resource feed</a>
                    </li>
                </ul> */}
            </div>

            <div className="col-md-7 col-sm-10">
                <ul className="nav_item float-right">
                    {userEmail ? (
                    <>
                        <ul className="nav_item float-right after_login_user">
                            {/* <li className="message xs-hidden">
                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M22.2473 0.879883H2.71266C1.21436 0.879883 0 2.10231 0 3.60077V16.399C0 17.9018 1.21873 19.1199 2.71266 19.1199H22.2473C23.7456 19.1199 24.96 17.8975 24.96 16.399V3.60077C24.96 2.10231 23.7413 0.879883 22.2473 0.879883ZM12.9518 9.43687C12.8164 9.54202 12.6504 9.60336 12.48 9.60336C12.3096 9.60336 12.1436 9.5464 12.0082 9.43687L4.05371 3.10566H20.9107L12.9518 9.43687ZM22.2473 16.8985H2.71266C2.44183 16.8985 2.21905 16.675 2.21905 16.4034V4.49458L10.6279 11.1851C11.1521 11.6013 11.8117 11.8335 12.48 11.8335C13.1483 11.8335 13.8079 11.6057 14.3321 11.1851L22.7409 4.49458V16.4034C22.7409 16.675 22.5182 16.8985 22.2473 16.8985Z"
                                        fill="black" />
                                </svg>
                            </li>
                            <li className="notification xs-hidden">
                                <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.0008 24C12.3208 24 13.4008 22.92 13.4008 21.6H8.60078C8.60078 22.92 9.68078 24 11.0008 24ZM18.8008 16.8V10.2C18.8008 6.48 16.2808 3.48 12.8008 2.64V1.8C12.8008 0.84 11.9608 0 11.0008 0C10.0408 0 9.20078 0.84 9.20078 1.8V2.64C5.72078 3.48 3.20078 6.48 3.20078 10.2V16.8L0.800781 19.2V20.4H21.2008V19.2L18.8008 16.8ZM16.4008 18H5.60078V10.2C5.60078 7.2 8.00078 4.8 11.0008 4.8C14.0008 4.8 16.4008 7.2 16.4008 10.2V18Z"
                                        fill="black" />
                                </svg>
                            </li> */}
                            <li className="iser_img_name xs-hidden">
                                {memberData?.profilePhotoUrl ? (
                                <S3Image imgKey={memberData.profilePhotoUrl} />
                                ) : (
                                <div className="demo_img">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        fill="#eeeeee" stroke="none">
                                        <path d="M2370 5105 c-287 -40 -550 -173 -761 -384 -397 -397 -506 -972 -283
-1491 129 -300 394 -565 694 -694 427 -184 901 -143 1285 111 133 88 290 245
378 378 299 453 299 1037 0 1490 -88 133 -245 290 -378 378 -278 183 -607 258
-935 212z"></path>
                                        <path d="M2125 2114 c-402 -37 -725 -160 -1037 -395 -112 -84 -298 -273 -384
-389 -241 -328 -365 -674 -390 -1090 -6 -106 -5 -119 15 -158 14 -28 35 -49
61 -62 39 -20 54 -20 2170 -20 2116 0 2131 0 2170 20 26 13 47 34 61 62 20 39
21 52 15 158 -31 514 -220 943 -576 1300 -301 304 -641 480 -1070 556 -78 14
-176 17 -545 19 -247 2 -468 1 -490 -1z"></path>
                                    </g>
                                </svg>
                                </div>

                                )}

                                <div className="user_name">
                                    <span className="name xs-hidden">Hello,</span>{" "}
                                    <span className="dropdown-toggle js-toggle-menu xs-hidden" href="#"
                                        id="user_name_navbarDropdown" role="button" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <span className="xs-hidden">
                                            {memberData?.fullName}
                                        </span>
                                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2.63443L1.76877 0.865723L7.5 6.59695L13.2312 0.865723L15 2.63443L7.5 10.1344L0 2.63443Z"
                                                fill="#CCCCCC" />
                                        </svg>
                                    </span>
                                    <button className="navbar-toggler xl-hidden" type="button" data-toggle="collapse"
                                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon open">
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 2.63443L1.76877 0.865723L7.5 6.59695L13.2312 0.865723L15 2.63443L7.5 10.1344L0 2.63443Z"
                                                    fill="#CCCCCC" />
                                            </svg>
                                        </span>
                                    </button>
                                    {memberData?.userType == "User" ? (
                                    <div className="dropdown-menu" aria-labelledby="user_name_navbarDropdown">
                                        <a className="dropdown-item" href="/become-a-member">
                                            Apply for Membership
                                        </a>
                                        <a className="dropdown-item" href="/member-edit">
                                            Profile Settings
                                        </a>
                                        <a className="dropdown-item" onClick={logout}>
                                            Logout
                                        </a>
                                    </div>
                                    ) : (
                                    <>
                                        <div className="dropdown-menu" aria-labelledby="user_name_navbarDropdown">
                                            <div className="">
                                                <a className="dropdown-item" href={ "/member/" +
                                                    localStorage.getItem("userId") }>
                                                    My Profile
                                                </a>
                                                {memberData?.userType == "Member" && (
                                                <a className="dropdown-item" href="/member-edit">
                                                    Edit Member Details{" "}
                                                </a>
                                                )}
                                                <a className="dropdown-item" href="/member-edit">
                                                    Account Settings{" "}
                                                </a>
                                                <a className="dropdown-item" href="/comming-soon">
                                                    Billing Settings
                                                </a>
                                                <a className="dropdown-item" onClick={logout}>
                                                    Logout
                                                </a>
                                            </div>
                                            
                                        </div>
                                    </>
                                    )}
                                </div>
                            </li>
                            <li className="iser_img_name desktop-hidden">
                            {memberData?.profilePhotoUrl ? (
                                <S3Image imgKey={memberData.profilePhotoUrl} />
                                ) : (
                                <div className="demo_img">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        fill="#eeeeee" stroke="none">
                                        <path d="M2370 5105 c-287 -40 -550 -173 -761 -384 -397 -397 -506 -972 -283
-1491 129 -300 394 -565 694 -694 427 -184 901 -143 1285 111 133 88 290 245
378 378 299 453 299 1037 0 1490 -88 133 -245 290 -378 378 -278 183 -607 258
-935 212z"></path>
                                        <path d="M2125 2114 c-402 -37 -725 -160 -1037 -395 -112 -84 -298 -273 -384
-389 -241 -328 -365 -674 -390 -1090 -6 -106 -5 -119 15 -158 14 -28 35 -49
61 -62 39 -20 54 -20 2170 -20 2116 0 2131 0 2170 20 26 13 47 34 61 62 20 39
21 52 15 158 -31 514 -220 943 -576 1300 -301 304 -641 480 -1070 556 -78 14
-176 17 -545 19 -247 2 -468 1 -490 -1z"></path>
                                    </g>
                                </svg>
                                </div>

                                )}
                                <button className="navbar-toggler" type="button" onClick={openNav2}>
                                    <span className="navbar-toggler-icon open">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 2.63443L1.76877 0.865723L7.5 6.59695L13.2312 0.865723L15 2.63443L7.5 10.1344L0 2.63443Z"
                                                fill="#CCCCCC" />
                                        </svg>
                                    </span>
                                </button>
                            </li>
                        </ul>
                        {/* // <li className="login">
                            // <a onClick={logout}>Logout</a>
                            // </li> */}
                    </>
                    ) : (
                    <>
                        <li className="border_nav xs-hidden">
                            <Link to="/become-a-member">Become a Member</Link>{" "}
                        </li>
                        <li className="login">
                            <a href="/login" className="">
                                Login
                            </a>
                        </li>
                    </>
                    )}
                </ul>
            </div>

            <div id="myprofilenav" className="sidenav">
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav2}>
                    ×
                </a>
                <div className="btn_ama profile_menu">
                        <div className="profile_pic">
                            <div className="img">
                            {memberData?.profilePhotoUrl ? (
                                <S3Image imgKey={memberData.profilePhotoUrl} />
                                ) : (
                                <div className="demo_img">
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        fill="#eeeeee" stroke="none">
                                        <path d="M2370 5105 c-287 -40 -550 -173 -761 -384 -397 -397 -506 -972 -283
-1491 129 -300 394 -565 694 -694 427 -184 901 -143 1285 111 133 88 290 245
378 378 299 453 299 1037 0 1490 -88 133 -245 290 -378 378 -278 183 -607 258
-935 212z"></path>
                                        <path d="M2125 2114 c-402 -37 -725 -160 -1037 -395 -112 -84 -298 -273 -384
-389 -241 -328 -365 -674 -390 -1090 -6 -106 -5 -119 15 -158 14 -28 35 -49
61 -62 39 -20 54 -20 2170 -20 2116 0 2131 0 2170 20 26 13 47 34 61 62 20 39
21 52 15 158 -31 514 -220 943 -576 1300 -301 304 -641 480 -1070 556 -78 14
-176 17 -545 19 -247 2 -468 1 -490 -1z"></path>
                                    </g>
                                </svg>
                                </div>

                                )}
                            </div>
                            <a href="#"> <span>Hello,</span> Tofayel</a>
                        </div>
                        <div className="sms">
                           <a href="#">
                           <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.2475 2.87988H4.71279C3.21449 2.87988 2.00012 4.10231 2.00012 5.60077V18.399C2.00012 19.9018 3.21886 21.1199 4.71279 21.1199H24.2475C25.7458 21.1199 26.9601 19.8975 26.9601 18.399V5.60077C26.9601 4.10231 25.7414 2.87988 24.2475 2.87988ZM14.9519 11.4369C14.8165 11.542 14.6505 11.6034 14.4801 11.6034C14.3098 11.6034 14.1438 11.5464 14.0084 11.4369L6.05383 5.10566H22.9108L14.9519 11.4369ZM24.2475 18.8985H4.71279C4.44196 18.8985 4.21918 18.675 4.21918 18.4034V6.49458L12.628 13.1851C13.1522 13.6013 13.8118 13.8335 14.4801 13.8335C15.1485 13.8335 15.8081 13.6057 16.3322 13.1851L24.7411 6.49458V18.4034C24.7411 18.675 24.5183 18.8985 24.2475 18.8985Z" fill="black"/>
                                </svg>
                           </a>

                            <a href="#">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 24C13.3201 24 14.4001 22.92 14.4001 21.6H9.60005C9.60005 22.92 10.68 24 12 24ZM19.8001 16.8V10.2C19.8001 6.48 17.2801 3.48 13.8 2.64V1.8C13.8 0.84 12.96 0 12 0C11.04 0 10.2 0.84 10.2 1.8V2.64C6.72005 3.48 4.20005 6.48 4.20005 10.2V16.8L1.80005 19.2V20.4H22.2001V19.2L19.8001 16.8ZM17.4001 18H6.60005V10.2C6.60005 7.2 9.00005 4.8 12 4.8C15.0001 4.8 17.4001 7.2 17.4001 10.2V18Z" fill="black"/>
                                </svg>
                            </a>

                        </div>
                    </div>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        <a className="dropdown-item" href={ "/member/" +
                                                    localStorage.getItem("userId") }>
                                                    My Profile
                                                </a>
                        </li>
                        <li className="nav-item">
                        {memberData?.userType == "Member" && (
                                                <a className="dropdown-item" href="/member-edit">
                                                    Edit Member Details{" "}
                                                </a>
                                                )}
                        </li>
                        <li className="nav-item">
                        <a className="dropdown-item" href="/member-edit">
                                                    Account Settings{" "}
                                                </a>
                        </li>
                        <li className="nav-item">
                        <a className="dropdown-item" href="/comming-soon">
                                                    Billing Settings
                                                </a>
                        </li>
                        <li className="nav-item">
                        <a className="dropdown-item" onClick={logout}>
                                                    Logout
                                                </a>
                        </li>
                    </ul>

            </div>
            
        </div>
    </nav>
</div>
);
}
import React, {useState} from 'react';
import { API } from "aws-amplify";
import { useFormik } from "formik";
const Collaborate = () => {
    const [companyName, setCompanyName] = useState()
    const [webAddress, setWebAddress] = useState()
    const [companyEmail, setCompanyEmail] = useState()
    const [contactPersonName, setContactPersonName] = useState()
    const [designation, setDesignation] = useState()
    const [personalEmail, setPersonalEmail] = useState()
    const [mobile, setMobile] = useState()
    const [country, setCountry] = useState()
    const [purpose, setPurpose] = useState()
    const [message, setMessage] = useState()
    const [insertStatus, setInsertStatus] = useState(false)


    const handleSubmit = async(values) => {
     
        let query = `
        mutation MyMutation {
          createBecomeAPartner(
              input: {
                  companyEmail: "${values.companyEmail}", 
                  companyName: "${values.companyName}", 
                  contactPersonName: "${values.contactPersonName}", 
                  country: "${values.country}", 
                  designation: "${values.designation}", 
                  message: "${values.message}", 
                  mobile: "${values.mobile}", 
                  personalEmail: "${values.personalEmail}", 
                  purpose: "${values.purpose}", 
                  webAddress: "${values.webAddress}",
                  purposeType:Collaboration
                })
                {
                    id
                    companyName
                    contactPersonName
                }
            }
        `
        try{
            let resonse = await API.graphql({query});
            console.log('-------------', resonse)
            formik.resetForm()
            setInsertStatus(true)
        }catch(e)
        {
            setInsertStatus(true)
        }
       
        return false;
    }
    const validation = (values) => {
        const errors = {};
        if (!values.companyName) errors.companyName = "Company Name is required";
        if(values.webAddress && !/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(values.webAddress))
        {   
            errors.webAddress ="Invalid url"
        }
        if (values.companyEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.companyEmail))
            errors.companyEmail = "Invalid email";  
         if (!values.contactPersonName) errors.contactPersonName = "Contact Person Name is required";
         if (!values.designation) errors.designation = "Designation is required";
         if (!values.personalEmail) errors.personalEmail = "Personal Email Address is required";
         if (values.personalEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personalEmail))
            errors.personalEmail = "Invalid email";  
         if (!values.mobile) errors.mobile ="Mobile Number is required"

         if (typeof values.mobile !== "undefined") {
            var pattern = new RegExp(/^[0-9\b]+$/);
          
            if (!pattern.test(values.mobile)) {
          
              errors.mobile = "Please enter only number.";
          
            }else if(values.mobile.length != 11){
              errors.mobile = "Please enter valid phone number.";
          
            }
        }
            
        if(!values.purpose) errors.purpose = "Please enter purpose"
        if(!values.message) errors.message = "Please enter message"
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            companyName: "",
             webAddress: "",
             companyEmail: "",
             contactPersonName: "",
             designation: "",
             personalEmail: "",
             mobile:"",
             country: "",
             purpose: "",
             message: "",
        },
        validate: validation,
        validateOnBlur: true,
        onSubmit: handleSubmit,
    });
    
    return (
            <>
            <form onSubmit={formik.handleSubmit} id="clear-form">
                            <div className="row">
                                {insertStatus == true && 
                                    <div className="col-md-12">  
                                        <div className="alert alert-success custom-alert" >
                                            Request successfully sent to admin
                                        </div>
                                    </div>
                                }
                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Company name <span>*</span></label>
                                        <input type="text" 
                                         
                                        placeholder="Enter your company name" 
                                        id="companyName"
                                        //onChange={(e)=>setCompanyName(e.target.value)} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="companyName"
                                        value={formik.values.companyName}
                                        />
                                        {formik.touched.companyName && formik.errors.companyName && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.companyName}
                                            </label>
                                        )}

                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Web addresss</label>
                                        <input type="text" 
                                        placeholder="Enter web addresss" 
                                        // onChange={(e)=>setWebAddress(e.target.value)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id="webAddress"
                                        name="webAddress"
                                        value={formik.values.webAddress}
                                        
                                        />
                                        {formik.touched.webAddress && formik.errors.webAddress && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.webAddress}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label> Company Email</label>
                                        <input type="email" 
                                        placeholder="Enter your company email" 
                                        //onChange={(e)=>setCompanyEmail(e.target.value)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id="companyEmail"
                                        value={formik.values.companyEmail}
                                        />
                                        {formik.touched.companyEmail && formik.errors.companyEmail && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.companyEmail}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Contact person name <span>*</span></label>
                                        <input type="text"
                                          
                                         placeholder="Enter Contact person name" 
                                        // onChange={(e)=>setContactPersonName(e.target.value)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id="contactPersonName"
                                        value={formik.values.contactPersonName}
                                         />
                                         {formik.touched.contactPersonName && formik.errors.contactPersonName && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.contactPersonName}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Designation <span>*</span></label>
                                        <input type="text" 
                                         placeholder="Enter Designation"
                                        //   onChange={(e)=>setDesignation(e.target.value)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id="designation"
                                        value={formik.values.designation}
                                          />
                                          {formik.touched.designation && formik.errors.designation && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.designation}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Email <span>*</span></label>
                                        <input type="email" 
                                         placeholder="Enter your personal email"
                                        //  onChange={(e)=>setPersonalEmail(e.target.value)}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        id="personalEmail"
                                        value={formik.values.personalEmail}
                                          />
                                          {formik.touched.personalEmail && formik.errors.personalEmail && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.personalEmail}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Mobile <span>*</span></label>
                                        <input type="text" 
                                       
                                        placeholder="Enter mobile number" 
                                        onChange={(e)=>setMobile(e.target.value)} 
                                        id="mobile"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.mobile}
                                        />
                                        {formik.touched.mobile && formik.errors.mobile && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.mobile}
                                            </label>
                                        )}
                                    </div>

                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label>Country </label>
                                        <input type="text" 
                                         placeholder="Enter your country name"
                                          //onChange={(e)=>setCountry(e.target.value)} 
                                          id="country"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.country}

                                          />
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="input_item">
                                        <label> Purpose of collaboration <span>*</span> </label>
                                        <input type="text" 
                                         placeholder="Enter purpose of collaboration" 
                                        // onChange={(e)=>setPurpose(e.target.value)} 
                                         id="purpose"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.purpose}
                                         />
                                         {formik.touched.purpose && formik.errors.purpose && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.purpose}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="input_item">
                                        <label>Message <span>*</span></label>
                                        <textarea rows="3" 
                                         placeholder="Write your thought" 
                                        //  onChange={(e)=>setMessage(e.target.value)}
                                          id="message"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.message}
                                         ></textarea>
                                         {formik.touched.message && formik.errors.message && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.message}
                                            </label>
                                        )}
                                    </div>
                                </div>

                                <div className="save">
                                    <button type="submit" onClick={formik.handleSubmit} >Apply Now</button>
                                </div>

                            </div>
                        </form>

            {/* <form onSubmit={handleSubmit} >
                <div className="row">
                    {insertStatus == true && 
                        <div className="col-md-12">  
                            <div className="alert alert-success custom-alert" >
                                Request successfully sent to admin
                            </div>
                        </div>
                    }
                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Company name <span>*</span></label>
                            <input type="text" required placeholder="Enter your company name" onChange={(e)=>setCompanyName(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Web addresss</label>
                            <input type="text" placeholder="Enter web addresss" onChange={(e)=>setWebAddress(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label> Company Email</label>
                            <input type="email" placeholder="Enter your company email" onChange={(e)=>setCompanyEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Contact person name <span>*</span></label>
                            <input type="text" required placeholder="Enter Contact person name" onChange={(e)=>setContactPersonName(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Designation <span>*</span></label>
                            <input type="text" required placeholder="Enter Designation" onChange={(e)=>setDesignation(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Email <span>*</span></label>
                            <input type="email" required placeholder="Enter your personal email" onChange={(e)=>setPersonalEmail(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Mobile <span>*</span></label>
                            <input type="text" required placeholder="Enter mobile number" onChange={(e)=>setMobile(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Country </label>
                            <input type="text" required placeholder="Enter your country name" onChange={(e)=>setCountry(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Purpose of collaboration <span>*</span> </label>
                            <input type="text" required placeholder="Enter purpose of collaboration" onChange={(e)=>setPurpose(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="input_item">
                            <label>Message <span>*</span></label>
                            <textarea rows="3" required placeholder="Write your thought" onChange={(e)=>setMessage(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="save">
                        <button type="submit" >Apply Now</button>
                    </div>

                </div>
            </form> */}
            {/* <form action="">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Company name <span>*</span></label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Web addresss</label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Emails</label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Contact person name <span>*</span></label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Designation <span>*</span></label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Email <span>*</span></label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Mobile <span>*</span></label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Country </label>
                            <input type="text" placeholder="Enter your full name" />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="input_item">
                            <label>Purpose of collaboration<span>*</span> </label>
                            <input type="text" placeholder="Enter purpose of collaboration" />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="input_item">
                            <label>Message <span>*</span></label>
                            <textarea rows="3" placeholder="Write your thought"></textarea>
                        </div>
                    </div>

                    <div className="save">
                        <a href="">Apply Now</a>
                    </div>

                </div>
            </form> */}
        </>
    );
};

export default Collaborate;
import React from "react";
import Info from "../info/info";
import AddQuestion from "../add-question/addQuestion";
import TopQuestions from "../top-questions/topQuestions";

export default function AMAComponent() {
    return (
        <React.Fragment>
            <div class="ema_banner event_banner about_banner banner_bg col-xl-12 col-md-12 col-sm-12 col-xs-12">
                <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="images/about_banner.png" class="d-block w-100" alt="..." />
                        </div>
                    </div>

                    <div class="carousel-caption d-md-block">
                        <div class="about-desc">
                            <h2>ama ( ask me anything )</h2>
                            <p>Topic: Blockchain for secured banking</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="portfolio_part">
                <div class="container">
                    <div class="row">
                        <Info />
                        <AddQuestion />
                        <TopQuestions />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

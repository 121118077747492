import React from 'react';
import Gallery from 'react-photo-gallery';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { photos } from "./photos";

const VideoGalleryComponent = () => {
    return (
        <div className="container-fluid">
            <div className="Galery_part_Rasel">
                <div className="gallery-page">
                    <div className="allbum">
                        <div className="text">
                            <h2>CTO Tech Summit 2020</h2>
                            <div className="header-allbum">
                                <a href="/gallery-more-details-inovation">Show More
                                    <svg
                                        width="11"
                                        height="9"
                                        viewBox="0 0 11 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.5336 4.09602L6.77776 0.340133C6.67055 0.232921 6.52766 0.174072 6.37529 0.174072C6.22276 0.174072 6.07995 0.233005 5.97274 0.340133L5.63174 0.681216C5.52461 0.788259 5.4656 0.931237 5.4656 1.08368C5.4656 1.23605 5.52461 1.38384 5.63174 1.49089L7.82282 3.68679H0.861653C0.547796 3.68679 0.299805 3.9325 0.299805 4.24644V4.72864C0.299805 5.04258 0.547796 5.31307 0.861653 5.31307H7.84768L5.63183 7.52123C5.5247 7.62844 5.46568 7.76753 5.46568 7.91998C5.46568 8.07226 5.5247 8.21338 5.63183 8.3205L5.97282 8.66049C6.08004 8.7677 6.22285 8.82612 6.37538 8.82612C6.52774 8.82612 6.67063 8.76694 6.77784 8.65973L10.5336 4.90392C10.6411 4.79637 10.7002 4.6528 10.6998 4.50018C10.7001 4.34706 10.6411 4.2034 10.5336 4.09602Z"
                                            fill="black"/>
                                    </svg>
                                </a>
                            </div>
                        </div>

                        {/* <Gallery photos={photos}/> */}
                    </div>

                    <Tabs>
                        <TabList>
                            <Tab>Guest</Tab>
                            <Tab>Organizer</Tab>
                            <Tab>Participants</Tab>
                        </TabList>
                        <TabPanel>
                            <Gallery photos={photos}/>
                        </TabPanel>
                        <TabPanel>
                            <Gallery photos={photos}/>
                        </TabPanel>
                        <TabPanel>
                            <Gallery photos={photos}/>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default VideoGalleryComponent;
import {professionList} from "../../model/Profession"
import { useState, useEffect, useRef } from "react";
import {  createBecomeAPartner } from "../../graphql/mutations";
import { API } from "aws-amplify";
import { useFormik } from "formik";
export default function InquiryComponent() {
    const [professions, setProfessions] =useState();
    const [fullName, setFullName] = useState()
    const [profession, setProfession] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [subject, setSubject] = useState()
    const [message, setMessage] = useState()
    const [insertStatus, setInsertStatus] = useState(false)

    useEffect(async()=>{
        let professionData = await professionList();
        setProfessions(professionData);
    },[])
    const handleSubmitInQuery=async(values) => {
       // let jsonProfession = JSON.stringify(profession);
        // event.preventDefault();
        
        let response = await API.graphql({
            query: createBecomeAPartner,
            variables: {
                input: {
                   
                        contactPersonName: values.fullName,
                        mobile: values.phone,
                        personalEmail:values.email,
                        message: values.message,
                        purposeType:`Inquiry`,
                        purpose: values.subject,
                        profession:JSON.stringify(values.profession)
                },
            },
        });

        console.log(response)
        formik.resetForm()
        setInsertStatus(true);
        return false;
    }

    const validation = (values) => {
        const errors = {};
        if (!values.profession) errors.profession = "Profession is required";
         if (!values.fullName) errors.fullName = "Full Name is required";

         if (!values.subject) errors.subject = "Subject is required";
         if (!values.email) errors.email = "Email Address is required";
         if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
            errors.email = "Invalid email";  

         if (!values.phone) errors.phone ="Phone Number is required"

         if (typeof values.phone !== "undefined") {
            var pattern = new RegExp(/^[0-9\b]+$/);
          
            if (!pattern.test(values.phone)) {
          
              errors.phone = "Please enter only number.";
          
            }else if(values.phone.length != 11){
              errors.phone = "Please enter valid phone number.";
          
            }
        }
            
        
        if(!values.message) errors.message = "Please enter message"
        return errors;
    };

    const formik = useFormik({
        initialValues: {
             profession: "",
             fullName: "",
             email: "",
             phone:"",

             subject: "",
             
             personalEmail: "",
             
             country: "",
             purpose: "",
             message: "",
        },
        validate: validation,
        validateOnBlur: true,
        onSubmit: handleSubmitInQuery,
    });
    return (
        <div id="inquery">
             <div className="banner_bg col-xl-12 col-md-12 col-sm-12 col-xs-12">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            <div className="banner_header">
                                <h2>Contact Us</h2>
                                <p>""Our team is happy to answer your question. Feel out the form and we will be in touch as soon as possible.""</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12 m-auto">
                            <div className="form_part">
                                <form action="" onSubmit={formik.handleSubmit}>
                                    {insertStatus == true && 
                                        <div className="col-md-12">  
                                            <div className="alert alert-success custom-alert" >
                                                Request successfully sent to admin
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="input_item">
                                                <label>Full Name</label>
                                                <input type="text" 
                                                //onChange={(e)=>setFullName(e.target.value)} 
                                                placeholder="Enter your full name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                id="fullName"
                                                value={formik.values.fullName}
                                                />
                                                {formik.touched.fullName && formik.errors.fullName && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.fullName}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input_item">
                                                <label>Profession</label>
                                                <select
                                                //  onChange={(e)=>setProfession(e.target.value)}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                id="profession"
                                                value={formik.values.profession}
                                                 
                                                 >
                                                    <option value="">Select your profession</option>
                                                    {professions&&professions.map((item, index)=>{
                                                        let dataJson = JSON.stringify(item);
                                                        return <option value={dataJson} >{item.professionName}</option>
                                                    })}
                                                    
                                                </select>
                                                {formik.touched.profession && formik.errors.profession && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.profession}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input_item">
                                                <label>Email Address</label>
                                                <input type="text" 
                                                //onChange={(e)=>setEmail(e.target.value)}
                                                 placeholder="Enter your username"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                id="email"
                                                value={formik.values.email}
                                                 
                                                 />
                                                 {formik.touched.email && formik.errors.email && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.email}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input_item">
                                                <label>Phone</label>
                                                <input type="text" 
                                                //onChange={(e)=>setPhone(e.target.value)} 
                                                placeholder="Enter your phone number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                id="phone"
                                                value={formik.values.phone}
                                                />
                                                 {formik.touched.phone && formik.errors.phone && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.phone}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input_item">
                                                <label>Subject</label>
                                                <input type="text" 
                                                //onChange={(e)=>setSubject(e.target.value)}
                                                 placeholder="Enter contact subject"
                                                
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                id="subject"
                                                value={formik.values.subject}
                                                 
                                                 />
                                                 {formik.touched.subject && formik.errors.subject && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.subject}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="input_item">
                                                <label>Message</label>
                                                <textarea rows="3"
                                                //  onChange={(e)=>setMessage(e.target.value)}
                                                  placeholder="Write your thought"
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                  id="message"
                                                  value={formik.values.message}
                                                  ></textarea>
                                                  {formik.touched.message && formik.errors.message && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.message}
                                                    </label>
                                                )}
                                            </div>
                                        </div>

                                        <div className="save">
                                            <button  onClick={formik.handleSubmit} type="submit">Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_height maps xs-hidden"></div>
            <section id="maps">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12 m-auto">
                            <div className="maps_part">
                                <div className="overlay_text xl-hidden">
                                    <div className="maps_location">
                                        <div className="svg">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path d="M12 0C7.038 0 3 4.066 3 9.065C3 16.168 11.154 23.502 11.501 23.81C11.644 23.937 11.822 24 12 24C12.178 24 12.356 23.937 12.499 23.811C12.846 23.502 21 16.168 21 9.065C21 4.066 16.962 0 12 0ZM12 14C9.243 14 7 11.757 7 9C7 6.243 9.243 4 12 4C14.757 4 17 6.243 17 9C17 11.757 14.757 14 12 14Z" fill="#E51010"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <h3>Office Address</h3>
                                            <p>4-B, Level-3, Concept Tower, 68-69, Green Road Dhanmondi, Dhaka-1205,Bangladesh. </p>
                                        </div>
                                    </div>

                                    <div className="maps_location">
                                        <div className="svg">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path d="M20.529 10.0713C20.5579 6.68762 17.6759 3.58566 14.1048 3.15671C14.0337 3.14829 13.9564 3.13547 13.8745 3.1219C13.6977 3.09261 13.5148 3.0625 13.3304 3.0625C12.5984 3.0625 12.4028 3.57664 12.3513 3.88327C12.3012 4.18141 12.349 4.43177 12.493 4.62772C12.7354 4.95731 13.1619 5.01582 13.5045 5.0627C13.6049 5.07656 13.6996 5.08946 13.7789 5.10727C16.9875 5.82421 18.0678 6.95139 18.5958 10.1327C18.6087 10.2104 18.6145 10.3062 18.6207 10.4078C18.6438 10.788 18.6918 11.5792 19.5423 11.5792H19.5424C19.6132 11.5792 19.6893 11.5731 19.7686 11.561C20.5606 11.4407 20.5358 10.7175 20.5239 10.37C20.5205 10.2721 20.5173 10.1795 20.5256 10.118C20.5278 10.1025 20.5289 10.0869 20.529 10.0713Z" fill="#E51010"/>
                                                <path d="M13.1226 1.91463C13.2176 1.92149 13.3076 1.92805 13.3825 1.93953C18.6512 2.74979 21.0742 5.24609 21.7457 10.556C21.7571 10.6463 21.7589 10.7564 21.7608 10.873C21.7675 11.2878 21.7813 12.1509 22.7081 12.1687L22.7369 12.169C23.0276 12.169 23.2588 12.0814 23.4242 11.9083C23.7127 11.6065 23.6926 11.1582 23.6763 10.7979C23.6724 10.7095 23.6686 10.6263 23.6695 10.5534C23.7365 5.12266 19.0357 0.198112 13.6098 0.0149814C13.5873 0.014236 13.5658 0.0154286 13.5441 0.0184845C13.5335 0.0200497 13.5137 0.0219876 13.4799 0.0219876C13.4258 0.0219876 13.3592 0.0172919 13.2886 0.0125217C13.2033 0.00670807 13.1065 0 13.0085 0C12.1444 0 11.9801 0.614161 11.9591 0.980273C11.9106 1.82639 12.7292 1.88594 13.1226 1.91463Z" fill="#E51010"/>
                                                <path d="M21.4907 17.4188C21.3787 17.3332 21.2629 17.2446 21.1544 17.1573C20.5792 16.6944 19.9672 16.2676 19.3753 15.855C19.2525 15.7694 19.1296 15.6837 19.0072 15.5979C18.2491 15.0653 17.5675 14.8066 16.9235 14.8066C16.0561 14.8066 15.2999 15.2858 14.6758 16.2308C14.3992 16.6498 14.0638 16.8535 13.6503 16.8535C13.4059 16.8535 13.1281 16.7837 12.8248 16.6461C10.3776 15.5363 8.63015 13.8349 7.63087 11.5889C7.14781 10.5034 7.30441 9.79384 8.15455 9.21635C8.63731 8.88869 9.53589 8.27871 9.47224 7.11061C9.40016 5.78412 6.47335 1.79289 5.24019 1.33958C4.71808 1.14758 4.1695 1.14579 3.60677 1.3351C2.18913 1.81167 1.17181 2.64862 0.664535 3.7553C0.174324 4.82464 0.19646 6.08025 0.72856 7.38638C2.2668 11.1627 4.42948 14.4552 7.15676 17.1722C9.82627 19.8319 13.1073 22.0105 16.9087 23.6474C17.2514 23.7948 17.6108 23.8752 17.8732 23.9339C17.9626 23.954 18.0399 23.9711 18.0961 23.9866C18.1271 23.995 18.159 23.9995 18.1909 23.9998L18.221 24C18.2211 24 18.221 24 18.2211 24C20.0091 24 22.1559 22.3662 22.8152 20.5036C23.3928 18.8726 22.3382 18.0665 21.4907 17.4188Z" fill="#E51010"/>
                                                <path d="M13.9125 6.22844C13.6064 6.23619 12.9694 6.25199 12.7458 6.90081C12.6413 7.20401 12.6538 7.46727 12.7831 7.68327C12.9729 8.00018 13.3365 8.0973 13.6672 8.15059C14.8665 8.34304 15.4824 9.00625 15.6057 10.2375C15.6629 10.8117 16.0495 11.2126 16.5459 11.2126H16.546C16.5826 11.2126 16.6201 11.2104 16.6575 11.2059C17.2545 11.1349 17.5439 10.6962 17.5178 9.90229C17.5273 9.0737 17.0937 8.133 16.3561 7.38371C15.6161 6.63211 14.724 6.20883 13.9125 6.22844Z" fill="#E51010"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <h3>Phone</h3>
                                            <p>+88 02 9641156</p>
                                        </div>
                                    </div>

                                    <div className="maps_location">
                                        <div className="svg">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0)">
                                                <path d="M23.9822 5.17009C23.9822 5.14267 24.001 5.11598 23.9998 5.08887L16.6758 12.1459L23.991 18.9775C23.9953 18.9287 23.9822 18.8795 23.9822 18.8297V5.17009Z" fill="#E51010"/>
                                                <path d="M15.5583 13.2319L12.5685 16.1086C12.4172 16.2541 12.2218 16.3271 12.0263 16.3271C11.8348 16.3271 11.6434 16.2572 11.4931 16.1172L8.51141 13.3387L1.14844 20.4358C1.32747 20.5001 1.51964 20.5501 1.72088 20.5501H22.3318C22.6306 20.5501 22.9109 20.459 23.1546 20.3233L15.5583 13.2319Z" fill="#E51010"/>
                                                <path d="M12.0188 14.4682L23.1962 3.7011C22.9431 3.55064 22.6485 3.44971 22.3328 3.44971H1.72191C1.31077 3.44971 0.934043 3.60935 0.640625 3.85282L12.0188 14.4682Z" fill="#E51010"/>
                                                <path d="M0 5.43237V18.8295C0 18.9833 0.0353478 19.1316 0.0737194 19.2733L7.34535 12.2709L0 5.43237Z" fill="#E51010"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0">
                                                <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <h3>Email Address</h3>
                                            <p>ctoforumbd@gmail.com</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="maps_img">
                                        <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.9164178171727!2d90.38504981498136!3d23.75035978458945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8bb31cdbf51%3A0x4fad09befa2c7c72!2z4Ka44Ka_4Kaf4Ka_4KaTIOCmq-Cni-CmsOCmvuCmrg!5e0!3m2!1sbn!2sbd!4v1627812659171!5m2!1sbn!2sbd"   allowfullscreen="" loading="lazy" width="100%" height="491"></iframe>
                                        {/* <img src="images/maps.png" alt="image" className="img-fluid w-100"/> */}

                                        <div className="overlay_text xs-hidden">

                                            <div className="maps_location">
                                                <div className="svg">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                        <path d="M12 0C7.038 0 3 4.066 3 9.065C3 16.168 11.154 23.502 11.501 23.81C11.644 23.937 11.822 24 12 24C12.178 24 12.356 23.937 12.499 23.811C12.846 23.502 21 16.168 21 9.065C21 4.066 16.962 0 12 0ZM12 14C9.243 14 7 11.757 7 9C7 6.243 9.243 4 12 4C14.757 4 17 6.243 17 9C17 11.757 14.757 14 12 14Z" fill="#E51010"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0">
                                                        <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <h3>Office Address</h3>
                                                    <p>4-B, Level-3, Concept Tower, 68-69, Green Road Dhanmondi, Dhaka-1205,Bangladesh. </p>
                                                </div>
                                            </div>

                                            <div className="maps_location">
                                                <div className="svg">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                        <path d="M20.529 10.0713C20.5579 6.68762 17.6759 3.58566 14.1048 3.15671C14.0337 3.14829 13.9564 3.13547 13.8745 3.1219C13.6977 3.09261 13.5148 3.0625 13.3304 3.0625C12.5984 3.0625 12.4028 3.57664 12.3513 3.88327C12.3012 4.18141 12.349 4.43177 12.493 4.62772C12.7354 4.95731 13.1619 5.01582 13.5045 5.0627C13.6049 5.07656 13.6996 5.08946 13.7789 5.10727C16.9875 5.82421 18.0678 6.95139 18.5958 10.1327C18.6087 10.2104 18.6145 10.3062 18.6207 10.4078C18.6438 10.788 18.6918 11.5792 19.5423 11.5792H19.5424C19.6132 11.5792 19.6893 11.5731 19.7686 11.561C20.5606 11.4407 20.5358 10.7175 20.5239 10.37C20.5205 10.2721 20.5173 10.1795 20.5256 10.118C20.5278 10.1025 20.5289 10.0869 20.529 10.0713Z" fill="#E51010"/>
                                                        <path d="M13.1226 1.91463C13.2176 1.92149 13.3076 1.92805 13.3825 1.93953C18.6512 2.74979 21.0742 5.24609 21.7457 10.556C21.7571 10.6463 21.7589 10.7564 21.7608 10.873C21.7675 11.2878 21.7813 12.1509 22.7081 12.1687L22.7369 12.169C23.0276 12.169 23.2588 12.0814 23.4242 11.9083C23.7127 11.6065 23.6926 11.1582 23.6763 10.7979C23.6724 10.7095 23.6686 10.6263 23.6695 10.5534C23.7365 5.12266 19.0357 0.198112 13.6098 0.0149814C13.5873 0.014236 13.5658 0.0154286 13.5441 0.0184845C13.5335 0.0200497 13.5137 0.0219876 13.4799 0.0219876C13.4258 0.0219876 13.3592 0.0172919 13.2886 0.0125217C13.2033 0.00670807 13.1065 0 13.0085 0C12.1444 0 11.9801 0.614161 11.9591 0.980273C11.9106 1.82639 12.7292 1.88594 13.1226 1.91463Z" fill="#E51010"/>
                                                        <path d="M21.4907 17.4188C21.3787 17.3332 21.2629 17.2446 21.1544 17.1573C20.5792 16.6944 19.9672 16.2676 19.3753 15.855C19.2525 15.7694 19.1296 15.6837 19.0072 15.5979C18.2491 15.0653 17.5675 14.8066 16.9235 14.8066C16.0561 14.8066 15.2999 15.2858 14.6758 16.2308C14.3992 16.6498 14.0638 16.8535 13.6503 16.8535C13.4059 16.8535 13.1281 16.7837 12.8248 16.6461C10.3776 15.5363 8.63015 13.8349 7.63087 11.5889C7.14781 10.5034 7.30441 9.79384 8.15455 9.21635C8.63731 8.88869 9.53589 8.27871 9.47224 7.11061C9.40016 5.78412 6.47335 1.79289 5.24019 1.33958C4.71808 1.14758 4.1695 1.14579 3.60677 1.3351C2.18913 1.81167 1.17181 2.64862 0.664535 3.7553C0.174324 4.82464 0.19646 6.08025 0.72856 7.38638C2.2668 11.1627 4.42948 14.4552 7.15676 17.1722C9.82627 19.8319 13.1073 22.0105 16.9087 23.6474C17.2514 23.7948 17.6108 23.8752 17.8732 23.9339C17.9626 23.954 18.0399 23.9711 18.0961 23.9866C18.1271 23.995 18.159 23.9995 18.1909 23.9998L18.221 24C18.2211 24 18.221 24 18.2211 24C20.0091 24 22.1559 22.3662 22.8152 20.5036C23.3928 18.8726 22.3382 18.0665 21.4907 17.4188Z" fill="#E51010"/>
                                                        <path d="M13.9125 6.22844C13.6064 6.23619 12.9694 6.25199 12.7458 6.90081C12.6413 7.20401 12.6538 7.46727 12.7831 7.68327C12.9729 8.00018 13.3365 8.0973 13.6672 8.15059C14.8665 8.34304 15.4824 9.00625 15.6057 10.2375C15.6629 10.8117 16.0495 11.2126 16.5459 11.2126H16.546C16.5826 11.2126 16.6201 11.2104 16.6575 11.2059C17.2545 11.1349 17.5439 10.6962 17.5178 9.90229C17.5273 9.0737 17.0937 8.133 16.3561 7.38371C15.6161 6.63211 14.724 6.20883 13.9125 6.22844Z" fill="#E51010"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0">
                                                        <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <h3>Phone</h3>
                                                    <p>+88 02 9641156</p>
                                                </div>
                                            </div>

                                            <div className="maps_location">
                                                <div className="svg">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                        <path d="M23.9822 5.17009C23.9822 5.14267 24.001 5.11598 23.9998 5.08887L16.6758 12.1459L23.991 18.9775C23.9953 18.9287 23.9822 18.8795 23.9822 18.8297V5.17009Z" fill="#E51010"/>
                                                        <path d="M15.5583 13.2319L12.5685 16.1086C12.4172 16.2541 12.2218 16.3271 12.0263 16.3271C11.8348 16.3271 11.6434 16.2572 11.4931 16.1172L8.51141 13.3387L1.14844 20.4358C1.32747 20.5001 1.51964 20.5501 1.72088 20.5501H22.3318C22.6306 20.5501 22.9109 20.459 23.1546 20.3233L15.5583 13.2319Z" fill="#E51010"/>
                                                        <path d="M12.0188 14.4682L23.1962 3.7011C22.9431 3.55064 22.6485 3.44971 22.3328 3.44971H1.72191C1.31077 3.44971 0.934043 3.60935 0.640625 3.85282L12.0188 14.4682Z" fill="#E51010"/>
                                                        <path d="M0 5.43237V18.8295C0 18.9833 0.0353478 19.1316 0.0737194 19.2733L7.34535 12.2709L0 5.43237Z" fill="#E51010"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0">
                                                        <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="text">
                                                    <h3>Email Address</h3>
                                                    <p>ctoforumbd@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_height"></div>
        </div>
    );
}

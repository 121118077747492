import React from "react";
import Layout from "../../components/common/layout/layout";
import MagazineComponent from "../../components/magazine/magazine/magazine";

export default function Magazine() {
    return (
        <Layout showBanner={false}>
            <MagazineComponent />
        </Layout>
    );
}

import About from "../pages/About/About";
import AMA from "../pages/AMA/AMA";
import BecomeAMember from "../pages/Become-A-Member/BecomeAMember";
import CommingSoon from "../pages/Comming-soon/CommingSoon";
import EmailVerification from "../pages/Email-Verification/EmailVerification";
import Events from "../pages/Events/Events";
import ExecutiveCommittee from "../pages/executive-committee/executiveCommittee";
import Home from "../pages/Home/Home";
import Inquiry from "../pages/Inquiry/Inquiry";
import ForgotPassword from "../pages/Log-In/ForgotPassword";
import LogIn from "../pages/Log-In/LogIn";
import Magazine from "../pages/Magazine/Magazine";
import MemberDetails from "../pages/Member-Details/MemberDetails";
import MemberList from "../pages/Member-List/MemberList";
import MemberEdit from "../pages/Member-Registration/MemberEdit";
import MemberRegistration from "../pages/Member-Registration/MemberRegistration";
import Membership from "../pages/Membership/Membership";
import PartnersCorner from "../pages/Partners-Corner/PartnersCorner";
import Publications from "../pages/Publications/Publications";
import SignUp from "../pages/Sign-Up/SignUp";
import EventDetails from "./../pages/Events/others-event/EventDetails";
import OtherEvents from "./../pages/Events/others-event/OtherEvents";
import GalleryMoreDetailsInovation from "./../pages/gallery/GalleryMoreDetailsInovation";
import PhotoGallery from "./../pages/gallery/PhotoGallery";
import VideoGallery from "./../pages/gallery/VideoGallery";
import InnovationHackathon2021 from "../pages/innovation-hackathon/InnovationHackathon2021";
import InnovationHackathon2022 from "../pages/innovation-hackathon/InnovationHackathon2022";
import PhotoGalleryComponent from "../components/gallery/PhotoGalleryComponent";
import InnovationHackathonGallery2021 from "../components/innovation-hackathon/InnovationHackathonGallery2021";
import InnovationHackathonGallery2022 from "../components/innovation-hackathon/InnovationHackathonGallery2022";

export const publicRoutes = [
    {
        url: "/",
        private: false,
        component: Home,
    },
    {
        url: "/ama",
        private: false,
        component: AMA,
    },
    {
        url: "/about",
        private: false,
        component: About,
    },
    {
        url: "/become-a-member",
        private: false,
        component: BecomeAMember,
    },
    {
        url: "/events",
        private: false,
        component: Events,
    },
    {
        url: "/login",
        private: false,
        component: LogIn,
    },
    {
        url: "/magazine",
        private: false,
        component: Magazine,
    },
    {
        url: "/membership/:id",
        private: false,
        component: Membership,
    },
    {
        url: "/member-registration",
        private: false,
        component: MemberRegistration,
    },
    {
        url: "/member-list",
        private: false,
        component: MemberList,
    },
    {
        url: "/member/:id",
        private: false,
        component: MemberDetails,
    },
    // {
    //     url: "/member",
    //     private: false,
    //     component: MemberDetails,
    // },
    {
        url: "/publications",
        private: false,
        component: Publications,
    },
    {
        url: "/signup",
        private: false,
        component: SignUp,
    },
    // {
    //     url: "/Membership-Details/:id",
    //     private: false,
    //     component: SignUp,
    // },
    {
        url: "/member-edit",
        private: false,
        component: MemberEdit,
    },
    {
        url: "/inquiry",
        private: false,
        component: Inquiry,
    },
    {
        url: "/partners-corner",
        private: false,
        component: PartnersCorner,
    },
    {
        url: "/verify-email",
        private: false,
        component: EmailVerification,
    },
    {
        url: "/executive-committee",
        private: false,
        component: ExecutiveCommittee,
    },

    {
        url: "/comming-soon",
        private: false,
        component: CommingSoon,
    },
    {
        url: "/Forgot-password",
        private: false,
        component: ForgotPassword,
    },
    {
        url:"/innovation-hackathon-2021",
        private: false,
        component:InnovationHackathon2021,
    },
    {
        url:"/Innovation-Hackathon-Gallery-2021",
        private: false,
        component: InnovationHackathonGallery2021,
    },
    {
        url:"/Innovation-Hackathon-Gallery-2022",
        private: false,
        component: InnovationHackathonGallery2022,
    },
    {
        url:"/innovation-hackathon-2022",
        private: false,
        component:InnovationHackathon2022,
    },
    {
        url:"/photo-gallery",
        private: false,
        component:PhotoGallery,
    },
    {
        url:"/video-gallery",
        private: false,
        component:VideoGallery,
    },
    {
        url:"/gallery-more-details-inovation",
        private: false,
        component:GalleryMoreDetailsInovation
    },
    {
        url:'/other-events',
        private: false,
        component:OtherEvents,
    },
    {
        url:"/event-details/:id",
        private: false,
        component:EventDetails,
    }
];

import React,{ useState, useEffect} from 'react';
import { API, Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import ResetPassword from "./ResetPassword"
const ForgotPasswordComponent = () => {
    const [form, setForm] = useState('forgot');
    const [email, setEmail] = useState();
    const validateForm = (values) => {
        const errors = {};

        if (!values.email) errors.email = "Email is required";
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
       

        return errors;
    };
    const submitForm = async (values) => {
        try {
            let response =await Auth.forgotPassword(values.email)
            console.log(response, '---------response')
            setForm('reset')
            setEmail(values.email);
        }catch (e){
            console.log(e, '---------error')
            formik.errors.email = e.message
        }
        
      
    };
    const formik = useFormik({
        initialValues: {
            email: "",
            
        },
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    return (
        <React.Fragment>
            <div class="login_part">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            <h2>Forgot password</h2>
                            <p class="xs-hidden">
                               
                            </p>
                            <div class="dont_have xl-hidden">
                                <h3>Don’t have an account? <Link to={"/signup"}>Sign up</Link></h3>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="login_form forgot-password">
                                {form == "forgot"?
                                <>
                                <h2>Forgot password </h2>
                                <form onSubmit={formik.handleSubmit}>
                                    
                                    <div className="form-group">
                                        <input type="email"
                                        name="email"
                                        id="email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        className="form-control" placeholder="Enter your Registered email" />
                                        {formik.touched.email && formik.errors.email && (
                                                <label className="text-danger">
                                                    {formik.errors.email}
                                                </label>
                                            )}
                                    </div>
                                    <button type="submit" onClick={formik.handleSubmit} class="btn">
                                        Send
                                    </button>
                                </form>
                                </>
                                :
                                form == "reset"?
                                    <ResetPassword email={email} />
                                    :""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dont_have_part xs-hidden">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPasswordComponent;
import React from "react";
import MemberListComponent from "../../components/membership-list/membershipList";
import Layout from "../../components/common/layout/layout";

export default function MemberList() {
    return (
        <Layout showBanner={false}>
            <MemberListComponent />
        </Layout>
    );
}

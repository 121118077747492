import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

export default function EmailVerificationComponent() {
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const history = useHistory();

    const validateForm = (values) => {
        const errors = {};

        if (!values.email) errors.email = "Email is required";
        if (!values.verificationCode) errors.verificationCode = "Verification code is required";
    };

    const verifyEmail = async (values) => {
        console.log("*****");
        console.log(values.email, values.verificationCode);
        console.log("*****");
        let verifiedEmail = await Auth.confirmSignUp(values.email, values.verificationCode);
        if (verifiedEmail == "SUCCESS") {
            history.push("/login");
            localStorage.removeItem("emailToVerify");
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            verificationCode: "",
        },
        validate: validateForm,
        onSubmit: verifyEmail,
    });

    useEffect(() => {
        let emailToVerify = localStorage.getItem("emailToVerify");
        if (!emailToVerify) {
            history.push("/signup");
        }
        // console.log(emailToVerify);
        formik.initialValues.email = emailToVerify;
        setEmail(emailToVerify);
    }, []);

    return (
        <div className="signup_page email_verified">
            <div class="signup_part">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-xl-6 col-md-6 col-12 signup_text xl-hidden">
                            <h2>Almost Done!</h2>
                            <p>
                                Orci suscipit sit urna egestas orci. Tincidunt blandit sapien, sit
                                sed egestas nulla viverra vel. Ac id metus tristique egestas
                                elementum tellus. Commodo ac tellus parturient nisl sagittis.
                            </p>
                        </div>
                        <div class="col-xl-6 col-md-6 col-12">
                            <div class="signup_form verify">
                                <h2>Verify Email</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label htmlFor="fullname">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                value={formik.initialValues.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter your email"
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <label className="text-danger" htmlFor="error">
                                                    {formik.errors.email}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label for="email">Verification Code</label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="verificationCode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter you verification code"
                                            />
                                            {formik.touched.verificationCode &&
                                                formik.errors.verificationCode && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.verificationCode}
                                                    </label>
                                                )}
                                        </div>
                                    </div>
                                    <button type="submit" onClick={formik.handleSubmit} class="btn">
                                        Verify Email
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-6 signup_text xs-hidden">
                            <h2>Almost Done!</h2>
                            <p>
                                Orci suscipit sit urna egestas orci. Tincidunt blandit sapien, sit
                                sed egestas nulla viverra vel. Ac id metus tristique egestas
                                elementum tellus. Commodo ac tellus parturient nisl sagittis.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import { API, Auth } from "aws-amplify";
import { listMembers } from "../../graphql/queries";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";

export default function LogInComponent() {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const history = useHistory();

    const validateForm = (values) => {
        const errors = {};

        if (!values.email) errors.email = "Email is required";
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.password) errors.password = "Password is required";

        return errors;
    };

    const submitForm = async (values) => {
        try {
            let user = await Auth.signIn(values.email, values.password);
            let userEmail = user.attributes.email;
            localStorage.setItem("email", userEmail);
            // history.push("/member-registration");
            if (userEmail) {
                let user = await API.graphql({
                    query: listMembers,
                    variables: {
                        filter: {
                            email: {
                                eq: userEmail,
                            },
                        },
                    },
                });
                user = user.data.listMembers.items[0];
                localStorage.setItem("userId", user.id);
                localStorage.setItem("memberType", user.memberType);
                console.log(user);
            }
            history.push("/");
        } catch (e) {
            console.log(e);
            if (e.code == "UserNotConfirmedException") {
                localStorage.setItem("emailToVerify", formik.values.email);
                history.push("/verify-email");
            }
            formik.errors.server = e.message;
            console.log(e.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            loggedIn: false,
        },
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });

    return (
        <React.Fragment>
            <div class="login_part">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-7 col-sm-12">
                            <h2>Login</h2>
                            <p class="xs-hidden">
                                Orci suscipit sit urna egestas orci. Tincidunt blandit sapien, sit
                                sed egestas nulla viverra vel. Ac id metus tristique egestas
                                elementum tellus. Commodo ac tellus parturient nisl sagittis.
                            </p>
                            <div class="dont_have xl-hidden">
                                <h3>Don’t have an account? <Link to={"/signup"}>Sign up</Link></h3>
                            </div>
                        </div>
                        <div class="col-md-5 col-sm-12">
                            <div class="login_form">
                                <h2>Login to have greater access</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    {formik.errors.server && (
                                        <label className="text-danger">
                                            {formik.errors.server}
                                        </label>
                                    )}
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            placeholder="Enter your username"
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <label className="text-danger">
                                                {formik.errors.email}
                                            </label>
                                        )}
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputPassword1">Password</label>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password"
                                            id="password"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            placeholder="Enter your password"
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <label className="text-danger">
                                                {formik.errors.password}
                                            </label>
                                        )}
                                    </div>
                                    <div class="form-group form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="exampleCheck1"
                                        />
                                        <label class="form-check-label" for="exampleCheck1">
                                            Keep me logged in.
                                        </label>
                                    </div>
                                    <button type="submit" onClick={formik.handleSubmit} class="btn">
                                        Sign In
                                    </button>
                                    <a href="/Forgot-password">Forgot password?</a>
                                    <span>Or Login With</span>
                                    <div class="social_img">
                                        <img src="images/google.png" alt="" />
                                        <img src="images/linkedin.png" alt="" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dont_have_part xs-hidden">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <div class="dont_have">
                                <h2>Don’t have an account? </h2>
                                <p>
                                    <Link to={"/signup"}>Sign up</Link> <span> from here</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

import Details from "../details/details";
import SubMenu from "../sub-menu/subMenu";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
export default function MembershipComponent(props) {
    const [membershipid, setMembershipid] = useState(props.id);
    const [memberlist, setMemberlist] = useState();
    useEffect(async () => {
        getMemberShipData();
    }, []);
    const getMemberShipData = async () => {
        let query = `query MyQuery {
            listMembershipSettingss {
              items {
                JoiningFee
                YearlyFee
                benifit
                createdAt
                description
                id
                is_active
                memberType
                requirement
                updatedAt
              }
            }
          }
          `;
        let data = await API.graphql({ query: query });
        setMemberlist(data.data.listMembershipSettingss);
    };

    return (
        <div className="about">
            <div class="banner_bg col-xl-12 col-md-12 col-sm-12 col-xs-12 pl-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="img">
                                <img src="images/1 1.png" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="">
                                <h2>Select your membership type</h2>
                                <p>
                                    Members here get benefits they dream of. CTO forum offers some exciting packages and benefits for the members in any package with variations. What suits you-choose wisely. Eventually, you have options to go for. Your business goal, professional objective and way of work can define the membership type you should go for. 
                                     
                                </p>
                                <p>
                                    Explore, analysis and get the idea about choosing your membership type. The innovation places are ready to welcome you all. Go for any!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_height"></div>
            <section id="Membership_Features">
                <div class="container-fluid">
                    <div class="row">
                        <div class="member_header">
                            <h2>Membership Features</h2>
                            <p>
                            Exciting features and packages you can’t deny. 
Choose what your bucket craves. 

                            </p>
                        </div>
                    </div>
                    <div class="Membership_Features_tabs">
                        <div class="row">
                            <div class="col-lg-12">
                                <SubMenu memberships={memberlist} id={membershipid} />

                                <Details memberships={memberlist} id={membershipid} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="section_height"></div>

            <div>
                <section class="membership_tab_area">
                    <div class="row">
                        {/* <SubMenu memberships={memberlist} id={membershipid} />
                    <Details memberships={memberlist}  id={membershipid} /> */}
                    </div>
                </section>
            </div>
        </div>
    );
}

import React, { useEffect } from "react";
const EventSchedule2021 = () => {
  useEffect(() => {
    let eventTab = document.getElementsByClassName("eventTab");
    let eventlink = document.getElementsByClassName("eventlink");
    eventTab[0].style.display = "block";
    eventlink[0].className += " active-eventlink";
  });
  let openEventTap = (evt, tabName) => {
    var i, eventTab, eventlink;
    eventTab = document.getElementsByClassName("eventTab");
    for (i = 0; i < eventTab.length; i++) {
      eventTab[i].style.display = "none";
    }
    eventlink = document.getElementsByClassName("eventlink");
    for (i = 0; i < eventlink.length; i++) {
      eventlink[i].className = eventlink[i].className.replace(
        " active-eventlink",
        ""
      );
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active-eventlink";
  };
  return (
    <div className="event-schedule-section" id="evenschelule">
      <div className="overlay1">
        <div className="event-schedule__top sub-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h5 className="caption text-center">schedule</h5>
                <h2 className="heading text-center white-color">
                  event schedule
                </h2>
                {/* <p className="heading-desc text-center">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown prin
                </p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-4 custom-pad">
                <div
                  className="eventlink"
                  onClick={(e) => openEventTap(e, "event1")}
                >
                  <h6>Idea Round</h6>
                  <p>04 Sep, 2021 </p>
                </div>
              </div>
              <div className="col-4 custom-pad">
                <div
                  className="eventlink"
                  onClick={(e) => openEventTap(e, "event2")}
                >
                  <h6>Prototype Round</h6>
                  <p>18th Sep, 2021</p>
                </div>
              </div>
              <div className="col-4 custom-pad">
                <div
                  className="eventlink"
                  onClick={(e) => openEventTap(e, "event3")}
                >
                  <h6>Demonstration Round</h6>
                  <p>25th Sep, 2021</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-schedule_bottom event-schedule-padding">
        <div id="event1" className="eventTab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="es-item">
                  <div className="es-item_r">
                    <h3>Idea Round: </h3>
                    <h4>
                      This Round will be conducted on <span>04 Sep, 2021</span>{" "}
                      immediately after the registration [Date might change
                      depending on the Registration deadline]. This round will
                      determine the success or failure of a team for the next
                      round. In this round each Team must present a powerpoint
                      presentation consisting the following minimum agenda:
                    </h4>
                    <ul>
                      <li>
                        The Problem Statement - What problem does the project
                        solve.
                      </li>
                      <li>Motivation - Why did you choose this topic?</li>
                      <li>
                        Existing Solution - What are the existing solutions
                        already out there. [review of what has been done in
                        which country and how is it serving]
                      </li>
                      <li>
                        The Proposed Solution - How does the solution address
                        the problem identified.
                      </li>
                      <li>
                        The Services being planned to use - What third party
                        services are being planned to use and why.
                      </li>
                      <li>
                        Architectural Diagram - Outline of solution architecture
                        [Block Diagram, Use Case, BPM etc]
                      </li>
                      <li>
                        Business Benefit - How this solution will impact the
                        business.
                      </li>
                      <li>
                        Challenges - What are the hurdles to overcome for the
                        success
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="event2" className="eventTab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="es-item">
                  <div className="es-item_r">
                    <h3>Prototype Round(18th Sep, 2021): </h3>
                    <h4>
                      Only selected teams from the preliminary round will be
                      invited for this Prototype Round. On this round a Team
                      must present a powerpoint presentation along with a
                      working prototype and demo video of their proposed
                      solution of the Idea round which consisting of the
                      following:
                    </h4>
                    <h4 className="padding">
                      <span>Powerpoint Presentation:</span>
                    </h4>
                    <ul>
                      <li>
                        {" "}
                        The Problem Statement - What problem does the project
                        solve.
                      </li>
                      <li>
                        The Services used - What third party services were used
                        [Tools, Technologies, softwares, hardwares etc.]
                      </li>
                      <li> Back-end Architecture</li>
                      <li> How datasets were obtained for the project</li>
                      <li> Sustainability and Business</li>
                      <li> Use case with the real users in the field</li>
                      <li>
                        Challenges &amp; Threats - What challenges was faced and
                        what are the areas that concerns the future of this
                        project
                      </li>
                    </ul>
                    <h4 className="padding">
                      <span>Prototype:</span>
                    </h4>
                    <ul>
                      <li>Conceptual or Wireframe of the project</li>
                    </ul>

                    <h4 className="padding">
                      <span>Demo Video:</span>
                    </h4>
                    <ul>
                      <li>
                        Full working video of Development with explanation of
                        each features{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="event3" className="eventTab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="es-item">
                  <div className="es-item_r">
                    <h3>Demonstration Round(25th Sep, 2021):</h3>
                    <h4>
                      The best idea with implementation will be selected in this
                      round from the Prototype Round. In this round a team must
                      submit the following:
                    </h4>
                    <h4 className="padding">
                      <span>Working Solution:</span>
                    </h4>
                    <ul>
                      <li>Developed solution based on feedback from the Prototype round</li>
                    </ul>
                    <h4 className="padding">
                      <span>Demo Video</span>
                    </h4>
                    <ul>
                      <li>The marketing video of the proposed solution</li>
                      <li>The Journey</li>
                      <li>How the Challenges &amp; Threats were managed</li>
                      <li>Business opportunities of the current project along with project features</li>
                      <li>Supporting Tools, Technologies, Online Resources </li>
                    </ul>
                    <h4 className="padding">
                      <span>
                        GitHub link [Public] of the Development work along with
                        the following informations:
                      </span>
                    </h4>
                    <ul>
                      <li>
                        Project ID - Which is Provided to you after Registration
                      </li>
                      <li>
                        Team Members - Name of each team members with contact
                        information (email and phone number)
                      </li>
                      <li>
                        Project Short Description - Short synopsis of the
                        problem you are solving and summarize your solution to
                        the chosen category challenge (100 words max)
                      </li>
                    </ul>
{/* 
                    <h4 className="padding">
                      <span>Demo Video:</span>
                    </h4>
                    <ul>
                      <li>
                        Full working video of Development with explanation of
                        each features{" "}
                      </li>
                    </ul>

                    <h4 className="padding">
                      <span>
                        GitHub link [Public] of the Development work along with
                        the following informations
                      </span>
                    </h4>
                    <ul>
                      <li>
                        Project ID - Which is Provided to you after Registration
                      </li>
                      <li>
                        Team Members - Name of each team members with contact
                        information (email and phone number)
                      </li>
                      <li>
                        Project Short Description - Short synopsis of the
                        problem you are solving and summarize your solution to
                        the chosen category challenge (100 words max)
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSchedule2021;

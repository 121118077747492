import React, { useEffect, useState } from "react";
import EducationFieldsComponent from "./educationFieldsComponent";
import { API } from "aws-amplify";

import { createEducation, updateEducation } from "../../../graphql/mutations";

export default function Education({ educationData, setContentType }) {
    const [educationFields, setEducationFields] = useState([]);
    const [inputEducationData, setInputEducationData] = useState([
        { qualification: "", institution: "", board: "", passingYear: "" },
        { qualification: "", institution: "", board: "", passingYear: "" },
    ]);
    const [nextStatus, setNextStatus] = useState(false);

    const addInputEducationData = () => {
        // debugger;
        let array = inputEducationData;
        array.push({ qualification: "", institution: "", board: "", passingYear: "" });
        setInputEducationData(array);
    };

    const remountEducationFields = () => {
        let a = [];
        setEducationFields([]);
        // debugger;
        for (var i = 0; i < inputEducationData.length; i++) {
            a.push(
                <EducationFieldsComponent
                    key={i}
                    index={i}
                    educationFieldData={inputEducationData}
                    setEducationFieldData={setInputEducationData}
                />
            );
        }
        setEducationFields(a);
    };

    const addNewEducationFields = () => {
        // debugger;
        addInputEducationData();

        remountEducationFields();
    };

    useEffect(() => {
        if (educationData.length) {
            setInputEducationData([...educationData]);
        }
    }, [educationData]);

    useEffect(() => {
        // console.log("inputEducationData:", inputEducationData);
        if (!educationFields.length) remountEducationFields();
        if (educationFields.length != inputEducationData) remountEducationFields();
    }, [inputEducationData]);

    // next button enable disable controlling
    useEffect(() => {
        var i;
        for (i = 0; i < inputEducationData.length; i++) {
            // console.log(
            //     inputEducationData[i]["qualification"],
            //     inputEducationData[i]["institution"],
            //     inputEducationData[i]["board"],
            //     inputEducationData[i]["passingYear"]
            // );
            if (!inputEducationData[i]["qualification"].length) {
                setNextStatus(false);
                break;
            }
            if (!inputEducationData[i]["institution"].length) {
                setNextStatus(false);
                break;
            }
            if (!inputEducationData[i]["board"].length) {
                setNextStatus(false);
                break;
            }
            if (!inputEducationData[i]["passingYear"].length) {
                setNextStatus(false);
                break;
            }
        }
        if (i == inputEducationData.length) {
            setNextStatus(true);
        }
    });

    const saveData = async () => {
        // console.log(inputEducationData);
        for (var i = 0; i < inputEducationData.length; i++) {
            // if id field is missing, it means, we need to create new element
            if (!inputEducationData[i].id) {
                // converting passing year to datetime object :(
                let data = inputEducationData[i];
                data.memberID = localStorage.getItem("id");
                const newEducation = await API.graphql({
                    query: createEducation,
                    variables: {
                        input: {
                            // ...inputEducationData[i],
                            ...data,
                        },
                    },
                });
                // console.log("new education", newEducation);
            }
            // else update it
            else {
                let data = inputEducationData[i];
                delete data.createdAt;
                delete data.updatedAt;
                const updatedEducation = await API.graphql({
                    query: updateEducation,
                    variables: {
                        input: {
                            // ...inputEducationData[i],
                            ...data,
                        },
                    },
                });
                // console.log("new updated education", updatedEducation);
            }
        }
    };

    const handleNext = () => {
        saveData();
        setContentType(3);
    };
    const handlePrev = () => {
        //saveData();
        setContentType(1);
    };
    

    return (
        <div className="shadow p-4 rounded bg-white" data-animation="scaleIn">
            <div className="multisteps-form__content">
                <div className="multisteps-content-item">
                    <h2>Educational Info</h2>
                    <p className="xs-hidden" type="button" title="Next" onClick={addNewEducationFields}>
                        Add Another Qualification <i class="fas fa-plus-circle"></i>
                    </p>
                </div>
                <div>{educationFields}</div>
                <p className="xl-hidden" type="button" title="Next" onClick={addNewEducationFields}>
                        Add Another Qualification <i class="fas fa-plus-circle"></i>
                    </p>
            </div>
            <div className="button-row text-right mt-4">
                <button  onClick={handlePrev} className="js-btn-prev" type="button" title="Prev">
                    Prev
                </button>
                <button
                    className="ml-auto js-btn-SAVE"
                    onClick={saveData}
                    type="button"
                    title="SAVE"
                >
                    SAVE
                </button>
                {/* <h3>{nextStatus ? "hello" : "not hello"}</h3> */}
                <button
                    className="ml-auto js-btn-next"
                    onClick={handleNext}
                    disabled={!nextStatus}
                    type="button"
                    title="Next"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

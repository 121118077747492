import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { listEducations, getMember, listJobInfos } from "../../../graphql/queries";
import PersonalInfo from "../personal-info/personalInfo";
import Education from "../education/education";
import JobInfo from "../job-info/jobInfo";
import Payment from "../payment/payment";
import "./memberRegistration.css";

export default function MemberRegistrationComponent() {
    const [userData, setUserData] = useState();
    const [educationData, setEducationData] = useState();
    const [jobsData, setJobsData] = useState();
    const [industryData, setIndustryData] = useState();
    const [expertiesData, setExpertiesData] = useState();
    const [contentType, setContentType] = useState(1);
    const history = useHistory();

    useEffect(async () => {
        let userEmail = localStorage.getItem("email");
        if (!userEmail) {
            history.push("/login");
        } else {
            if (userEmail) {
                // fetch personal information
                let userId = localStorage.getItem("userId");
                let user = await API.graphql({
                    query: getMember,
                    variables: {
                        id: userId,
                    },
                });
                user = user.data.getMember;
                localStorage.setItem("id", user.id);
                delete user.createdAt;
                delete user.updatedAt;
                setUserData(user);

                // fetch education information
                let education = await API.graphql({
                    query: listEducations,
                    variables: {
                        filter: {
                            memberID: {
                                eq: user.id,
                            },
                        },
                    },
                });
                education = education.data.listEducations.items;
                education.reverse();
                setEducationData(education);
                // console.log("educationData", education);

                // fetch job information
                let jobs = await API.graphql({
                    query: listJobInfos,
                    variables: {
                        filter: {
                            memberID: {
                                eq: user.id,
                            },
                        },
                    },
                });
                jobs = jobs.data.listJobInfos.items;
                jobs.reverse();
                // console.log("jobInfoData", jobs);
                setJobsData(jobs);

                // fetch industry list
                let getIndustryList = `#graphql
                    query IndustryListQuery {
                        listCommonDDLs(filter: {ddlType: {eq: Industry}}) {
                                    items {
                                        id
                                        name
                                    }
                                }
                            }
                `;
                let industryList = await API.graphql({
                    query: getIndustryList,
                });
                industryList = industryList.data.listCommonDDLs.items;
                // console.log("industryData", industryList);
                setIndustryData(industryList);

                // fetch experties list
                let getExpertiesList = `#graphql
                    query ExpertiesListQuery {
                        listCommonDDLs(filter: {ddlType: {eq: Expertise}}) {
                                items {
                                        id
                                        name
                                    }
                            }
                        }
                `;
                let expertiesList = await API.graphql({
                    query: getExpertiesList,
                });
                expertiesList = expertiesList.data.listCommonDDLs.items;
                // console.log("expertiesData", expertiesList);
                setExpertiesData(expertiesList);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <div className="membership_header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="membership_heading text-center">
                                <h2>Membership Registration</h2>
                                <p>
                                    Orci suscipit sit urna egestas orci. Tincidunt blandit sapien,
                                    sit sed egestas nulla viverra vel. Ac id metus tristique <br />{" "}
                                    egestas elementum tellus. Commodo ac tellus parturient nisl
                                    sagittis.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="membership_reg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="membership_overflow">
                                <div className="multisteps-form">
                                    {/* for desktop */}
                                    <div className="multisteps-form__progress mobile-hidden">
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType >= 1 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="User Info"
                                            onClick={() => setContentType(1)}
                                        >
                                            <span></span>
                                            <p>Personal Info</p>
                                            <div className="next">
                                                {contentType == 1 && <p>next</p>}
                                                {contentType > 1 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div>
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType >= 2 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Address"
                                            onClick={() => setContentType(2)}
                                        >
                                            <span></span>
                                            <p>Educational Info</p>
                                            <div className="next">
                                                {contentType == 2 && <p>next</p>}
                                                {contentType > 2 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div>
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType >= 3 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Order Info"
                                            onClick={() => setContentType(3)}
                                        >
                                            <span></span>
                                            <p>Professional Info</p>
                                            <div className="next">
                                                {contentType == 3 && <p>next</p>}
                                                {contentType > 3 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div>
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType == 4 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Comments"
                                            onClick={() => setContentType(4)}
                                        >
                                            <span></span>
                                            <p>Payment</p>
                                        </button>
                                    </div>

                                    {/* for mobile */}
                                    <div className="multisteps-form__progress desktop-hidden">
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType == 1 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="User Info"
                                            onClick={() => setContentType(1)}
                                        >
                                            <span></span>
                                            <p>Personal Info</p>
                                            <span className="count-pages">01/04</span>
                                            {/* <div className="next">
                                                {contentType == 1 && <p>next</p>}
                                                {contentType > 1 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div> */}
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType == 2 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Address"
                                            onClick={() => setContentType(2)}
                                        >
                                            <span></span>
                                            <p>Educational Info</p>
                                            <span className="count-pages">02/04</span>
                                            {/* <div className="next">
                                                {contentType == 2 && <p>next</p>}
                                                {contentType > 2 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div> */}
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType == 3 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Order Info"
                                            onClick={() => setContentType(3)}
                                        >
                                            <span></span>
                                            <p>Professional Info</p>
                                            <span className="count-pages">03/04</span>
                                            {/* <div className="next">
                                                {contentType == 3 && <p>next</p>}
                                                {contentType > 3 && (
                                                    <svg
                                                        width="28"
                                                        height="28"
                                                        viewBox="0 0 28 28"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="14"
                                                            cy="14"
                                                            r="14"
                                                            fill="#E51010"
                                                        />
                                                        <g clip-path="url(#clip0)">
                                                            <path
                                                                d="M13.1267 18.8485C13.0172 18.9758 12.8278 18.9855 12.7058 18.87L8.09507 14.5061C7.9731 14.3907 7.9678 14.1965 8.08316 14.0745L9.55217 12.5222C9.6676 12.4002 9.86182 12.3949 9.98379 12.5103L12.5025 14.894C12.6244 15.0094 12.8138 14.9997 12.9233 14.8724L17.844 9.15511C17.9535 9.02784 18.1473 9.01331 18.2745 9.12288L19.8942 10.517C20.0214 10.6265 20.036 10.8203 19.9264 10.9475L13.1267 18.8485Z"
                                                                fill="white"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect
                                                                    width="12"
                                                                    height="12"
                                                                    fill="white"
                                                                    transform="translate(8 8)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                )}
                                            </div> */}
                                        </button>
                                        <button
                                            className={
                                                "multisteps-form__progress-btn" +
                                                (contentType == 4 ? " js-active" : "")
                                            }
                                            type="button"
                                            title="Comments"
                                            onClick={() => setContentType(4)}
                                        >
                                            <span></span>
                                            <p>Payment</p>
                                            <span className="count-pages">04/04</span>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-12">
                                            {contentType == 1 && (
                                                <PersonalInfo
                                                    userData={userData}
                                                    setContentType={setContentType}
                                                />
                                            )}
                                            {contentType == 2 && (
                                                <Education
                                                    educationData={educationData}
                                                    setContentType={setContentType}
                                                />
                                            )}
                                            {contentType == 3 && (
                                                <JobInfo
                                                    jobsData={jobsData}
                                                    industryData={industryData}
                                                    expertiesData={expertiesData}
                                                    userData={userData}
                                                    setContentType={setContentType}
                                                />
                                            )}
                                            {contentType == 4 && <Payment userData={userData} />}
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-12 m-auto">
                                            <PersonalInfo userData={userData} />
                                            <Education educationData={educationData} />
                                            <JobInfo
                                                jobsData={jobsData}
                                                industryData={industryData}
                                                expertiesData={expertiesData}
                                                userData={userData}
                                            />
                                            <Payment userData={userData} />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

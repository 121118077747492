/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      fullName
      email
      mobile
      password
      gender
      fathersName
      mothersName
      dob
      maritalStatus
      pob
      permanentAddress
      homePhone
      cellPhone
      workPhone
      bloodGroup
      mailingAddress
      personalEmail
      disciplinaryAction
      file1
      file2
      professionID
      status
      profilePhotoUrl
      coverPhotoUrl
      officePhone
      proffesionalEmail
      mailingArea
      mailingZip
      mailingCityID
      mailingCountryID
      permanentArea
      permanentZip
      permanentCityID
      permanentCountryID
      IndustryID
      IsDischarged
      dischargedReason
      facebook
      linkedin
      tweeter
      website
      userType
      intro
      positionID
      skills
      expertise
      certification
      awards
      memberType
      payment {
        nextToken
      }
      committee {
        nextToken
      }
      educationInfo {
        nextToken
      }
      jobInfo {
        nextToken
      }
      profession {
        id
        professionName
        isActive
        isRestricted
        createdAt
        updatedAt
      }
      permanentCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      permanentCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      mailingCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      mailingCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      fullName
      email
      mobile
      password
      gender
      fathersName
      mothersName
      dob
      maritalStatus
      pob
      permanentAddress
      homePhone
      cellPhone
      workPhone
      bloodGroup
      mailingAddress
      personalEmail
      disciplinaryAction
      file1
      file2
      professionID
      status
      profilePhotoUrl
      coverPhotoUrl
      officePhone
      proffesionalEmail
      mailingArea
      mailingZip
      mailingCityID
      mailingCountryID
      permanentArea
      permanentZip
      permanentCityID
      permanentCountryID
      IndustryID
      IsDischarged
      dischargedReason
      facebook
      linkedin
      tweeter
      website
      userType
      intro
      positionID
      skills
      expertise
      certification
      awards
      memberType
      payment {
        nextToken
      }
      committee {
        nextToken
      }
      educationInfo {
        nextToken
      }
      jobInfo {
        nextToken
      }
      profession {
        id
        professionName
        isActive
        isRestricted
        createdAt
        updatedAt
      }
      permanentCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      permanentCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      mailingCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      mailingCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      fullName
      email
      mobile
      password
      gender
      fathersName
      mothersName
      dob
      maritalStatus
      pob
      permanentAddress
      homePhone
      cellPhone
      workPhone
      bloodGroup
      mailingAddress
      personalEmail
      disciplinaryAction
      file1
      file2
      professionID
      status
      profilePhotoUrl
      coverPhotoUrl
      officePhone
      proffesionalEmail
      mailingArea
      mailingZip
      mailingCityID
      mailingCountryID
      permanentArea
      permanentZip
      permanentCityID
      permanentCountryID
      IndustryID
      IsDischarged
      dischargedReason
      facebook
      linkedin
      tweeter
      website
      userType
      intro
      positionID
      skills
      expertise
      certification
      awards
      memberType
      payment {
        nextToken
      }
      committee {
        nextToken
      }
      educationInfo {
        nextToken
      }
      jobInfo {
        nextToken
      }
      profession {
        id
        professionName
        isActive
        isRestricted
        createdAt
        updatedAt
      }
      permanentCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      permanentCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      mailingCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      mailingCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMemberCommittee = /* GraphQL */ `
  mutation CreateMemberCommittee(
    $input: CreateMemberCommitteeInput!
    $condition: ModelMemberCommitteeConditionInput
  ) {
    createMemberCommittee(input: $input, condition: $condition) {
      id
      committeeID
      memberID
      committyType
      isActive
      member {
        nextToken
      }
      common {
        id
        ddlType
        name
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberCommittee = /* GraphQL */ `
  mutation UpdateMemberCommittee(
    $input: UpdateMemberCommitteeInput!
    $condition: ModelMemberCommitteeConditionInput
  ) {
    updateMemberCommittee(input: $input, condition: $condition) {
      id
      committeeID
      memberID
      committyType
      isActive
      member {
        nextToken
      }
      common {
        id
        ddlType
        name
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberCommittee = /* GraphQL */ `
  mutation DeleteMemberCommittee(
    $input: DeleteMemberCommitteeInput!
    $condition: ModelMemberCommitteeConditionInput
  ) {
    deleteMemberCommittee(input: $input, condition: $condition) {
      id
      committeeID
      memberID
      committyType
      isActive
      member {
        nextToken
      }
      common {
        id
        ddlType
        name
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      memberID
      amount
      paymentDate
      memberFeesId
      member {
        nextToken
      }
      fees {
        nextToken
      }
      memberSettings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      memberID
      amount
      paymentDate
      memberFeesId
      member {
        nextToken
      }
      fees {
        nextToken
      }
      memberSettings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      memberID
      amount
      paymentDate
      memberFeesId
      member {
        nextToken
      }
      fees {
        nextToken
      }
      memberSettings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEducation = /* GraphQL */ `
  mutation CreateEducation(
    $input: CreateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    createEducation(input: $input, condition: $condition) {
      id
      memberID
      qualification
      institution
      board
      passingYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEducation = /* GraphQL */ `
  mutation UpdateEducation(
    $input: UpdateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    updateEducation(input: $input, condition: $condition) {
      id
      memberID
      qualification
      institution
      board
      passingYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEducation = /* GraphQL */ `
  mutation DeleteEducation(
    $input: DeleteEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    deleteEducation(input: $input, condition: $condition) {
      id
      memberID
      qualification
      institution
      board
      passingYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJobInfo = /* GraphQL */ `
  mutation CreateJobInfo(
    $input: CreateJobInfoInput!
    $condition: ModelJobInfoConditionInput
  ) {
    createJobInfo(input: $input, condition: $condition) {
      id
      memberID
      designation
      organization
      startYear
      endYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJobInfo = /* GraphQL */ `
  mutation UpdateJobInfo(
    $input: UpdateJobInfoInput!
    $condition: ModelJobInfoConditionInput
  ) {
    updateJobInfo(input: $input, condition: $condition) {
      id
      memberID
      designation
      organization
      startYear
      endYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobInfo = /* GraphQL */ `
  mutation DeleteJobInfo(
    $input: DeleteJobInfoInput!
    $condition: ModelJobInfoConditionInput
  ) {
    deleteJobInfo(input: $input, condition: $condition) {
      id
      memberID
      designation
      organization
      startYear
      endYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProfession = /* GraphQL */ `
  mutation CreateProfession(
    $input: CreateProfessionInput!
    $condition: ModelProfessionConditionInput
  ) {
    createProfession(input: $input, condition: $condition) {
      id
      professionName
      isActive
      isRestricted
      createdAt
      updatedAt
    }
  }
`;
export const updateProfession = /* GraphQL */ `
  mutation UpdateProfession(
    $input: UpdateProfessionInput!
    $condition: ModelProfessionConditionInput
  ) {
    updateProfession(input: $input, condition: $condition) {
      id
      professionName
      isActive
      isRestricted
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfession = /* GraphQL */ `
  mutation DeleteProfession(
    $input: DeleteProfessionInput!
    $condition: ModelProfessionConditionInput
  ) {
    deleteProfession(input: $input, condition: $condition) {
      id
      professionName
      isActive
      isRestricted
      createdAt
      updatedAt
    }
  }
`;
export const createMemberFees = /* GraphQL */ `
  mutation CreateMemberFees(
    $input: CreateMemberFeesInput!
    $condition: ModelMemberFeesConditionInput
  ) {
    createMemberFees(input: $input, condition: $condition) {
      id
      amount
      isActive
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const updateMemberFees = /* GraphQL */ `
  mutation UpdateMemberFees(
    $input: UpdateMemberFeesInput!
    $condition: ModelMemberFeesConditionInput
  ) {
    updateMemberFees(input: $input, condition: $condition) {
      id
      amount
      isActive
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const deleteMemberFees = /* GraphQL */ `
  mutation DeleteMemberFees(
    $input: DeleteMemberFeesInput!
    $condition: ModelMemberFeesConditionInput
  ) {
    deleteMemberFees(input: $input, condition: $condition) {
      id
      amount
      isActive
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const createBecomeAPartner = /* GraphQL */ `
  mutation CreateBecomeAPartner(
    $input: CreateBecomeAPartnerInput!
    $condition: ModelBecomeAPartnerConditionInput
  ) {
    createBecomeAPartner(input: $input, condition: $condition) {
      id
      companyName
      webAddress
      companyEmail
      contactPersonName
      designation
      personalEmail
      mobile
      country
      purpose
      message
      profession
      purposeType
      createdAt
      updatedAt
    }
  }
`;
export const updateBecomeAPartner = /* GraphQL */ `
  mutation UpdateBecomeAPartner(
    $input: UpdateBecomeAPartnerInput!
    $condition: ModelBecomeAPartnerConditionInput
  ) {
    updateBecomeAPartner(input: $input, condition: $condition) {
      id
      companyName
      webAddress
      companyEmail
      contactPersonName
      designation
      personalEmail
      mobile
      country
      purpose
      message
      profession
      purposeType
      createdAt
      updatedAt
    }
  }
`;
export const deleteBecomeAPartner = /* GraphQL */ `
  mutation DeleteBecomeAPartner(
    $input: DeleteBecomeAPartnerInput!
    $condition: ModelBecomeAPartnerConditionInput
  ) {
    deleteBecomeAPartner(input: $input, condition: $condition) {
      id
      companyName
      webAddress
      companyEmail
      contactPersonName
      designation
      personalEmail
      mobile
      country
      purpose
      message
      profession
      purposeType
      createdAt
      updatedAt
    }
  }
`;
export const createMembershipSettings = /* GraphQL */ `
  mutation CreateMembershipSettings(
    $input: CreateMembershipSettingsInput!
    $condition: ModelMembershipSettingsConditionInput
  ) {
    createMembershipSettings(input: $input, condition: $condition) {
      id
      paymentID
      memberType
      JoiningFee
      YearlyFee
      description
      benifit
      requirement
      is_active
      createdAt
      updatedAt
    }
  }
`;
export const updateMembershipSettings = /* GraphQL */ `
  mutation UpdateMembershipSettings(
    $input: UpdateMembershipSettingsInput!
    $condition: ModelMembershipSettingsConditionInput
  ) {
    updateMembershipSettings(input: $input, condition: $condition) {
      id
      paymentID
      memberType
      JoiningFee
      YearlyFee
      description
      benifit
      requirement
      is_active
      createdAt
      updatedAt
    }
  }
`;
export const deleteMembershipSettings = /* GraphQL */ `
  mutation DeleteMembershipSettings(
    $input: DeleteMembershipSettingsInput!
    $condition: ModelMembershipSettingsConditionInput
  ) {
    deleteMembershipSettings(input: $input, condition: $condition) {
      id
      paymentID
      memberType
      JoiningFee
      YearlyFee
      description
      benifit
      requirement
      is_active
      createdAt
      updatedAt
    }
  }
`;
export const createMagazine = /* GraphQL */ `
  mutation CreateMagazine(
    $input: CreateMagazineInput!
    $condition: ModelMagazineConditionInput
  ) {
    createMagazine(input: $input, condition: $condition) {
      id
      issue_no
      issue_name
      image_url
      description
      writers
      featuredArticle
      publicationDate
      documentUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateMagazine = /* GraphQL */ `
  mutation UpdateMagazine(
    $input: UpdateMagazineInput!
    $condition: ModelMagazineConditionInput
  ) {
    updateMagazine(input: $input, condition: $condition) {
      id
      issue_no
      issue_name
      image_url
      description
      writers
      featuredArticle
      publicationDate
      documentUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteMagazine = /* GraphQL */ `
  mutation DeleteMagazine(
    $input: DeleteMagazineInput!
    $condition: ModelMagazineConditionInput
  ) {
    deleteMagazine(input: $input, condition: $condition) {
      id
      issue_no
      issue_name
      image_url
      description
      writers
      featuredArticle
      publicationDate
      documentUrl
      createdAt
      updatedAt
    }
  }
`;
export const createCommonDdl = /* GraphQL */ `
  mutation CreateCommonDdl(
    $input: CreateCommonDDLInput!
    $condition: ModelCommonDDLConditionInput
  ) {
    createCommonDDL(input: $input, condition: $condition) {
      id
      ddlType
      name
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateCommonDdl = /* GraphQL */ `
  mutation UpdateCommonDdl(
    $input: UpdateCommonDDLInput!
    $condition: ModelCommonDDLConditionInput
  ) {
    updateCommonDDL(input: $input, condition: $condition) {
      id
      ddlType
      name
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommonDdl = /* GraphQL */ `
  mutation DeleteCommonDdl(
    $input: DeleteCommonDDLInput!
    $condition: ModelCommonDDLConditionInput
  ) {
    deleteCommonDDL(input: $input, condition: $condition) {
      id
      ddlType
      name
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      eventName
      description
      address
      imageUrl
      fromDate
      toDate
      isComplete
      guest {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      eventName
      description
      address
      imageUrl
      fromDate
      toDate
      isComplete
      guest {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      eventName
      description
      address
      imageUrl
      fromDate
      toDate
      isComplete
      guest {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGuest = /* GraphQL */ `
  mutation CreateGuest(
    $input: CreateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    createGuest(input: $input, condition: $condition) {
      id
      eventID
      guestName
      designation
      company
      imageUrl
      guestStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateGuest = /* GraphQL */ `
  mutation UpdateGuest(
    $input: UpdateGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    updateGuest(input: $input, condition: $condition) {
      id
      eventID
      guestName
      designation
      company
      imageUrl
      guestStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteGuest = /* GraphQL */ `
  mutation DeleteGuest(
    $input: DeleteGuestInput!
    $condition: ModelGuestConditionInput
  ) {
    deleteGuest(input: $input, condition: $condition) {
      id
      eventID
      guestName
      designation
      company
      imageUrl
      guestStatus
      createdAt
      updatedAt
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      preID
      countryName
      countryPhoneCode
      countryStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      preID
      countryName
      countryPhoneCode
      countryStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      preID
      countryName
      countryPhoneCode
      countryStatus
      createdAt
      updatedAt
    }
  }
`;
export const createState = /* GraphQL */ `
  mutation CreateState(
    $input: CreateStateInput!
    $condition: ModelStateConditionInput
  ) {
    createState(input: $input, condition: $condition) {
      id
      countryID
      preCountryID
      preStateID
      stateName
      stateStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateState = /* GraphQL */ `
  mutation UpdateState(
    $input: UpdateStateInput!
    $condition: ModelStateConditionInput
  ) {
    updateState(input: $input, condition: $condition) {
      id
      countryID
      preCountryID
      preStateID
      stateName
      stateStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteState = /* GraphQL */ `
  mutation DeleteState(
    $input: DeleteStateInput!
    $condition: ModelStateConditionInput
  ) {
    deleteState(input: $input, condition: $condition) {
      id
      countryID
      preCountryID
      preStateID
      stateName
      stateStatus
      createdAt
      updatedAt
    }
  }
`;

import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Formik, useFormik } from "formik";
import { listEducations, getMember, listJobInfos } from "../../../graphql/queries";
import PersonalInfo from "../personal-info/personalInfo";
import Education from "../education/education";
import JobInfo from "../job-info/jobInfo";
import Payment from "../payment/payment";
import "./memberRegistration.css";

export default function MemberEditComponent() {
    const [userData, setUserData] = useState();
    const [educationData, setEducationData] = useState();
    const [jobsData, setJobsData] = useState();
    const [industryData, setIndustryData] = useState();
    const [expertiesData, setExpertiesData] = useState();
    const [contentType, setContentType] = useState(1);
    const [menuType, setMenuType] = useState(1);
    const history = useHistory();

    const validateResetForm = (values) => {
        const errors = {};

        if (!values.currentPassword) errors.currentPassword = "Current password is required";
        if (!values.newPassword) errors.newPassword = "New password is required";
        if (!values.retypeNewPassword) errors.retypeNewPassword = "Retype new password is required";
        if (
            values.newPassword &&
            values.retypeNewPassword &&
            values.newPassword != values.retypeNewPassword
        )
            errors.retypeNewPassword = "Password didn't match";

        return errors;
    };

    const resetPassword = async (values) => {
        let authenticatedUser = await Auth.currentAuthenticatedUser();
        if (authenticatedUser) {
            let changedPassword = false;
            try {
                changedPassword = await Auth.changePassword(
                    authenticatedUser,
                    values.currentPassword,
                    values.newPassword
                );
            } catch (e) {
                passwordResetForm.errors.currentPassword = e.message;
                // debugger;
            }
            if (changedPassword) {
                passwordResetForm.resetForm();
                // debugger;
            }
            // debugger;
        }
        console.log("changing password here...");
    };

    const passwordResetForm = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            retypeNewPassword: "",
        },
        validate: validateResetForm,
        onSubmit: resetPassword,
    });

    const logout = () => {
        localStorage.clear();
        history.push("/");
    };

    useEffect(async () => {
        let userEmail = localStorage.getItem("email");
        if (!userEmail) {
            history.push("/login");
        } else {
            if (userEmail) {
                // fetch personal information
                let userId = localStorage.getItem("userId");
                let user = await API.graphql({
                    query: getMember,
                    variables: {
                        id: userId,
                    },
                });
                user = user.data.getMember;
                localStorage.setItem("id", user.id);
                delete user.createdAt;
                delete user.updatedAt;
                setUserData(user);

                // fetch education information
                let education = await API.graphql({
                    query: listEducations,
                    variables: {
                        filter: {
                            memberID: {
                                eq: user.id,
                            },
                        },
                    },
                });
                education = education.data.listEducations.items;
                education.reverse();
                setEducationData(education);
                // console.log("educationData", education);

                // fetch job information
                let jobs = await API.graphql({
                    query: listJobInfos,
                    variables: {
                        filter: {
                            memberID: {
                                eq: user.id,
                            },
                        },
                    },
                });
                jobs = jobs.data.listJobInfos.items;
                jobs.reverse();
                // console.log("jobInfoData", jobs);
                setJobsData(jobs);

                // fetch industry list
                let getIndustryList = `#graphql
                    query IndustryListQuery {
                        listCommonDDLs(filter: {ddlType: {eq: Industry}}) {
                                    items {
                                        id
                                        name
                                    }
                                }
                            }
                `;
                let industryList = await API.graphql({
                    query: getIndustryList,
                });
                industryList = industryList.data.listCommonDDLs.items;
                // console.log("industryData", industryList);
                setIndustryData(industryList);

                // fetch experties list
                let getExpertiesList = `#graphql
                    query ExpertiesListQuery {
                        listCommonDDLs(filter: {ddlType: {eq: Expertise}}) {
                                items {
                                        id
                                        name
                                    }
                            }
                        }
                `;
                let expertiesList = await API.graphql({
                    query: getExpertiesList,
                });
                expertiesList = expertiesList.data.listCommonDDLs.items;
                // console.log("expertiesData", expertiesList);
                setExpertiesData(expertiesList);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <div class="header_height"></div>
            <section id="member_view">
                <div class="container-fluid">
                    <div class="row">
                    <div class="col-lg-3 col-sm-12 xl-hidden">
                                <div class="member_view_list">
                                    <ul>
                                        <li>
                                            <a
                                                className={menuType == 1 ? "active" : ""}
                                                onClick={() => setMenuType(1)}
                                            >
                                                Edit Member Details
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={menuType == 2 ? "active" : ""}
                                                onClick={() => setMenuType(2)}
                                            >
                                                Account Settings
                                            </a>
                                        </li>
                                        <li>
                                            <a>Billing Settings</a>
                                        </li>
                                        <li>
                                            <a onClick={logout}>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        <div class="col-lg-6">
                            <div class="header_member">
                                <h2>Edit Member Details</h2>
                                <p>Blandit eget urna, a, turpis molestie quam sed quam.</p>
                            </div>
                        </div>
                    </div>
                    <div class="member_view_content">
                        <div class="row">
                            <div class="col-lg-3 xs-hidden">
                                <div class="member_view_list">
                                    <ul>
                                        <li>
                                            <a
                                                className={menuType == 1 ? "active" : ""}
                                                onClick={() => setMenuType(1)}
                                            >
                                                Edit Member Details
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={menuType == 2 ? "active" : ""}
                                                onClick={() => setMenuType(2)}
                                            >
                                                Account Settings
                                            </a>
                                        </li>
                                        <li>
                                            <a>Billing Settings</a>
                                        </li>
                                        <li>
                                            <a onClick={logout}>Logout</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {menuType == 1 && (
                                <div className="col-lg-9">
                                    <div className="membership_reg">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="membership_overflow">
                                                        <div className="multisteps-form">
                                                            <div className="multisteps-form__progress">
                                                                <button
                                                                    className={
                                                                        "multisteps-form__progress-btn" +
                                                                        (contentType == 1
                                                                            ? " js-active"
                                                                            : "")
                                                                    }
                                                                    type="button"
                                                                    title="User Info"
                                                                    onClick={() =>
                                                                        setContentType(1)
                                                                    }
                                                                >
                                                                    <p>Personal Info</p>
                                                                </button>
                                                                <button
                                                                    className={
                                                                        "multisteps-form__progress-btn" +
                                                                        (contentType == 2
                                                                            ? " js-active"
                                                                            : "")
                                                                    }
                                                                    type="button"
                                                                    title="Address"
                                                                    onClick={() =>
                                                                        setContentType(2)
                                                                    }
                                                                >
                                                                    <p>Educational Info</p>
                                                                </button>
                                                                <button
                                                                    className={
                                                                        "multisteps-form__progress-btn" +
                                                                        (contentType == 3
                                                                            ? " js-active"
                                                                            : "")
                                                                    }
                                                                    type="button"
                                                                    title="Order Info"
                                                                    onClick={() =>
                                                                        setContentType(3)
                                                                    }
                                                                >
                                                                    <p>Professional Info</p>
                                                                </button>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {contentType == 1 && (
                                                                        <PersonalInfo
                                                                            userData={userData}
                                                                        />
                                                                    )}
                                                                    {contentType == 2 && (
                                                                        <Education
                                                                            educationData={
                                                                                educationData
                                                                            }
                                                                        />
                                                                    )}
                                                                    {contentType == 3 && (
                                                                        <JobInfo
                                                                            jobsData={jobsData}
                                                                            industryData={
                                                                                industryData
                                                                            }
                                                                            expertiesData={
                                                                                expertiesData
                                                                            }
                                                                            userData={userData}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {menuType == 2 && (
                                <div className="col-lg-9">
                                    <div className="reset_password">
                                        <div className="container-fluid">
                                            <div className="">
                                                <h2>Password Reset</h2>

                                                <form onSubmit={passwordResetForm.handleSubmit}>
                                                    <div className="mt-4">
                                                        <label>Current Password</label>

                                                        <input
                                                            className="multisteps-form__input form-control"
                                                            id="currentPassword"
                                                            type="password"
                                                            onChange={
                                                                passwordResetForm.handleChange
                                                            }
                                                            onBlur={passwordResetForm.handleBlur}
                                                        />
                                                        {passwordResetForm.touched
                                                            .currentPassword &&
                                                            passwordResetForm.errors
                                                                .currentPassword && (
                                                                <span className="text-danger">
                                                                    {
                                                                        passwordResetForm.errors
                                                                            .currentPassword
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                    <div className="mt-4">
                                                        <label>New Password</label>

                                                        <input
                                                            className="multisteps-form__input form-control"
                                                            id="newPassword"
                                                            type="password"
                                                            onChange={
                                                                passwordResetForm.handleChange
                                                            }
                                                            onBlur={passwordResetForm.handleBlur}
                                                        />
                                                        {passwordResetForm.touched.newPassword &&
                                                            passwordResetForm.errors
                                                                .newPassword && (
                                                                <span className="text-danger">
                                                                    {
                                                                        passwordResetForm.errors
                                                                            .newPassword
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                    <div className="mt-4">
                                                        <label>Retype New Password</label>

                                                        <input
                                                            className="multisteps-form__input form-control"
                                                            id="retypeNewPassword"
                                                            type="password"
                                                            onChange={
                                                                passwordResetForm.handleChange
                                                            }
                                                            onBlur={passwordResetForm.handleBlur}
                                                        />
                                                        {passwordResetForm.touched
                                                            .retypeNewPassword &&
                                                            passwordResetForm.errors
                                                                .retypeNewPassword && (
                                                                <span className="text-danger">
                                                                    {
                                                                        passwordResetForm.errors
                                                                            .retypeNewPassword
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                    <button
                                                        class="btn"
                                                        onSubmit={passwordResetForm.handleSubmit}
                                                    >
                                                        Reset Password
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* old code  */}
            {/* <div>
            <div className="membership_reg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="membership_heading text-center">
                                <h2>Membership Registration</h2>
                                <p>
                                    Orci suscipit sit urna egestas orci. Tincidunt blandit sapien,
                                    sit sed egestas nulla viverra vel. Ac id metus tristique <br />{" "}
                                    egestas elementum tellus. Commodo ac tellus parturient nisl
                                    sagittis.
                                </p>
                            </div>
                            <div class="membership_overflow">
                                <div className="multisteps-form">
                                    <div className="row">
                                        <div className="col-12 ml-auto mr-auto mb-4">
                                            <div class="multisteps-form__progress">
                                                <button
                                                    class="multisteps-form__progress-btn js-active"
                                                    type="button"
                                                    title="User Info"
                                                >
                                                    <span></span>
                                                    <p>Personal Info</p>
                                                    <div class="next">
                                                        <p>next</p>
                                                        <i class="fas fa-check-circle"></i>
                                                    </div>
                                                </button>
                                                <button
                                                    class="multisteps-form__progress-btn"
                                                    type="button"
                                                    title="Address"
                                                >
                                                    <span></span>
                                                    <p>Educational Info</p>
                                                    <div class="next">
                                                        <p>next</p>
                                                        <i class="fas fa-check-circle"></i>
                                                    </div>
                                                </button>
                                                <button
                                                    class="multisteps-form__progress-btn"
                                                    type="button"
                                                    title="Order Info"
                                                >
                                                    <span></span>
                                                    <p>Professional Info</p>
                                                    <div class="next">
                                                        <p>next</p>
                                                        <i class="fas fa-check-circle"></i>
                                                    </div>
                                                </button>
                                                <button
                                                    class="multisteps-form__progress-btn"
                                                    type="button"
                                                    title="Comments"
                                                >
                                                    <span></span>
                                                    <p>Payment</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 m-auto">
                                            <form class="multisteps-form__form">
                                                <PersonalInfo userData={userData} />
                                                <Education educationData={educationData} />
                                                <JobInfo
                                                    jobsData={jobsData}
                                                    industryData={industryData}
                                                    expertiesData={expertiesData}
                                                    userData={userData}
                                                />
                                                <Payment userData={userData} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        </React.Fragment>
    );
}

import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { deleteJobInfo } from "../../../graphql/mutations";
import "./jobFieldsComponent.css";

export default function JobFieldsComponent({ index, jobInfoFieldData, setJobInfoFieldData }) {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(jobInfoFieldData[index]);
    }, [jobInfoFieldData]);

    const updateField = (value, field) => {
        let d = data;
        data[field] = value;
        setData({ ...d });

        let dt = jobInfoFieldData;
        dt[index] = d;
        setJobInfoFieldData([...dt]);
    };

    const deleteJob = async () => {
        let dt = jobInfoFieldData;
        let del = dt.splice(index, 1)[0];
        setJobInfoFieldData([...dt]);

        let deletedData = await API.graphql({
            query: deleteJobInfo,
            variables: {
                input: {
                    id: del.id,
                },
            },
        });

        // console.log("deleted job:", deletedData);
    };

    return (
        <div>
            <div className="form-row mt-4">
                <div className="col-xl-3 col-12">
                    <label for="profession">
                        Designation <span>*</span>
                    </label>
                    <input
                        className="multisteps-form__input form-control"
                        type="text"
                        value={data.designation}
                        onChange={(e) => updateField(e.target.value, "designation")}
                        placeholder="Designation"
                    />
                </div>
                <div className="col-xl-3 col-12">
                    <label for="profession">
                        Organization <span>*</span>
                    </label>
                    <input
                        className="multisteps-form__input form-control"
                        type="text"
                        value={data.organization}
                        onChange={(e) => updateField(e.target.value, "organization")}
                        placeholder="Organization "
                    />
                </div>

                <div className="col-xl-3 col-12">
                    <label for="profession">
                        Starting Year <span>*</span>
                    </label>
                    <input
                        className="multisteps-form__input form-control"
                        type="text"
                        value={data.startYear}
                        onChange={(e) => updateField(e.target.value, "startYear")}
                        placeholder="Starting Year"
                    />
                </div>
                <div className="col-xl-3 col-12">
                    <label for="profession">
                        End Year <span>*</span>
                    </label>
                    <input
                        className="multisteps-form__input form-control"
                        type="text"
                        value={data.endYear}
                        onChange={(e) => updateField(e.target.value, "endYear")}
                        placeholder="End Year"
                    />
                    <i className="fas fa-trash-alt del-icon" onClick={deleteJob}></i>
                </div>
            </div>
        </div>
    );
}

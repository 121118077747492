import React from "react";
import Layout from "../../components/common/layout/layout";
import EmailVerificationComponent from "../../components/email-verification/emailVerification";

export default function EmailVerification() {
    return (
        <Layout showBanner={false}>
            <EmailVerificationComponent />
        </Layout>
    );
}

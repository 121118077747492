import React, { useState, useEffect, useRef } from "react"; //{ useState, useEffect }
import Slider from "react-slick";
import { S3Image } from "aws-amplify-react";
import { Storage } from "aws-amplify";
import moment from "moment";
const MdoucUrl = (data) => {
    const [documentUrl, setDocumentUrl] = useState();
    useEffect(async () => {
        let docUrl = await Storage.get(data.data.documentUrl);
        console.log(docUrl, data, "--------------------docUrl");
        setDocumentUrl(docUrl);
    }, []);
    
    return (
        <>
            <p className="mg-issue-title">  {data.data.issue_name},{" "} <span>
          
                {data.data.description}
                </span></p>
            <a href={documentUrl} target="_blank" className="mg-issue-read-now">Read Now</a>
        </>
    );
};

export default MdoucUrl;
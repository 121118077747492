import React from "react";
import Layout from "../../components/common/layout/layout";
import Countdown from "../../components/home/countdown/Countdown";
import Innovation from "../../components/home/innovation/Innovation";
import Ctoforum from "../../components/home/ctoforum/Ctoforum";
// import Benifit from "../../components/home/benifit/Benifit";
import Benefits from "../../components/home/benefits/benefits";
import Ama from "../../components/home/ama/Ama";
import Magazine from "../../components/home/magazine/Magazine";
import Explore from "../../components/home/explore/Explore";
import Follow from "../../components/home/follow/Follow";
import ProudMembers from "../../components/home/proud-members/proudMembers";
import OurMembers from "../../components/home/our-members/ourMembers";

import About from "../../components/home/about/about";

import Events from "../../components/home/events/events";
import Gallery from "../../components/home/gallery/gallery";
import Membership from "../../components/home/membership/membership";
import OurPartners from "../../components/home/our-partners/ourPartners";
import RecentUpdates from "../../components/home/recent-updates/recentUpdates";

export default function Home() {
    return (
        <Layout>
            {/* <Countdown /> */}
            <Innovation />
            <Ctoforum />
            <Benefits />
            <Ama />
            {/* <Magazine /> */}
            <Explore />
            <Follow />
            {/* <ProudMembers /> */}
            {/*   curosol*/}
            <OurMembers />
            <OurPartners />

            {/* <Events />
            <About />
          
            <Gallery />
            <Membership />
             */}
            {/* 
            <RecentUpdates /> */}
            <a href="#" class="back-to-top">
                <i class="fa fa-chevron-up"></i>
            </a>
        </Layout>
    );
}

export default function OurPartners() {
    return (
        <div className="partnership_part">
            <div className="">
                <div className="row ml-0 mr-0">
                    <div className="col-md-12 col-sm-12 text-center">
                        <h2>Collaborations and Partnerships</h2>
                        <p>Brands and industries always fall for immensity. We are immense in being partner in tech matter that helps. Meet our partners! </p>
                        {/* <div className="partnership_slider">
                            <div className="partnership_slider_item">
                                <img src="images/clients-1.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-2.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-3.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-4.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-5.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-1.png" alt="" />
                            </div>
                            <div className="partnership_slider_item">
                                <img src="images/clients-2.png" alt="" />
                            </div>
                        </div> */}
                        <a href="/partners-corner">Partners Corner</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import Layout from "../../components/common/layout/layout";
import MemberEditComponent from "../../components/member-edit/member-edit/memberEdit";

export default function MemberEdit() {
    return (
        <Layout showBanner={false}>
            <MemberEditComponent />
        </Layout>
    );
}

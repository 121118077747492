import CTOAndExecutiveForum from "../cto-and-executive-forum/ctoAndExecutiveForum";
import EdgeProgram from "../edge-program/edgeProgram";


export default function BecomeAMemberComponent() {
    return (
        <>
        <div className="benifits_part">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <h2>What Benefits Do You Receive?</h2>
                        </div>
                    </div>
                    <div className="row margin xs-hidden">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item active">
                                <img src="images/Connecting-With-the-top-CTOs.png" alt="" />
                                <h4>
                                Connecting With the top CTOs
                                </h4>
                                <p>
                                It’s your network that may be a factor changes your way of being ambitious. Connect to the experienced CTOs, explore the experiences and journey. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item">
                                <img src="images/Access-to-the-innovation-centre.png" alt="" />
                                <h4>
                                    Access to the innovation centre
                                </h4>
                                <p>
                                Innovation finds the keys to futuristic solution. CTO forum let you access to the innovation center; where Ideas grow, greatness create. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item">
                                <img src="images/Resource-and-Insight-Privilege.png" alt="" />
                                <h4>
                                Resource and Insight Privilege
                                </h4>
                                <p>
                                The way of innovating, resources you need can be found here with the data and insights-what makes your journey easy and understandable? 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="benifits_part_slider margin xl-hidden">
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        <div class="ama_event_part">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 ama">
                        <h2>Be updated with latest industry insights</h2>
                        <hr />
                        <p>Up to date information can deliver resources that’ll be needed anytime! Insights can provide you a good look into feasibility! </p>
                        <div class="img_item d-flex">
                            <div class="all_img_item">
                                <div class="span_border"></div>
                                <img src="images/ama_1.png" class="img-fluid" alt="" />
                            </div>
                            <div class="all_img_item">
                                <div class="span_border"></div>
                                <img src="images/ama_2.png" class="img-fluid" alt="" />
                            </div>
                            <div class="all_img_item xs-hidden">
                                <div class="span_border"></div>
                                <img src="images/ama_3.png" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <a href="#">Visit Now</a>
                        <span>Explore our previous AMAs</span>
                    </div>
                    <div class="col-md-6 event text-center">
                        <h2>Be a part of leading industry events </h2>
                        <hr />
                        <p>Tech goes quick. Take participation of maximum events and don’t miss anything. </p>
                        <div class="img_item d-flex">
                            <div class="all_img_item">
                                <div class="span_border"></div>
                                <img src="images/ama_4.png" class="img-fluid" alt="" />
                            </div>
                            <div class="all_img_item">
                                <div class="span_border"></div>
                                <img src="images/ama_5.png" class="img-fluid" alt="" />
                            </div>
                            <div class="all_img_item xs-hidden">
                                <div class="span_border"></div>
                                <img src="images/ama_4.png" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <a href="#">Visit Now</a>
                        <span>Visit our prevoius events</span>
                    </div>
                </div>
            </div>
        </div>


        <section id="about_Cto">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-lg-4 col-sm-12 xl-hidden">
                            <div className="about_cto_img">
                                <img src="images/mike.png" alt="images" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="about_cto_text">
                                <h3>About CTO Forum</h3>
                                <p>The CTO Forum Bangladesh is an independent, non-profit, non-political organization dedicated to fostering a trusted, open and creative environment where the brightest minds of our time convene to address the banking industry's important issues. The CTO Forum members collaborate and develop solutions that harness the extraordinary potential of technology. </p>
                                <p>The CTO Forum aims to provide a powerful platform where technology leaders and visionaries from around the world can explore topics of interest and share knowledge in an unbiased, trusted and intellectually charged environment. Members respect and support the mission of the organization. The support by members is vital to the continued success, growth and relevance of the Forum, as we seek to invigorate innovation and technological advancements in the modern world.</p>
                                <a href="/about">Learn More</a>
                            </div>
                        </div>
                        <div className="col-lg-4 xs-hidden">
                            <div className="about_cto_img">
                                <img src="images/mike.png" alt="images" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <div className="section_height"></div>

            <section id="about_Cto" className="Become_an_user">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="about_cto_img">
                                <img src="images/Become an user to get greater access (2).png" alt="images" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="about_cto_text">
                                <h3>Become an user to get greater access</h3>
                                <p>CTO forum is Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
                                    facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore evt dolore magna aliqua. </p>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas cumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                                <a href="/signup">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_height"></div>
            
        </>
    );
}

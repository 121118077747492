import React from 'react';
import Layout from '../../components/common/layout/layout';
import InnovationHackathonComponent2021 from '../../components/innovation-hackathon/InnovationHackathonComponent2021';

const InnovationHackathon2021 = () => {
    return (
        <Layout showBanner={false}>
            <InnovationHackathonComponent2021 />
        </Layout>
    );
};

export default InnovationHackathon2021;
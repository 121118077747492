

import Layout from "../../components/common/layout/layout";
import EventsComponent from "../../components/events/events/events";

const CommingSoon = () => {
    return (
        <Layout showBanner={false}>
            <div className="commingSoon_part">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Yet To Reveal!</h2>
                            <p>Exciting and Effectual things are on making. Subsequently of completion, will come on live! </p>
                            <a href="#">
                                Go to Home
                            </a>
                        </div>
                    </div>
                </div>
            </div>  
        </Layout>
    );
};

export default CommingSoon;
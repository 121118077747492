import React from 'react';
import Layout from '../../components/common/layout/layout';
import InnovationHackathonComponent2022 from '../../components/innovation-hackathon/InnovationHackathonComponent2022';

const InnovationHackathon2022 = () => {
    return (
        <Layout showBanner={false}>
            <InnovationHackathonComponent2022 />
        </Layout>
    );
};

export default InnovationHackathon2022;
import React from "react";
import LogInComponent from "../../components/log-in/log-in";
import Layout from "../../components/common/layout/layout";

export default function LogIn() {
    return (
        <Layout showBanner={false}>
            <LogInComponent />
        </Layout>
    );
}

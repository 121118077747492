import React from "react";

export default function Footer() {
    return (
        <React.Fragment>
            <footer id="footer">
                <div className="container-fluid">
                        <div className="footer_logo">
                                <div className="logo">
                                    <img src="images/footer_logo.png" alt="image" className="img-fluid"/>
                                </div>
                            </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <div className="footer_logo">
                                {/* <div className="logo">
                                    <img src="images/footer_logo.png" alt="image" className="img-fluid"/>
                                </div> */}
                                <ul className="footer_logo_fast">
                                    <li>
                                        <a href="">Office Address: </a> 4-B, Level-3, Concept Tower, 68-69, Green Road Dhanmondi, Dhaka-1205,Bangladesh.
                                    </li>
                                    <li>
                                        <a href="">Phone: </a>+88 02 9641156
                                    </li>
                                    <li>
                                        <a href="">Email Address: </a> 
                                        <span>
                                            contact@ctoforumbd.org
                                            ctoforumbd@gmail.com
                                        </span>
                                    </li>
                                </ul>
                                <ul className="footer_logo_last">
                                    <li>
                                        <a href="/comming-soon">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="/comming-soon">Terms of Service</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6">
                            <div className="footer_item">
                                <h4>The Forum</h4>
                                <ul>
                                    <li>
                                        <a href="/about">About</a>
                                    </li>
                                    <li>
                                        <a href="/executive-committee">Executive Panel</a>
                                    </li>
                                    <li>
                                        <a href="/inquiry">Inquiry</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6">
                            <div className="footer_item">
                                <h4>Activity</h4>
                                <ul>
                                    <li>
                                        <a href="/comming-soon">Events</a>
                                    </li>
                                    <li>
                                        <a href="/comming-soon">Activity Timeline</a>
                                    </li>
                                    <li>
                                        <a href="/magazine">CTO Magazine</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6">
                            <div className="footer_item">
                                <h4>Membership</h4>
                                <ul>
                                    <li>
                                        <a href="/member-list">Member Directory</a>
                                    </li>
                                    <li>
                                        <a href="/become-a-member">Membership Features</a>
                                    </li>
                                    <li>
                                        <a href="/signup">Registration</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6">
                            <div className="footer_item">
                                <h4>Membership</h4>
                                <ul>
                                    <li>
                                        <a href="/comming-soon">FAQ</a>
                                    </li>
                                    <li>
                                        <a href="/comming-soon">News</a>
                                    </li>
                                    <li>
                                        <a href="/comming-soon">Resources Corner</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <footer id="copy_right">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="Copy_right_logo">
                                <div className="logo">
                                    <img src="images/doodle_logo.png" alt="image" />
                                </div>
                                <div className="logo_text">
                                    <p>
                                        Designed and developed by <br /> <span>Doodle Inc.</span> -
                                        Empowering Future
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12 m-auto">
                        <div className="copy_rg_p xl-hidden">
                                <p>2020 Copyright. CTO Forum. All Rights Reserved.</p>
                            </div>
                            <div className="social_icon">
                                <a target="_blank" href="https://www.facebook.com/bangladeshctoforum" rel="noreferrer">
                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.71875 9L9.15625 6.125H6.375V4.25C6.375 3.4375 6.75 2.6875 8 2.6875H9.28125V0.21875C9.28125 0.21875 8.125 0 7.03125 0C4.75 0 3.25 1.40625 3.25 3.90625V6.125H0.6875V9H3.25V16H6.375V9H8.71875Z"
                                        fill="black"></path>
                                </svg>
                                </a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCJ-KHx0LUWWgqz-xujUOFmA " rel="noreferrer">
                                <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.9789 6.11088C18.7628 5.18713 18.1295 4.45895 17.3264 4.21021C15.8591 3.74854 9.99001 3.74854 9.99001 3.74854C9.99001 3.74854 4.12113 3.74854 2.65388 4.19271C1.86619 4.44118 1.21742 5.18726 1.00136 6.11088C0.615234 7.79808 0.615234 11.2971 0.615234 11.2971C0.615234 11.2971 0.615234 14.8138 1.00136 16.4834C1.21765 17.407 1.85074 18.1352 2.654 18.3839C4.13658 18.8457 9.99023 18.8457 9.99023 18.8457C9.99023 18.8457 15.8591 18.8457 17.3264 18.4015C18.1296 18.1529 18.7628 17.4247 18.9791 16.5011C19.3651 14.8138 19.3651 11.3149 19.3651 11.3149C19.3651 11.3149 19.3806 7.79808 18.9789 6.11088V6.11088ZM8.12141 14.5297V8.06459L13.0019 11.2971L8.12141 14.5297Z" fill="black"/>
                                </svg>
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/company/79689034/admin/" rel="noreferrer">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.125 14V4.65625H0.21875V14H3.125ZM1.65625 3.40625C2.59375 3.40625 3.34375 2.625 3.34375 1.6875C3.34375 0.78125 2.59375 0.03125 1.65625 0.03125C0.75 0.03125 0 0.78125 0 1.6875C0 2.625 0.75 3.40625 1.65625 3.40625ZM13.9688 14H14V8.875C14 6.375 13.4375 4.4375 10.5 4.4375C9.09375 4.4375 8.15625 5.21875 7.75 5.9375H7.71875V4.65625H4.9375V14H7.84375V9.375C7.84375 8.15625 8.0625 7 9.5625 7C11.0625 7 11.0938 8.375 11.0938 9.46875V14H13.9688Z"
                                        fill="black"></path>
                                </svg>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="copy_rg_p xs-hidden">
                                <p>2020 Copyright. CTO Forum. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
}

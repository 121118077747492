/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      fullName
      email
      mobile
      password
      gender
      fathersName
      mothersName
      dob
      maritalStatus
      pob
      permanentAddress
      homePhone
      cellPhone
      workPhone
      bloodGroup
      mailingAddress
      personalEmail
      disciplinaryAction
      file1
      file2
      professionID
      status
      profilePhotoUrl
      coverPhotoUrl
      officePhone
      proffesionalEmail
      mailingArea
      mailingZip
      mailingCityID
      mailingCountryID
      permanentArea
      permanentZip
      permanentCityID
      permanentCountryID
      IndustryID
      IsDischarged
      dischargedReason
      facebook
      linkedin
      tweeter
      website
      userType
      intro
      positionID
      skills
      expertise
      certification
      awards
      memberType
      payment {
        nextToken
      }
      committee {
        nextToken
      }
      educationInfo {
        nextToken
      }
      jobInfo {
        nextToken
      }
      profession {
        id
        professionName
        isActive
        isRestricted
        createdAt
        updatedAt
      }
      permanentCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      permanentCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      mailingCountry {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      mailingCity {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        email
        mobile
        password
        gender
        fathersName
        mothersName
        dob
        maritalStatus
        pob
        permanentAddress
        homePhone
        cellPhone
        workPhone
        bloodGroup
        mailingAddress
        personalEmail
        disciplinaryAction
        file1
        file2
        professionID
        status
        profilePhotoUrl
        coverPhotoUrl
        officePhone
        proffesionalEmail
        mailingArea
        mailingZip
        mailingCityID
        mailingCountryID
        permanentArea
        permanentZip
        permanentCityID
        permanentCountryID
        IndustryID
        IsDischarged
        dischargedReason
        facebook
        linkedin
        tweeter
        website
        userType
        intro
        positionID
        skills
        expertise
        certification
        awards
        memberType
        committee {
          items {
            committeeID
            committyType
            createdAt
            id
            isActive
            memberID
            updatedAt
          }
        }
        jobInfo {
          items {
            memberID
            organization
            designation
            endYear
            id
            startYear
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberCommittee = /* GraphQL */ `
  query GetMemberCommittee($id: ID!) {
    getMemberCommittee(id: $id) {
      id
      committeeID
      memberID
      committyType
      isActive
      member {
        nextToken
      }
      common {
        id
        ddlType
        name
        isDeleted
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMemberCommittees = /* GraphQL */ `
  query ListMemberCommittees(
    $filter: ModelMemberCommitteeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberCommittees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        committeeID
        memberID
        committyType
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      memberID
      amount
      paymentDate
      memberFeesId
      member {
        nextToken
      }
      fees {
        nextToken
      }
      memberSettings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        amount
        paymentDate
        memberFeesId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEducation = /* GraphQL */ `
  query GetEducation($id: ID!) {
    getEducation(id: $id) {
      id
      memberID
      qualification
      institution
      board
      passingYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEducations = /* GraphQL */ `
  query ListEducations(
    $filter: ModelEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        qualification
        institution
        board
        passingYear
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobInfo = /* GraphQL */ `
  query GetJobInfo($id: ID!) {
    getJobInfo(id: $id) {
      id
      memberID
      designation
      organization
      startYear
      endYear
      member {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobInfos = /* GraphQL */ `
  query ListJobInfos(
    $filter: ModelJobInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        memberID
        designation
        organization
        startYear
        endYear
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfession = /* GraphQL */ `
  query GetProfession($id: ID!) {
    getProfession(id: $id) {
      id
      professionName
      isActive
      isRestricted
      createdAt
      updatedAt
    }
  }
`;
export const listProfessions = /* GraphQL */ `
  query ListProfessions(
    $filter: ModelProfessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        professionName
        isActive
        isRestricted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMemberFees = /* GraphQL */ `
  query GetMemberFees($id: ID!) {
    getMemberFees(id: $id) {
      id
      amount
      isActive
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const listMemberFeess = /* GraphQL */ `
  query ListMemberFeess(
    $filter: ModelMemberFeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberFeess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        isActive
        paymentType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBecomeAPartner = /* GraphQL */ `
  query GetBecomeAPartner($id: ID!) {
    getBecomeAPartner(id: $id) {
      id
      companyName
      webAddress
      companyEmail
      contactPersonName
      designation
      personalEmail
      mobile
      country
      purpose
      message
      profession
      purposeType
      createdAt
      updatedAt
    }
  }
`;
export const listBecomeAPartners = /* GraphQL */ `
  query ListBecomeAPartners(
    $filter: ModelBecomeAPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBecomeAPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        webAddress
        companyEmail
        contactPersonName
        designation
        personalEmail
        mobile
        country
        purpose
        message
        profession
        purposeType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMembershipSettings = /* GraphQL */ `
  query GetMembershipSettings($id: ID!) {
    getMembershipSettings(id: $id) {
      id
      paymentID
      memberType
      JoiningFee
      YearlyFee
      description
      benifit
      requirement
      is_active
      createdAt
      updatedAt
    }
  }
`;
export const listMembershipSettingss = /* GraphQL */ `
  query ListMembershipSettingss(
    $filter: ModelMembershipSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipSettingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentID
        memberType
        JoiningFee
        YearlyFee
        description
        benifit
        requirement
        is_active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMagazine = /* GraphQL */ `
  query GetMagazine($id: ID!) {
    getMagazine(id: $id) {
      id
      issue_no
      issue_name
      image_url
      description
      writers
      featuredArticle
      publicationDate
      documentUrl
      createdAt
      updatedAt
    }
  }
`;
export const listMagazines = /* GraphQL */ `
  query ListMagazines(
    $filter: ModelMagazineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMagazines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        issue_no
        issue_name
        image_url
        description
        writers
        featuredArticle
        publicationDate
        documentUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommonDdl = /* GraphQL */ `
  query GetCommonDdl($id: ID!) {
    getCommonDDL(id: $id) {
      id
      ddlType
      name
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listCommonDdLs = /* GraphQL */ `
  query ListCommonDdLs(
    $filter: ModelCommonDDLFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommonDDLs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ddlType
        name
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventName
      description
      address
      imageUrl
      fromDate
      toDate
      isComplete
      guest {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        description
        address
        imageUrl
        fromDate
        toDate
        isComplete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGuest = /* GraphQL */ `
  query GetGuest($id: ID!) {
    getGuest(id: $id) {
      id
      eventID
      guestName
      designation
      company
      imageUrl
      guestStatus
      createdAt
      updatedAt
    }
  }
`;
export const listGuests = /* GraphQL */ `
  query ListGuests(
    $filter: ModelGuestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        guestName
        designation
        company
        imageUrl
        guestStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      preID
      countryName
      countryPhoneCode
      countryStatus
      createdAt
      updatedAt
    }
  }
`;
export const listCountrys = /* GraphQL */ `
  query ListCountrys(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountrys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        preID
        countryName
        countryPhoneCode
        countryStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      countryID
      preCountryID
      preStateID
      stateName
      stateStatus
      createdAt
      updatedAt
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countryID
        preCountryID
        preStateID
        stateName
        stateStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export async function committeeName(filter)
{
    console.log(filter, '-----------filter')
    filter.items.map(async(item, index)=>{
        if(item.memberType == "LifeFellow")
        {
            filter.items[index].member_type = "Life Fellow";
        }
        if(item.memberType == "Fellow")
        {
            filter.items[index].member_type = "Fellow";
        }
        if(item.memberType == "Member")
        {
            filter.items[index].member_type = "Member";
        }
        if(item.memberType == "Student")
        {
            filter.items[index].member_type = "Student";
        }
        if(item.memberType == "HonoraryMember")
        {
            filter.items[index].member_type = "Honorary Member";
        }
        if(item.memberType == "AssociateMember")
        {
            filter.items[index].member_type = "Associate Member";
        }
    })

    return filter;


}
export async function memberTypeFormat(filter)
{
    
    filter.map(async(item, index)=>{
        if(item.committee?.items.length>0)
        {
            if(item.committee.items[0].committyType == "President")
            {
                filter[index].committyTypeName = 'President';  
            }
            if(item.committee.items[0].committyType == "VisePresident")
            {
                filter[index].committyTypeName = 'Vice President';  
            }
            if(item.committee.items[0].committyType == "ViseChairman")
            {
                filter[index].committyTypeName = 'Vice Chairman';  
            }
            if(item.committee.items[0].committyType == "SecretaryGeneral")
            {
                filter[index].committyTypeName = 'Secretary General';  
            }
            if(item.committee.items[0].committyType == "JointSecretary")
            {
                filter[index].committyTypeName = 'Joint Secretary';  
            }
            if(item.committee.items[0].committyType == "Treasurer")
            {
                filter[index].committyTypeName = 'Treasurer';  
            }
            if(item.committee.items[0].committyType == "ECMember")
            {
                filter[index].committyTypeName = 'EC Member';  
            }

            
        }else
        {
            filter[index] = '';  
        }
    })

    return filter;


}

import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import { deleteEducation } from "../../../graphql/mutations";
import "./educationFieldsComponent.css";

export default function EducationFieldsComponent({
    index,
    educationFieldData,
    setEducationFieldData,
}) {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(educationFieldData[index]);
    }, [educationFieldData]);

    const updateField = (value, field) => {
        let d = data;
        d[field] = value;
        setData({ ...d });
        let dt = educationFieldData;
        dt[index] = d;
        setEducationFieldData([...dt]);
    };

    const deleteEdu = async () => {
        let dt = educationFieldData;
        let del = dt.splice(index, 1)[0];
        setEducationFieldData([...dt]);

        let deletedData = await API.graphql({
            query: deleteEducation,
            variables: {
                input: {
                    id: del.id,
                },
            },
        });

        // console.log("deleted education:", deletedData);
    };

    return (
        <React.Fragment>
            <div class="form-row mt-4">
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Your Qualifications <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        value={data.qualification}
                        onChange={(e) => updateField(e.target.value, "qualification")}
                        type="text"
                        placeholder="Enter your qualifications"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Institute <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.institution}
                        onChange={(e) => updateField(e.target.value, "institution")}
                        placeholder="Enter your Institute"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Board / University <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.board}
                        onChange={(e) => updateField(e.target.value, "board")}
                        placeholder="Enter your Board / University"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Year of Passing<span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.passingYear}
                        onChange={(e) => updateField(e.target.value, "passingYear")}
                        placeholder="Enter your Passing year"
                    />
                </div>
                <div class="icon">
                    <i class="" onClick={deleteEdu}>
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.7344 2.375H12.4688V1.78125C12.4688 0.79748 11.6713 0 10.6875 0H8.3125C7.32873 0 6.53125 0.79748 6.53125 1.78125V2.375H3.26562C2.44584 2.375 1.78125 3.03959 1.78125 3.85938V5.04688C1.78125 5.37481 2.04706 5.64062 2.375 5.64062H16.625C16.9529 5.64062 17.2188 5.37481 17.2188 5.04688V3.85938C17.2188 3.03959 16.5542 2.375 15.7344 2.375ZM7.71875 1.78125C7.71875 1.45395 7.9852 1.1875 8.3125 1.1875H10.6875C11.0148 1.1875 11.2812 1.45395 11.2812 1.78125V2.375H7.71875V1.78125Z" fill="#AAAAAA"/>
                        <path d="M2.9082 6.82812C2.80225 6.82812 2.71782 6.91667 2.72287 7.0225L3.21271 17.3034C3.25799 18.2548 4.03951 19 4.99174 19H14.0086C14.9608 19 15.7423 18.2548 15.7876 17.3034L16.2774 7.0225C16.2825 6.91667 16.1981 6.82812 16.0921 6.82812H2.9082ZM11.8752 8.3125C11.8752 7.98445 12.1409 7.71875 12.4689 7.71875C12.797 7.71875 13.0627 7.98445 13.0627 8.3125V16.0312C13.0627 16.3593 12.797 16.625 12.4689 16.625C12.1409 16.625 11.8752 16.3593 11.8752 16.0312V8.3125ZM8.90641 8.3125C8.90641 7.98445 9.17211 7.71875 9.50016 7.71875C9.8282 7.71875 10.0939 7.98445 10.0939 8.3125V16.0312C10.0939 16.3593 9.8282 16.625 9.50016 16.625C9.17211 16.625 8.90641 16.3593 8.90641 16.0312V8.3125ZM5.93766 8.3125C5.93766 7.98445 6.20336 7.71875 6.53141 7.71875C6.85945 7.71875 7.12516 7.98445 7.12516 8.3125V16.0312C7.12516 16.3593 6.85945 16.625 6.53141 16.625C6.20336 16.625 5.93766 16.3593 5.93766 16.0312V8.3125Z" fill="#AAAAAA"/>
                    </svg>

                    </i>
                </div>
            </div>

            <div className="form-row mt-4"></div>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Auth, Hub, API, graphqlOperation, label } from "aws-amplify";
import { createMember } from "../../graphql/mutations";
import { listProfessions } from "../../graphql/queries";

export default function SignUpComponent() {
    // const [fullName, setFullName] = useState("");
    // const [email, setEmail] = useState("");
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    // const [mobile, setMobile] = useState("");
    // const [profession, setProfession] = useState(null);
    const [professionList, setProfessionList] = useState([
        { id: 0, professionName: "Select a profession" },
    ]);
    const history = useHistory();

    useEffect(async () => {
        let professions = await API.graphql({
            query: listProfessions,
        });
        setProfessionList((d) => [...d, ...professions.data.listProfessions.items]);
    }, []);

    const signUp = async (values) => {
        let data = {
            fullName: values.fullName,
            email: values.email,
            password: values.password,
            mobile: values.mobile,
            professionID: values.profession,
            username: values.email,
            attributes: {
                email: values.email,
            },
        };

        try {
            const { user } = await Auth.signUp(data);
            delete data.password;
            // data.username = values.username;
            delete data.username;
            delete data.attributes;
            data.userType = "User";
            let userData = await API.graphql({
                query: createMember,
                variables: { input: data },
            });
            localStorage.setItem("emailToVerify", data.email);
            history.push("/verify-email");
            // history.push("/login");
            console.log("*********");
            console.log(userData);
            console.log("*********");
        } catch (e) {
            console.log("##############");
            console.log(e);
            if (e.code == "UsernameExistsException")
                formik.errors.email = "This email is already used";
            console.log("##############");
        }

        console.log(data);
    };

    const validation = (values) => {
        const errors = {};
        if (!values.fullName) errors.fullName = "Full name is required";
        if (!values.email) errors.email = "Email is required";
        if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
            errors.email = "Invalid email";
        if (!values.mobile) errors.mobile = "Mobile is required";
        if (!values.password) errors.password = "Password is required";
        if (values.password && values.password != values.passwordConfirm)
            errors.passwordConfirm = "Password didn't match";
        if (values.profession == 0) errors.profession = "Profession is required";
        if (!values.agree) errors.agree = "Please agree first";
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            fullName: "",
            email: "",
            mobile: "",
            profession: 0,
            password: "",
            passwordConfirm: "",
            agree: false,
        },
        validate: validation,
        validateOnBlur: true,
        onSubmit: signUp,
    });

    return (
        <div className="signup_page">
            <div class="signup_part">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 col-sm-12">
                            <div class="col-md-6 col-sm-12 signup_text xl-hidden">
                                <h2>User Sign Up </h2>
                                <p className="xs-hidden">
                                The race of reaching excellence will begin shortly. Connect your team can solve any tech solutions up and make a greater impact. Register and press the accelerator! 
                                </p>
                                <div class="dont_have xl-hidden">
                                    <h3>Already have an account?<Link to="/login">Sign In</Link></h3>
                                </div>
                            </div>
                            <div class="signup_form">
                                <h2>Login to have greater access</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label htmlFor="fullname">Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fullName"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter your full name"
                                            />
                                            {formik.touched.fullName && formik.errors.fullName && (
                                                <label className="text-danger" htmlFor="error">
                                                    {formik.errors.fullName}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 form-group">
                                            <label for="email">Email</label>

                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter your email"
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <label className="text-danger" htmlFor="error">
                                                    {formik.errors.email}
                                                </label>
                                            )}
                                        </div>
                                        <div class="col-md-6 col-sm-12 form-group">
                                            <label for="mobile">Mobile</label>

                                            <input
                                                type="tel"
                                                className="form-control"
                                                id="mobile"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Mobile"
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <label className="text-danger" htmlFor="error">
                                                    {formik.errors.mobile}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="profession">Profession</label>

                                        <select
                                            class="form-control form-control-sm"
                                            id="profession"
                                            onChange={formik.handleChange}
                                        >
                                            {/* need to change 'professtionName' */}
                                            {professionList &&
                                                professionList.map((pl) => (
                                                    <option value={pl.id} key={pl.id}>
                                                        {pl.professionName}
                                                    </option>
                                                ))}
                                        </select>
                                        {formik.touched.profession && formik.errors.profession && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.profession}
                                            </label>
                                        )}
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-12 form-group">
                                            <label for="password">Password</label>

                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                id="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter your password"
                                            />
                                            {formik.touched.password && formik.errors.password && (
                                                <label className="text-danger" htmlFor="error">
                                                    {formik.errors.password}
                                                </label>
                                            )}
                                        </div>
                                        <div class="col-md-6 col-sm-12 form-group">
                                            <label for="confirm_password">Confirm Password</label>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="passwordConfirm"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="Enter your password"
                                            />
                                            {formik.touched.passwordConfirm &&
                                                formik.errors.passwordConfirm && (
                                                    <label className="text-danger" htmlFor="error">
                                                        {formik.errors.passwordConfirm}
                                                    </label>
                                                )}
                                        </div>
                                    </div>
                                    <div class="form-group form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            id="agree"
                                        />
                                        <label
                                            className="form-check-label check_text"
                                            htmlFor="defaultCheck1"
                                        >
                                            <span>I agree all statement in </span>Terms of condition
                                        </label>
                                        {formik.touched.agree && formik.errors.agree && (
                                            <label className="text-danger" htmlFor="error">
                                                {formik.errors.agree}
                                            </label>
                                        )}
                                    </div>
                                    <button type="submit" onClick={formik.handleSubmit} class="btn">
                                        Sign Up
                                    </button>
                                    <span class="span">Or Sign Up With</span>
                                    <div class="social_img">
                                        <img src="images/google.png" alt="" />
                                        <img src="images/linkedin.png" alt="" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 signup_text xs-hidden">
                            <h2>User Sign Up </h2>
                            <p>
                            The race of reaching excellence will begin shortly. Connect your team can solve any tech solutions up and make a greater impact. Register and press the accelerator! 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dont_have_part xs-hidden">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 offset-6">
                            <div class="dont_have">
                                <h2>Already have an account?</h2>
                                <p>
                                    <Link to="/login">Sign In</Link> <span>from here</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import AboutComponent from "../../components/about/about/about";
import Layout from "../../components/common/layout/layout";

export default function About() {
    return (
        <Layout showBanner={false}>
            <AboutComponent />
        </Layout>
    );
}

import React from "react";
import Layout from "../../components/common/layout/layout";
import SignUpComponent from "../../components/sign-up/sign-up";
import "./SignUp.css";

export default function SignUp() {
    return (
        <Layout showBanner={false}>
            <SignUpComponent />
        </Layout>
    );
}

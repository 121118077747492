import React from 'react';
import Layout from '../../components/common/layout/layout';
import VideoGalleryComponent from '../../components/gallery/VideoGalleryComponent';

const VideoGallery = () => {
    return (
        <Layout showBanner={false}>
           <VideoGalleryComponent />
       </Layout>
    );
};

export default VideoGallery;
import { API } from "aws-amplify";
export async function professionList(search, filter){
   let query = `query MyQuery {
    listProfessions(filter: {isActive: {eq: true}}) {
      items {
        id
        professionName
        isActive
        isRestricted
      }
    }
  }
  `;
  let responsive = await API.graphql({query: query})

  console.log(responsive, '----------------responsive')
  return responsive.data.listProfessions.items;
}
import Collaborate from "./Collaborate"
import PartnerMessege from "./partnerMessege"

export default function PartnersCornerComponent() {
return (
<>
    <div className="banner_bg Partner_Corner col-xl-12 col-md-12 col-sm-12 col-xs-12" >
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-7">
                    <div className="">
                        <h2>Partner Corner’s</h2>
                        <p>Global and Local brands are finding ways to the door of CTO forum as the tech needs becoming an important part of growing bigger in business world. CTO forum helps to create an efficient community with technological enforcement and resources for companies and brands. 
                        </p>
                        <p>It’s a journey to innovation! A way to modern destination! </p>
                    </div>
                    <div className="btn">
                        <a id="Become_Partner" href="">Become a Partner </a>
                        <a href="" className="bdr" data-toggle="modal" data-target="#Modal_Collaborate">Collaborate with
                            Us</a>
                    </div>
                </div>

                <div className="col-md-5">
                    <div className="img">
                        <img src="images/cornar_banner.png" className="img-fluid" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="section_height"></div>
    <section id="Our_Partners">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 m-auto">
                    <div className="header_part">
                        <h2>Our Partners</h2>
                        <p>Brands and industries always fall for immensity. We are immense in being partner in tech matter that helps. Meet our partners! </p>
                    </div>
                </div>
            </div>
            <div className="Our_Partners_item">
                <div className="row">

                    {/* <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-1.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-2.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-3.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-4.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-5.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-6.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-7.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content">
                            <div className="Our_Partners_img">
                                <img src="images/partner-1.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content last_child">
                            <div className="Our_Partners_img">
                                <img src="images/partner-2.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content last_child">
                            <div className="Our_Partners_img">
                                <img src="images/partner-3.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content last_child">
                            <div className="Our_Partners_img">
                                <img src="images/partner-4.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="Our_Partners_content last_child">
                            <div className="Our_Partners_img">
                                <img src="images/partner-5.png" alt="image" className="img-fluid" />
                            </div>
                            <h3>Value-Top</h3>
                            <p>Technology Partner</p>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    </section>
    <div className="proud_member Our_Collaborations">
        <div className="">
            <div className="row mr-0 ml-0">
                <div className="col-md-12">
                    <h2>Our Collaborations </h2>
                    <p>Together we win! Together we find ways! Collaborating with big fishes and small tech savvies made us effectual and better. </p>
                    {/* <div className="proud_member_slider">

                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-1.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Instagram</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-2.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Google</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-3.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Apple</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-4.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Instagram</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-1.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Instagram</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-2.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Google</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-3.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Apple</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                        <div className="proud_member_slider_item">
                            <div className="slider_img">
                                <div className="p_display_flex">
                                    <img src="images/p_collection-4.png" alt="image" className="img-fluid" />
                                    <div className="title">
                                        <h2>Instagram</h2>
                                        <p>Google Corporation</p>
                                    </div>
                                </div>
                                <p>Euismod fringilla nisi nec faucibus magna donec morbi risus massa. Elementum, diam
                                    vel eget odio a placerat at laoreet. Habitant sem tincidunt sagittis, consectetur
                                    pharetra. Pellentesque eu</p>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </div>
    </div>
    
    <section id="Become_a_Partner" className="banner_bg">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-8">
                    <div className="banner_header">
                        <h2>Become a Partner</h2>
                        <p>There’s nothing wrong being part of the best. What do you think? <br />
Let’s come and join! 
</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="form_part">
                        <PartnerMessege />
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="section_height become_a_partners"></div>

    <div className="about modal fade" id="Modal_Collaborate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="17" fill="#3B54DB" />
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z"
                                        fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="12" height="12" fill="white" transform="translate(11 11)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-body">
                    <section id="Become_a_Partner" className="banner_bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="banner_header">
                                        <h2>Collaborate with Us</h2>
                                        <p>Risus non at tempus, eget dolor lorem viverra justo. Nunc tellus bibendum
                                            ridiculus tortor vel nunc mattis.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form_part">
                                        <Collaborate />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    </>
);
}
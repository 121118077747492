import React from 'react';
import Layout from '../../../components/common/layout/layout';
import OtherEventsComponent from '../../../components/events/events/others-events/OtherEventsComponent'
const OtherEvents = () => {
    return (
        <Layout showBanner={false}>
             <OtherEventsComponent />
        </Layout>
    );
};

export default OtherEvents;
import React from 'react';
import Gallery from 'react-photo-gallery'
import { photos } from "./photosInovation";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const GalleryMoreDetailsInovationComponent = () => {
    return (
        <div className="container-fluid">
            <div className="Galery_part_Rasel">
                <div className="gallery-page">
                    <div className="allbum">
                        <div className="text">
                            <h2>InnovationHackathon</h2>
                            {/* <div className="header-allbum">
                                <a href="/gallery-more-details-inovation">More details</a>
                            </div> */}
                        </div>

                        <Tabs>
                            <TabList>
                                <Tab>InnovationHackathon 2020</Tab>
                            </TabList>
                            <TabPanel>
                                <Gallery photos={photos}/>
                            </TabPanel>
                        </Tabs>
                        
                        {/* <Gallery photos={photos}/> */}
                    </div>
                </div>
            </div>
        </div>  
    );
};

export default GalleryMoreDetailsInovationComponent;


import React from "react";

export default function Details(props) {
    return (
        <React.Fragment>
            <div class="tabs_item">
                <div class="tab-content" id="nav-tabContent">
                    {props.memberships &&
                        props.memberships.items.map((member, i) => {
                            let benifit = JSON.parse(member.benifit);
                            let requirement = JSON.parse(member.requirement);
                            return (
                                <div
                                    className={
                                        member.id === props.id
                                            ? "tab-pane fade show active"
                                            : "tab-pane fade show "
                                    }
                                    id={member.memberType}
                                    role="tabpanel"
                                    aria-labelledby="nav-Student_Member-tab"
                                >
                                    <h2>{member.memberType}</h2>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="Member_Rights_Benefits">
                                                <h4>Member Rights & Benefits</h4>
                                                <ul>
                                                    {benifit.map((benifit, i) => {
                                                        return (
                                                            <li>
                                                                <a href="/">{benifit.benifit} </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="Member_Rights_Benefits Requirements">
                                                <h4>Requirements</h4>

                                                <div class="Membership_Requirements_item">
                                                    {requirement.map((requirement, i) => {
                                                        // console.log(requirement, '------------requirement')
                                                        return (
                                                            <div class="Membership_Requirements_text">
                                                                {/* <h5>National ID</h5> */}
                                                                <p>
                                                                    {requirement.requirement}
                                                                </p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="admission_fess active">
                                                <div class="Admission_Fees_text">
                                                    <h3>Admission Fees</h3>
                                                    <p>{member.JoiningFee} BDT</p>
                                                </div>
                                                {member.YearlyFee>0?
                                                <div class="Admission_Fees_text bdr">
                                                    <p>{member.YearlyFee} BDT</p>
                                                    <h3>Annual Fees</h3>
                                                </div>
                                                :""}
                                                <a
                                                    href="/member-registration"
                                                    onClick={() =>
                                                        localStorage.setItem("membershipType", i)
                                                    }
                                                >
                                                    Apply Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </React.Fragment>
    );
}

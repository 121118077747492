import { API } from "aws-amplify";
export async function executiveCommittee(search, filter){
   let query = `query MyQuery {
    listMemberCommittees {
      items {
        memberID
        isActive
        id
        createdAt
        rank
        committyType
        committeeID
        updatedAt
        member {
          items {
            IndustryID
            IsDischarged
            awards
            bloodGroup
            cellPhone
            certification
            committee {
              items {
                committeeID
                committyType
                createdAt
                id
                isActive
                memberID
                updatedAt
              }
            }
            coverPhotoUrl
            createdAt
            dischargedReason
            disciplinaryAction
            dob
            email
            expertise
            facebook
            fathersName
            file1
            file2
            fullName
            gender
            homePhone
            id
            intro
            linkedin
            mailingAddress
            mailingArea
            mailingCityID
            mailingCountryID
            mailingZip
            maritalStatus
            mobile
            memberType
            mothersName
            officePhone
            permanentAddress
            permanentArea
            permanentCityID
            permanentCountryID
            personalEmail
            permanentZip
            userType
            tweeter
            status
            skills
            updatedAt
            website
            workPhone
            educationInfo {
              nextToken
            }
            jobInfo {
              items {
                organization
                designation
                id
              }
            }
            profilePhotoUrl
          }
        }
      }
    }
  }
  
   
  `;
  let responsive = await API.graphql({query: query})
  // console.log(responsive, '----------------responsive ex')
  let member = responsive.data.listMemberCommittees.items;
  member.sort(function(a, b) {
    var keyA = parseInt(a.rank),
      keyB = parseInt(b.rank);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });

  member.map(async(item, index)=>{
   
        if(item.committyType == "President")
        {
          member[index].committyType = 'President';  
        }
        if(item.committyType == "VisePresident")
        {
          member[index].committyType = 'Vice President';  
        }
        if(item.committyType == "ViseChairman")
        {
          member[index].committyType = 'Vice Chairman';  
        }
        if(item.committyType == "SecretaryGeneral")
        {
          member[index].committyType = 'Secretary General';  
        }
        if(item.committyType == "JointSecretary")
        {
          member[index].committyType = 'Joint Secretary';  
        }
        if(item.committyType == "Treasurer")
        {
          member[index].committyType = 'Treasurer';  
        }
        if(item.committyType == "ECMember")
        {
          member[index].committyType = 'EC Member';  
        }

        
        

        if(item.member.items[0].memberType == "LifeFellow")
        {
          member[index].member.items[0].memberType = "Life Fellow";
        }
        if(item.member.items[0].memberType == "Fellow")
        {
          member[index].member.items[0].memberType = "Fellow";
        }
        if(item.member.items[0].memberType == "Member")
        {
          member[index].member.items[0].memberType = "Member";
        }
        if(item.member.items[0].memberType == "Student")
        {
          member[index].member.items[0].memberType = "Student";
        }
        if(item.member.items[0].memberType == "HonoraryMember")
        {
          member[index].member.items[0].memberType = "Honorary Member";
        }
        if(item.member.items[0].memberType == "AssociateMember")
        {
          member[index].member.items[0].memberType = "Associate Member";
        }
  })
  // console.log(member, '---------response')
  return member;
}
import React,{ useState, useEffect} from 'react';
import { API, Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
const ResetPassword = (data) => {
    const [msgStatus, setMsgStatus] = useState('check-email');
    const validateForm = (values) => {
        const errors = {};

        if (!values.code) errors.code = "OTP is required";
        
        if (!values.password) errors.password = "Password is required";
        if (values.password && values.password != values.passwordConfirm)
            errors.passwordConfirm = "Password didn't match";

        return errors;
    };
    const submitForm = async (values) => {
        try {
             let response = await   Auth.forgotPasswordSubmit(data.email, values.code, values.password)
             alert('Now login Please')
             console.log(response, '---------response')
            
        }catch (e){
            console.log(e, '---------error')
            formik.errors.email = e.message
        }
        
      
    };
    const formik = useFormik({
        initialValues: {
            code: "",
            password: "",
            passwordConfirm: "",
            
        },
        validateOnBlur: true,
        validate: validateForm,
        onSubmit: submitForm,
    });
    return (
            <>
            <h2>Reset Password </h2>
            <form onSubmit={formik.handleSubmit}>
                {msgStatus == 'check-email'? 
               
                    <label className="text-success">
                        Please check your email 
                    </label>
                :""}
                <div className="form-group">
                    <input type="text"
                    name="code"
                    id="code"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    className="form-control" placeholder="Enter OTP" />
                    {formik.touched.code && formik.errors.code && (
                            <label className="text-danger">
                                {formik.errors.code}
                            </label>
                        )}
                </div>
                <div className="form-group">
                 <input
                        className="form-control"
                        type="password"
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter your password"
                    />
                    {formik.touched.password && formik.errors.password && (
                        <label className="text-danger" htmlFor="error">
                            {formik.errors.password}
                        </label>
                    )}
                </div>
                <div class=" form-group">
                    <input
                        type="password"
                        className="form-control"
                        id="passwordConfirm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter your password"
                    />
                    {formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm && (
                            <label className="text-danger" htmlFor="error">
                                {formik.errors.passwordConfirm}
                            </label>
                        )}
                </div>

                <button type="submit" onClick={formik.handleSubmit} class="btn">
                    Send
                </button>
            </form>
            </>
    );
};

export default ResetPassword;
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { updateMember } from "../../graphql/mutations";

export default function HeaderModal({ setModal, memberData, setMemberData }) {
    const [fullName, setFullName] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [website, setWebsite] = useState("");

    useEffect(() => {
        setFullName(memberData.fullName);
        setLinkedin(memberData.linkedin);
        setWebsite(memberData.website);
    }, []);

    const save = async () => {
        let update = await API.graphql({
            query: updateMember,
            variables: {
                input: {
                    id: memberData.id,
                    fullName: fullName,
                    linkedin: linkedin,
                    website: website,
                },
            },
        });

        setMemberData({ ...memberData, fullName, linkedin, website });
     setModal(false);
    };

    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Intro
                        </h5>
                        <button
                            type="button"
                            onClick={() => setModal(false)}
                            classname="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="17" r="17" fill="#3B54DB" />
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(11 11)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="about_part">
                            <div className="about_intro mb-4">
                                <h4>Full Name</h4>
                                <input
                                    class="form-control"
                                    placeholder="Tell something about you"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </div>
                            <div className="about_intro mb-4">
                                <h4>LinkedIn</h4>
                                <input
                                    class="form-control"
                                    value={linkedin}
                                    onChange={(e) => setLinkedin(e.target.value)}
                                />
                            </div>
                            <div className="about_intro mb-4">
                                <h4>Website</h4>
                                <input
                                    class="form-control"
                                    value={website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button"
                        data-dismiss="modal"
                        aria-label="Close" className="btn btn-primary" onClick={save}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import AMAComponent from "../../components/ama/ama/ama";
import Layout from "../../components/common/layout/layout";

export default function AMA() {
    return (
        <Layout showBanner={false}>
            <AMAComponent />
        </Layout>
    );
}

export const photos = [
  {
    src: "images/gellary/1.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/2.png",
    width: 1,
    height: 1
  },
  {
    src: "images/gellary/3.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/4.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/5.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/6.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/7.png",
    width: 3,
    height: 3
  },
  {
    src: "images/gellary/8.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/9.png",
    width: 4,
    height: 3
  },
  // {
  //   src: "images/gellary/10.png",
  //   width: 3,
  //   height: 3
  // },

  

  {
    src: "images/gellary/11.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/12.png",
    width: 1,
    height: 1
  },
  {
    src: "images/gellary/13.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/14.png",
    width: 4,
    height: 3
  },
  // {
  //   src: "images/gellary/15.png",
  //   width: 4,
  //   height: 3
  // },
  {
    src: "images/gellary/16.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/17.png",
    width: 3,
    height: 3
  },
  {
    src: "images/gellary/18.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/19.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/20.png",
    width: 3,
    height: 3
  },


  // {
  //   src: "images/gellary/10.png",
  //   width: 3,
  //   height: 3
  // },

  {
    src: "images/gellary/21.png",
    width: 4,
    height: 3
  },
  {
    src: "images/gellary/22.png",
    width: 1,
    height: 1
  },
  
  
];

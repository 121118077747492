import React, { useState, useEffect, useRef } from "react"; //{ useState, useEffect }
import Slider from "react-slick";
import { S3Image } from "aws-amplify-react";
import { Storage } from "aws-amplify";
import moment from "moment";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    responsive: [{
        breakpoint: 2561,
        settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1921,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1401,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 1025,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: 769,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
        },
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
        },
    },
]
};

function NextButton({ func }) {
    return (
        <button onClick={func}>
            <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.94327 0L0.0566406 1.88669L6.16995 8L0.0566406 14.1133L1.94327 16L9.94327 8L1.94327 0Z"
                    fill="#CCCCCC"
                />
            </svg>
        </button>
    );
}

function PrevButton({ func }) {
    return (
        <button onClick={func}>
            <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.05664 0L9.94327 1.88669L3.82995 8L9.94327 14.1133L8.05664 16L0.0566406 8L8.05664 0Z"
                    fill="#CCCCCC"
                />
            </svg>
        </button>
    );
}

export default function MagazineList(props) {
    const [documentUrl, setDocumentUrl] = useState();
    const sliderRef = useRef();

    useEffect(async () => {
        let docUrl = await Storage.get(props.magazine.documentUrl);
        console.log(docUrl, "--------------------docUrl");
        setDocumentUrl(docUrl);
    }, []);

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <React.Fragment>
            {props.rightStatus ? (
                <React.Fragment>
                    <div className="section_height"></div>
                    <div className="magazine_part">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-md-5 xl-hidden">
                                    <div className="right_img">
                                        {/* <img src="images/gal_5 1.png" className="img-fluid" alt="" /> */}
                                        {console.log(
                                            props.magazine.image_url,
                                            "props.magazine.image_url"
                                        )}
                                        {/* <S3Image imgKey="uploads/images/0.27349802101600096download (1).jpg" /> */}
                                        <S3Image
                                            imgKey={props.magazine.image_url}
                                            className="img-fluid"
                                        />
                                        <div className="address">
                                            <p>
                                                Issue{" "}
                                                <span>
                                                    {props.magazine.issue_no},{" "}
                                                    {moment(props.magazine.publicationDate).format(
                                                        "DD-MMMM-YYYY"
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <h2>{props.magazine.issue_name}</h2>
                                    <p>{props.magazine.description}</p>
                                    <div className="writer_part">
                                        <h3>Featured Writers</h3>
                                        <div className="prev">
                                            <PrevButton func={goToPrev} />
                                        </div>
                                        <div className="next">
                                            <NextButton func={goToNext} />
                                        </div>
                                        <div className="writer_slider">
                                            <Slider {...settings} ref={sliderRef}>
                                                {JSON.parse(props.magazine.writers)
                                                    ? JSON.parse(props.magazine.writers).map(
                                                          (item, index) => {
                                                              let itemData = JSON.parse(item);
                                                              return (
                                                                  <div className="writer_slider_item">
                                                                      {/* <img src="images/writer_1.png" className="img-fluid" alt="" /> */}
                                                                      {itemData.profilePhotoUrl ? (
                                                                          <S3Image
                                                                              imgKey={
                                                                                  itemData.profilePhotoUrl
                                                                              }
                                                                              className="img-fluid"
                                                                          />
                                                                      ) : (
                                                                          <div className="default-user-image">
                                                                              <svg
                                                                                  className="user_profile_img-2021"
                                                                                  version="1.0"
                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                  width="512.000000pt"
                                                                                  height="512.000000pt"
                                                                                  viewBox="0 0 512.000000 512.000000"
                                                                                  preserveAspectRatio="xMidYMid meet"
                                                                              >
                                                                                  <g
                                                                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                                                      fill="#eeeeee"
                                                                                      stroke="none"
                                                                                  >
                                                                                      <path
                                                                                          d="M2370 5105 c-287 -40 -550 -173 -761 -384 -397 -397 -506 -972 -283
                                                    -1491 129 -300 394 -565 694 -694 427 -184 901 -143 1285 111 133 88 290 245
                                                    378 378 299 453 299 1037 0 1490 -88 133 -245 290 -378 378 -278 183 -607 258
                                                    -935 212z"
                                                                                      ></path>
                                                                                      <path
                                                                                          d="M2125 2114 c-402 -37 -725 -160 -1037 -395 -112 -84 -298 -273 -384
                                                    -389 -241 -328 -365 -674 -390 -1090 -6 -106 -5 -119 15 -158 14 -28 35 -49
                                                    61 -62 39 -20 54 -20 2170 -20 2116 0 2131 0 2170 20 26 13 47 34 61 62 20 39
                                                    21 52 15 158 -31 514 -220 943 -576 1300 -301 304 -641 480 -1070 556 -78 14
                                                    -176 17 -545 19 -247 2 -468 1 -490 -1z"
                                                                                      ></path>
                                                                                  </g>
                                                                              </svg>
                                                                          </div>
                                                                      )}

                                                                      <h4>{itemData.fullName}</h4>
                                                                  </div>
                                                              );
                                                          }
                                                      )
                                                    : ""}

                                                <div className="writer_slider_item">
                                                    <span>
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                    <h4>And More</h4>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                    <div className="article_part">
                                        <h3>Fractured Articles</h3>
                                        <div className="row">
                                            {console.log(
                                                JSON.parse(
                                                    props.magazine.featuredArticle,
                                                    "props.magazine.featuredArticle"
                                                )
                                            )}
                                            {JSON.parse(props.magazine.featuredArticle).map(
                                                (item) => {
                                                    return (
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li>
                                                                    <img
                                                                        src="images/application 1.png"
                                                                        alt=""
                                                                    />
                                                                    <div className="article_text">
                                                                        {item.feature}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                        <div className="article_text_more">
                                                            And More...
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a
                                            href={documentUrl}
                                            target="_blank"
                                            Content-Disposition="attachment"
                                            Content-Type="application/pdf"
                                        >
                                            Read now
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-5 xs-hidden">
                                    <div className="right_img">
                                        {/* <img src="images/gal_5 1.png" className="img-fluid" alt="" /> */}
                                        {console.log(
                                            props.magazine.image_url,
                                            "props.magazine.image_url"
                                        )}
                                        {/* <S3Image imgKey="uploads/images/0.27349802101600096download (1).jpg" /> */}
                                        <S3Image
                                            imgKey={props.magazine.image_url}
                                            className="img-fluid"
                                        />
                                        <div className="address">
                                            <p>
                                                Issue{" "}
                                                <span>
                                                    {props.magazine.issue_no},{" "}
                                                    {moment(props.magazine.publicationDate).format(
                                                        "DD-MMMM-YYYY"
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section_height"></div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* <div className="section_height"></div> */}
                    <div className="magazine_part fst_img">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="right_img">
                                        <S3Image imgKey={props.magazine.image_url} />
                                        <div className="address left">
                                            <p>
                                                Issue{" "}
                                                <span>
                                                    {props.magazine.issue_no},{" "}
                                                    {moment(props.magazine.publicationDate).format(
                                                        "DD-MMMM-YYYY"
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-7">
                                    <h2>{props.magazine.issue_name}</h2>
                                    <p>{props.magazine.description}</p>
                                    <div className="writer_part">
                                        <h3>Featured Writers</h3>
                                        <div className="prev">
                                            <PrevButton func={goToPrev} />
                                        </div>
                                        <div className="next">
                                            <NextButton func={goToNext} />
                                        </div>
                                        <div className="writer_slider">
                                            <Slider {...settings} ref={sliderRef}>
                                                {JSON.parse(props.magazine.writers)
                                                    ? JSON.parse(props.magazine.writers).map(
                                                          (item, index) => {
                                                              let itemData = JSON.parse(item);
                                                              return (
                                                                  <div className="writer_slider_item">
                                                                      {/* <img src="images/writer_1.png" className="img-fluid" alt="" /> */}
                                                                      {itemData.profilePhotoUrl ? (
                                                                          <S3Image
                                                                              imgKey={
                                                                                  itemData.profilePhotoUrl
                                                                              }
                                                                              className="img-fluid"
                                                                          />
                                                                      ) : (
                                                                          <div className="default-user-image">
                                                                              <svg
                                                                                  className="user_profile_img-2021"
                                                                                  version="1.0"
                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                  width="512.000000pt"
                                                                                  height="512.000000pt"
                                                                                  viewBox="0 0 512.000000 512.000000"
                                                                                  preserveAspectRatio="xMidYMid meet"
                                                                              >
                                                                                  <g
                                                                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                                                      fill="#eeeeee"
                                                                                      stroke="none"
                                                                                  >
                                                                                      <path
                                                                                          d="M2370 5105 c-287 -40 -550 -173 -761 -384 -397 -397 -506 -972 -283
                                                    -1491 129 -300 394 -565 694 -694 427 -184 901 -143 1285 111 133 88 290 245
                                                    378 378 299 453 299 1037 0 1490 -88 133 -245 290 -378 378 -278 183 -607 258
                                                    -935 212z"
                                                                                      ></path>
                                                                                      <path
                                                                                          d="M2125 2114 c-402 -37 -725 -160 -1037 -395 -112 -84 -298 -273 -384
                                                    -389 -241 -328 -365 -674 -390 -1090 -6 -106 -5 -119 15 -158 14 -28 35 -49
                                                    61 -62 39 -20 54 -20 2170 -20 2116 0 2131 0 2170 20 26 13 47 34 61 62 20 39
                                                    21 52 15 158 -31 514 -220 943 -576 1300 -301 304 -641 480 -1070 556 -78 14
                                                    -176 17 -545 19 -247 2 -468 1 -490 -1z"
                                                                                      ></path>
                                                                                  </g>
                                                                              </svg>
                                                                          </div>
                                                                      )}

                                                                      <h4>{itemData.fullName}</h4>
                                                                  </div>
                                                              );
                                                          }
                                                      )
                                                    : ""}

                                                <div className="writer_slider_item">
                                                    <span>
                                                        <i className="fas fa-plus"></i>
                                                    </span>
                                                    <h4>And More</h4>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                    <div className="article_part">
                                        <h3>Fractured Articles</h3>
                                        <div className="row">
                                            {console.log(
                                                JSON.parse(
                                                    props.magazine.featuredArticle,
                                                    "props.magazine.featuredArticle"
                                                )
                                            )}
                                            {JSON.parse(props.magazine.featuredArticle).map(
                                                (item) => {
                                                    return (
                                                        <div className="col-md-6">
                                                            <ul>
                                                                <li>
                                                                    <img
                                                                        src="images/application 1.png"
                                                                        alt=""
                                                                    />
                                                                    <div className="article_text">
                                                                        {item.feature}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    );
                                                }
                                            )}
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                        <div className="article_text_more">
                                                            And More...
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a
                                            href={documentUrl}
                                            target="_blank"
                                            Content-Disposition="attachment"
                                            Content-Type="application/pdf"
                                        >
                                            Read now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="section_height"></div> */}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

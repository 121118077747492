import React from 'react';
import Layout from '../../components/common/layout/layout';
import ForgotPasswordComponent from '../../components/log-in/ForgotPasswordComponent'
const ForgotPassword = () => {
    return (
        <Layout showBanner={false}>
            <ForgotPasswordComponent />
        </Layout>
    );
};

export default ForgotPassword;
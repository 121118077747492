import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { createMemberFees, createPayment, updateMember } from "../../../graphql/mutations";

import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function NextButton({ func }) {
    return (
        <button onClick={func}>
            <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.94327 0L0.0566406 1.88669L6.16995 8L0.0566406 14.1133L1.94327 16L9.94327 8L1.94327 0Z"
                    fill="#CCCCCC"
                />
            </svg>
        </button>
    );
}

function PrevButton({ func }) {
    return (
        <button onClick={func}>
            <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.05664 0L9.94327 1.88669L3.82995 8L9.94327 14.1133L8.05664 16L0.0566406 8L8.05664 0Z"
                    fill="#CCCCCC"
                />
            </svg>
        </button>
    );
}

export default function Payment({ userData }) {
    const history = useHistory();
    const [memberFees, setMemberFees] = useState();
    const [membershipType, setMembershipType] = useState();
    const [paymentInformation, setPaymentInformation] = useState({
        memberType: "Student Member",
        JoiningFee: 1200,
        YearlyFee: 1500,
    });
    const [memberlist, setMemberlist] = useState();
    const [benefit, setBenefit] = useState();
    const [requirements, setRequirements] = useState();
    const sliderRef = useRef();
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // NextArrow: <NextButton />,
        // PrevArrow: <PrevButton />,
        arrows: false,
        responsive: [{
            breakpoint: 2561,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1921,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1401,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 426,
            settings: {
                slidesToShow: 1.09,
                slidesToScroll: 1,
            },
        },
        ],
    };

    const makeMemberFees = async () => {
        let data = {
            amount: paymentInformation.total,
            paymentType: "JoiningFee",
        };

        let memberFees = await API.graphql({
            query: createMemberFees,
            variables: {
                input: {
                    ...data,
                },
            },
        });
        // debugger;
        if (memberFees.data.createMemberFees) {
            let userId = localStorage.getItem("userId");
            let updatedMember = await API.graphql({
                query: updateMember,
                variables: {
                    input: {
                        id: userId,
                        // userType: "Member",
                        status: "Pending",
                    },
                },
            });
            // debugger;
        }

        console.log("memberFees:", memberFees);
        setMemberFees(memberFees);
        localStorage.removeItem("membershipType");
        history.push("/");
    };

    const makePayment = async () => {
        let data = {
            amount: memberFees.amount,
            memberID: localStorage.getItem("id"),
            memberFeesID: memberFees.memberFeesID,
            paymentDate: new Date(),
        };

        let paymentData = await API.graphql({
            query: createPayment,
            variables: {
                input: {
                    ...data,
                },
            },
        });

        console.log("paymentData:", paymentData);
    };

    const getMemberShipData = async () => {
        let query = `query MyQuery {
            listMembershipSettingss {
              items {
                JoiningFee
                YearlyFee
                benifit
                createdAt
                description
                id
                is_active
                memberType
                requirement
                updatedAt
              }
            }
          }
          `;
        let data = await API.graphql({ query: query });
        setMemberlist(data.data.listMembershipSettingss.items);

        let mt = localStorage.getItem("membershipType");
        if (mt) setMembershipType(mt);
        else mt = 0;
        setPaymentInformation(data.data.listMembershipSettingss.items[mt]);
        setBenefit(JSON.parse(data.data.listMembershipSettingss.items[mt].benifit));
        setRequirements(JSON.parse(data.data.listMembershipSettingss.items[mt].requirement));
    };

    const onSelectPackage = (pkg, index) => {
        setPaymentInformation(pkg);
        setBenefit(JSON.parse(pkg.benifit));
        setRequirements(JSON.parse(pkg.requirement));
        setMembershipType(index);
        // let b = JSON.parse(pkg.benifit);
        // let r = JSON.parse(pkg.requirement);
        // debugger;
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    

    useEffect(() => {
        getMemberShipData();
    }, []);

    useEffect(() => {
        if (memberFees) makePayment();
    }, [memberFees]);
    const handlePrev = () => {
        //saveData();
        //setContentType(3);
    };
    return (
        <div className="shadow p-4 rounded bg-white payment" data-animation="scaleIn">
            <section id="multisteps-form__content Membership_Payment">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="member_header">
                            <h2>Membership &amp; Payment </h2>
                            <h3>Select Membership Package</h3>
                        </div>
                    </div>
                </div>

                <div className="Payment_member_slider">
                    <div className="payment _slider_arrows">
                        <PrevButton func={goToPrev} />
                        <NextButton func={goToNext} />
                    </div>

                    <Slider {...settings} ref={sliderRef}>
                        {memberlist &&
                            memberlist.map((ml, index) => (
                                <div
                                    className={
                                        "admission_fess" +
                                        (membershipType == index ? " active" : "")
                                    }
                                    onClick={() => onSelectPackage(ml, index)}
                                >
                                    <div className="admission_fess_bg">
                                        <div calssName="svg">
                                            <svg
                                                width="26"
                                                height="26"
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="13" cy="13" r="13" fill="white" />
                                                <g clip-path="url(#clip0)">
                                                    <path
                                                        d="M12.2723 17.0402C12.181 17.1463 12.0232 17.1544 11.9215 17.0582L8.07923 13.4216C7.97759 13.3254 7.97317 13.1636 8.0693 13.0619L9.29348 11.7683C9.38967 11.6667 9.55152 11.6623 9.65316 11.7584L11.7521 13.7448C11.8537 13.841 12.0115 13.8329 12.1028 13.7269L16.2033 8.96243C16.2946 8.85637 16.4561 8.84426 16.5621 8.93557L17.9118 10.0973C18.0179 10.1886 18.03 10.3501 17.9387 10.4561L12.2723 17.0402Z"
                                                        fill="#3B54DB"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect
                                                            width="10"
                                                            height="10"
                                                            fill="white"
                                                            transform="translate(8 8)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <h4>{ml.memberType}</h4>
                                        <div className="Admission_Fees_text">
                                            <h3>Admission Fees</h3>
                                            <p>{ml.JoiningFee} BDT</p>
                                        </div>
                                        <div className="Admission_Fees_text bdr">
                                            <p>{ml.YearlyFee} BDTs</p>
                                            <h3>Annual Fees</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Slider>
                </div>

                <div className="member_benifits">
                    <div className="row">
                        {/* <!-- Member Rights & Benefits --> */}
                        <div className="col-lg-6">
                            <div className="Member_Rights_Benefits">
                                <h4>Member Rights & Benefits</h4>
                                <ul>{benefit && benefit.map((b) => <li>{b.benifit}</li>)}</ul>
                            </div>
                        </div>

                        {/* <!-- Membership Requirements --> */}
                        <div className="col-lg-6">
                            <div className="Member_Rights_Benefits Requirements">
                                <h4>Member Rights & Benefits</h4>

                                <div className="Membership_Requirements_item">
                                    {/* <!-- National ID --> */}
                                    {requirements &&
                                        requirements.map((req) => (
                                            <div className="Membership_Requirements_text">
                                                {/* <h5>National ID</h5> */}
                                                <p>{req.requirement}</p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Billing Information --> */}
                <div class="Billing_Information">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="Member_Rights_Benefits">
                                <h4>Billing Information</h4>
                                <div className="form_part">
                                    <form action="#">
                                        {/* Name Part */}
                                        <div className="input_item">
                                            <label> Name</label>
                                            <input type="text" placeholder="Enter your full name" />
                                        </div>
                                        {/* Email Address Part */}
                                        <div className="input_item">
                                            <label> Email Address</label>
                                            <input
                                                type="Email"
                                                placeholder="Enter your email address"
                                            />
                                        </div>
                                        {/* Email Address Part */}
                                        <div className="input_item">
                                            <label> Phone Number</label>
                                            <input
                                                type="Email"
                                                placeholder="Enter your phone number"
                                            />
                                        </div>
                                        {/* Email Address Part */}
                                        <div className="input_item">
                                            <p>
                                                * Curabitur porttitor egestas ornare nulla vel.
                                                Fermentum risus habitasse a ac at ut. Curabitur
                                                porttitor egestas
                                            </p>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="next_prev_btn">
                                    <a href="">Previous</a>
                                    <a href="" className="bg">
                                        Save
                                    </a>
                                </div> */}
                            </div>
                           
                        </div>
                        {/* <div className="col-lg-3"></div> */}
                        <div className="col-lg-5">
                            {/* Checkout Information */}
                            <div className="Checkout_Information">
                                <div class="Member_Rights_Benefits">
                                    <h4>Billing Information</h4>
                                    <div className="Package_Selected">
                                        {/* Package Selected */}
                                        <div className="Package_Selected_item">
                                            <div className="Package_Selected_text">
                                                <h5>Package Selected</h5>
                                                <p>{paymentInformation.memberType}</p>
                                            </div>
                                            <div className="right_text">
                                                <span>{paymentInformation.JoiningFee} BDT</span>
                                            </div>
                                        </div>
                                        {/* Annual Fee */}
                                        <div className="Package_Selected_item Annual_Fee">
                                            <div className="Package_Selected_text">
                                                <h5>Annual Fee</h5>
                                            </div>
                                            <div className="right_text">
                                                <span>{paymentInformation.YearlyFee} BDT</span>
                                            </div>
                                        </div>
                                        {/* Total */}
                                        <div className="Package_Selected_item total">
                                            <div className="Package_Selected_text">
                                                <h5>Total</h5>
                                            </div>
                                            <div className="right_text">
                                                <span>
                                                    {paymentInformation.JoiningFee +
                                                        paymentInformation.YearlyFee}{" "}
                                                    BDT
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Select Payment Method */}
                                    <div className="Select_Payment_Method">
                                        <div class="Member_Rights_Benefits">
                                            <h4>Select Payment Method</h4>
                                            {/* Pay with Debit/Credit Cards */}
                                            <div className="Pay_Debit_Cards">
                                                <div className="Pay_Debit_Cards_item">
                                                    <div className="Pa_dispay">
                                                        <input type="checkbox" />
                                                        <label>Pay with Debit/Credit Cards</label>
                                                    </div>
                                                    <div className="img">
                                                        <img src="images/paymentby.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="Pay_Debit_Cards_item">
                                                    <div className="Pa_dispay">
                                                        <input type="checkbox" />
                                                        <label>Pay with Mobile Banking</label>
                                                    </div>
                                                    <div className="img">
                                                        <img src="images/paymentby.png" alt="" />
                                                        <img src="images/paymentby_2.png" alt="" />
                                                        <svg
                                                            width="32"
                                                            height="31"
                                                            viewBox="0 0 32 31"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                x="0.861328"
                                                                width="31"
                                                                height="31"
                                                                fill="#EEEEEE"
                                                            />
                                                            <path
                                                                d="M22.3121 14.3651V16.6351C22.3121 16.8059 22.1736 16.9446 22.0026 16.9446H17.8067V21.1405C17.8067 21.3116 17.668 21.45 17.4971 21.45H15.2271C15.0564 21.45 14.9176 21.3116 14.9176 21.1405V16.9446H10.7217C10.5506 16.9446 10.4121 16.8059 10.4121 16.6351V14.3651C10.4121 14.1942 10.5506 14.0555 10.7217 14.0555H14.9176V9.85959C14.9176 9.68851 15.0563 9.55005 15.2271 9.55005H17.4971C17.668 9.55005 17.8067 9.68851 17.8067 9.85959V14.0555H22.0026C22.1737 14.0555 22.3121 14.1942 22.3121 14.3651Z"
                                                                fill="#999999"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="Pay_Debit_Cards_item Terms">
                                                    <div className="Pa_dispay">
                                                        <input type="checkbox" />
                                                        <label>
                                                            I Agree with Terms and Conditions
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="Pay_Debit_Cards_item contine_payment xs-hidden">
                                                    <a onClick={makeMemberFees}>
                                                        Continue to Payment
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="button-row text-right mt-4">
                                <button onClick={handlePrev} className="js-btn-prev" type="button" title="Prev">
                                    Prev
                                </button>
                                <button className="ml-auto js-btn-SAVE" type="button" title="SAVE">
                                    SAVE
                                </button>
                                <div className="Pay_Debit_Cards_item contine_payment xl-hidden">
                                                    <a onClick={makeMemberFees}>
                                                        Continue to Payment
                                                    </a>
                                </div>
                        </div> */}
                        <div className="button-row text-right mt-4">
                                <button onClick={handlePrev} className="js-btn-prev" type="button" title="Prev">
                                    Prev
                                </button>
                                <button className="ml-auto js-btn-SAVE" type="button" title="SAVE">
                                    SAVE
                                </button>
                                <button className="ml-auto Pay_Debit_Cards_item contine_payment xl-hidden"  type="button" title="Next" onClick={makeMemberFees}>
                                   
                                        Continue to Payment
                                </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

import React from "react";
import BecomeAMemberComponent from "../../components/become-a-member/become-a-member/becomeAMember";
import Layout from "../../components/common/layout/layout";
import Benefits from "../../components/home/benefits/benefits";
import Follow from "../../components/home/follow/Follow";
import Ama from "../../components/home/ama/Ama";
import Ctoforum from "../../components/home/ctoforum/Ctoforum";
import Banner from "../../components/become-a-member/become-a-member/Banner";

export default function BecomeAMember() {
    return (
        <Layout showBanner={false}>
            <div className="become_member">
                <Banner />
                {/* <Benefits /> */}
                <Follow />
                <BecomeAMemberComponent />
            </div>
        </Layout>
    );
}

import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/common/layout/layout";
import MembershipComponent from "../../components/membership/membership/membership";

export default function Membership() {
    let { id } = useParams();
    return (
        <Layout showBanner={false}>
            <MembershipComponent id={id} />
        </Layout>
    );
}

import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import FeaturedMagazine from "../featured-magazine/featuredMagazine";
import MagazineList from "../magazine-list/magazineList";

export default function MagazineComponent() {
    const [magazines, setMagazines] = useState();
    useEffect(async () => {
        magazinelist();
    }, []);
    const magazinelist = async () => {
        let magazineListQuery = `query MyQuery {
                                    listMagazines {
                                    items {
                                        createdAt
                                        description
                                        documentUrl
                                        featuredArticle
                                        id
                                        image_url
                                        issue_name
                                        issue_no
                                        publicationDate
                                        updatedAt
                                        writers
                                    }
                                    }
                                }
                                `;
        let data = await API.graphql({ query: magazineListQuery });
        console.log(data.data.listMagazines, "------------data.data.listMagazines");
        setMagazines(data.data.listMagazines);
    };
    return (
        <div id="Mazine">
            <section class="all_member_banner">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 m-auto">
                            <div class="all_member_text">
                                <h3>All Magazines</h3>
                                <p>
                                    Blandit eget urna, a, turpis molestie quam sed quam. Morbi
                                    mattis in phasellus enim. Eleifend augue morbi nulla turpis
                                    aenean neque diam egestas. Risus, ultrices eros, purus, sit.
                                    Duis posuere lacus tellus, varius tincidunt in tempor vel.{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="All_member_list_part">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-4 m-auto">
                            <div class="member_item">
                                <div class="input">
                                    <input
                                        type="text"
                                        placeholder="Search by Name"
                                        class="form-control"
                                    />
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {magazines ? (
                magazines.items.map((item, index) => {
                    let status = true;
                    if (index % 2 == 0) {
                        status = true;
                    } else {
                        status = false;
                    }
                    return <MagazineList rightStatus={status} magazine={item} />;
                })
            ) : (
                <React.Fragment>
                    <h1>Not Found</h1>
                </React.Fragment>
            )}

            {/* old code */}
            {/* <div>
            <div class="magazine_bg banner_bg">
                <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="images/magazine/magazine.jpg" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <div class="carousel-caption d-none d-md-block">
                        <div class="about-desc">
                            <h2>cto Magazine</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem
                                ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FeaturedMagazine />
            <MagazineList />
        </div> */}
            {/* <div class="section_height"></div> */}
        </div>
    );
}

import React from "react";

export default function TopQuestions() {
    return (
        <div className="col-md-6 question_part">
            <h3 className="text-center">Top Question</h3>
            <ul>
                <li>What is blockchain ?</li>
                <li>Lorem Ipsum is simply dummy text of the printing and t ?</li>
                <li>Ipsum is simply dummy text of the printing and t ?</li>
                <li>Ipsum is simply dummy text of the printing and t ?</li>
                <li>Simply dummy text of the printing and t ?</li>
                <li>Ipsum is simply dummy text of printi nd text of the printing and t ?</li>
                <li>What is blockchain ?</li>
                <li>Simply dummy text of the printing and t ?</li>
                <li>Simply dummy text of the printing and t ?</li>
                <li>Simply dummy text of the printing and t ?</li>
            </ul>
        </div>
    );
}

import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRoutes } from "./routeConfig";
import { publicRoutes } from "./routeList";

export const Routes = () => {
    return (
        <Router forceRefresh={true}>
            <Switch>
                {publicRoutes.map((route, i) => (
                    <PublicRoutes exact key={i} path={route.url} component={route.component} />
                ))}
            </Switch>
        </Router>
    );
};

import React, { useState, useEffect } from "react";

import { API } from "aws-amplify";
import { deleteEducation } from "../../../graphql/mutations";
import "./educationFieldsComponent.css";

export default function EducationFieldsComponent({
    index,
    educationFieldData,
    setEducationFieldData,
}) {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(educationFieldData[index]);
    }, [educationFieldData]);

    const updateField = (value, field) => {
        let d = data;
        d[field] = value;
        setData({ ...d });
        let dt = educationFieldData;
        dt[index] = d;
        setEducationFieldData([...dt]);
    };

    const deleteEdu = async () => {
        let dt = educationFieldData;
        let del = dt.splice(index, 1)[0];
        setEducationFieldData([...dt]);

        let deletedData = await API.graphql({
            query: deleteEducation,
            variables: {
                input: {
                    id: del.id,
                },
            },
        });

        // console.log("deleted education:", deletedData);
    };

    return (
        <React.Fragment>
            <div class="form-row mt-4">
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Your Qualifications <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        value={data.qualification}
                        onChange={(e) => updateField(e.target.value, "qualification")}
                        type="text"
                        placeholder="Enter your qualifications"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Institute <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.institution}
                        onChange={(e) => updateField(e.target.value, "institution")}
                        placeholder="Enter your Institute"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Board / University <span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.board}
                        onChange={(e) => updateField(e.target.value, "board")}
                        placeholder="Enter your Board / University"
                    />
                </div>
                <div class="col-xl-3 col-12">
                    <label for="profession">
                        Year of Passing<span>*</span>
                    </label>
                    <input
                        class="multisteps-form__input form-control"
                        type="text"
                        value={data.passingYear}
                        onChange={(e) => updateField(e.target.value, "passingYear")}
                        placeholder="Enter your Passing year"
                    />
                </div>
                <div class="icon">
                    <i class="fas fa-trash-alt" onClick={deleteEdu}></i>
                </div>
            </div>

            <div className="form-row mt-4"></div>
        </React.Fragment>
    );
}

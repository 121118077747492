import React from "react";
import Layout from "../../components/common/layout/layout";
import EventsComponent from "../../components/events/events/events";

export default function Events() {
    return (
        <Layout showBanner={false}>
            <EventsComponent />
        </Layout>
    );
}

import React from "react";
import Layout from "../../common/layout/layout";
import Books from "../books/books";
import Newspapers from "../newspapers/newspapsers";
import Others from "../others/others";
import Reports from "../reports/reports";

export default function PublicationsComponent() {
    return (
        <Layout showBanner={false}>
            <div className="commingSoon_part">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Yet To Reveal!</h2>
                            <p>Exciting and Effectual things are on making. Subsequently of completion, will come on live! </p>
                            <a href="#">
                                Go to Home
                            </a>
                        </div>
                    </div>
                </div>
            </div>  
        </Layout>
    );
}

import React, { useEffect, useState } from "react";
import { API, Storage } from "aws-amplify";
import { updateMember } from "../../../graphql/mutations";
import { S3Image } from "aws-amplify-react";
import "./personalInfo.css";

const membershipType = ["HonoraryMember", "Fellow", "Member", "AssociateMember", "LifeFellow"];

export default function PersonalInfo({ userData }) {
    const [fullName, setFullName] = useState("");
    const [fathersName, setFathersName] = useState("");
    const [mothersName, setMothersName] = useState("");
    const [dob, setDob] = useState("");
    const [pob, setPob] = useState("");
    const [maritalStatus, setMaritalStatus] = useState(null);
    const [homePhone, setHomePhone] = useState("");
    const [cellPhone, setCellPhone] = useState();
    const [workPhone, setWorkPhone] = useState();
    const [bloodGroup, setBloodGroup] = useState();
    const [professionalEmail, setProfessionalEmail] = useState();
    const [personalEmail, setPersonalEmail] = useState();
    const [mailingAddress, setMailingAddress] = useState();
    const [mailingArea, setMailingArea] = useState(); // spelling mistake in backend
    const [mailingZip, setMailingZip] = useState(); // spelling mistake in backend
    const [mailingCityID, setMailingCityID] = useState(); // spelling mistake in backend
    const [mailingCountryID, setMailingCountryID] = useState(); // spelling mistake in backend
    const [permanentAddress, setPermanentAddress] = useState();
    const [permanentArea, setPermanentArea] = useState();
    const [permanentZip, setPermanentZip] = useState();
    const [permanentCityID, setPermanentCityID] = useState();
    const [permanentCountryID, setPermanentCountryID] = useState();
    const [gender, setGender] = useState();
    const [profilePhotoUrl, setProfilePhotoUrl] = useState();
    const [memberType, setMemberType] = useState();

    const [mailingCityList, setMailingCityList] = useState([]);
    const [mailingCountryList, setMailingCountryList] = useState([]);
    const [permanentCityList, setPermanentCityList] = useState([]);
    const [permanentCountryList, setPermanentCountryList] = useState([]);

    useEffect(async () => {
        console.log("--------");
        console.log(userData);
        console.log("--------");
        let memberType = parseInt(localStorage.getItem("memberType"));
        if (memberType) setMemberType(membershipType[memberType - 1]);
        if (userData) {
            setFullName(userData.fullName);
            setFathersName(userData.fathersName);
            setMothersName(userData.mothersName);
            setDob(userData.dob);
            setPob(userData.pob);
            setMaritalStatus(userData.maritalStatus);
            setHomePhone(userData.homePhone);
            setCellPhone(userData.mobile);
            setWorkPhone(userData.workPhone);
            setBloodGroup(userData.bloodGroup);
            setProfessionalEmail(userData.professionalEmail);
            setPersonalEmail(userData.email);
            setGender(userData.gender);
            setPermanentAddress(userData.permanentAddress);
            setPermanentArea(userData.permanentArea);
            setPermanentZip(userData.permanentZip);
            setPermanentCityID(userData.permanentCityID);
            setPermanentCountryID(userData.permanentCountryID);
            setMailingAddress(userData.mailingAddress);
            setMailingArea(userData.mailingArea);
            setMailingZip(userData.mailingZip);
            setMailingCityID(userData.mailingCityID);
            setMailingCountryID(userData.mailingCountryID);
            setProfilePhotoUrl(userData.profilePhotoUrl);
        }
        if (userData?.memberType) setMemberType(userData.memberType);

        let listCountryQuery = `#graphql
            query MyQuery {
                listCountrys {
                        items {
                            id
                            countryName
                        }
                    }
            }
        `;
        let listCountry = await API.graphql({
            query: listCountryQuery,
        });
        if (listCountry) {
            setMailingCountryList(listCountry.data.listCountrys.items);
            setPermanentCountryList(listCountry.data.listCountrys.items);
        }
        // console.log("==========");
        // console.log(listCountry);
        // console.log("==========");
    }, [userData]);

    useEffect(async () => {
        const filterCityQuery = `#graphql
            query MyQuery($countryID: ID) {
                listStates(filter: {countryID: {eq: $countryID}}) {
                    items {
                    id
                    stateName
                    }
                }
            }
        `;
        let cityList = await API.graphql({
            query: filterCityQuery,
            variables: {
                countryID: mailingCountryID,
            },
        });
        if (cityList) {
            setMailingCityList(cityList.data.listStates.items);
        }
    }, [mailingCountryID]);

    useEffect(async () => {
        const filterCityQuery = `#graphql
            query MyQuery($countryID: ID) {
                listStates(filter: {countryID: {eq: $countryID}}) {
                    items {
                    id
                    stateName
                    }
                }
            }
        `;
        let cityList = await API.graphql({
            query: filterCityQuery,
            variables: {
                countryID: permanentCountryID,
            },
        });
        if (cityList) {
            setPermanentCityList(cityList.data.listStates.items);
        }
    }, [permanentCountryID]);

    const saveData = async () => {
        let data = {
            ...userData,
            fullName,
            fathersName,
            mothersName,
            dob,
            pob,
            maritalStatus,
            homePhone,
            cellPhone,
            workPhone,
            bloodGroup,
            professionalEmail,
            personalEmail,
            gender,
            permanentAddress,
            permanentArea,
            permanentZip,
            permanentCityID,
            permanentCountryID,
            mailingArea,
            mailingArea,
            mailingCityID,
            mailingCountryID,
            memberType,
        };
        // :(
        delete data.educationInfo;
        delete data.jobInfo;
        delete data.payment;
        delete data.profession;
        delete data.permanentCountry;
        delete data.permanentCity;
        delete data.mailingCountry;
        delete data.mailingCity;
        delete data.committee;
        console.log(data);
        console.log(data.committee, '-------committee');
        let updatedMember = await API.graphql({
            query: updateMember,
            variables: {
                input: {
                    ...data,
                },
            },
        });
        console.log("updatedMemeber", updatedMember);
    };

    const handleImageUpload = async (e) => {
        let userId = localStorage.getItem("id");
        let file = e.target.files[0];
        console.log(file);
        console.log(file.name);
        let uploadedImage = await Storage.put("uploads/images/" + userId + file.name, file);
        // update member for image field
        // try {
        let updatedMember = await API.graphql({
            query: updateMember,
            variables: {
                input: {
                    id: userId,
                    profilePhotoUrl: uploadedImage.key,
                },
            },
        });
        if (updatedMember) {
            setProfilePhotoUrl(updatedMember.data.updateMember.profilePhotoUrl);
        }
        // } catch (e) {
        //     console.log("=============");
        //     console.log(e);
        //     console.log("=============");
        // }
    };

    return (
        <div className="shadow p-4 rounded bg-white prsonal_edit" data-animation="scaleIn">
            <div className="profession_part">
                <div className="form-row upload_img_part">
                    <div className="col-12">
                        <h2>Personal Info</h2>
                    </div>
                    <div className="col-12 upload_img">
                        <S3Image className="img-fluid" imgKey={profilePhotoUrl} />
                        {/* <img src="images/user_upload.png" className="img-fluid" alt="" /> */}
                        <div className="user_title">
                            <h2>Upload Your Picture</h2>
                            {/* <p>Click to upload</p> */}
                            <div className="upload_here">
                                <input type="file" onChange={(e) => handleImageUpload(e)} />
                                <p>Click to upload</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row input_feild">
                    <div className="col-12 col-sm-4">
                        <label for="fullname">
                            Full Name <span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            id="fullname"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div className="col-12 col-sm-4">
                        <label for="fullname">
                            Father’s Name<span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            id="fullname"
                            type="text"
                            value={fathersName}
                            onChange={(e) => setFathersName(e.target.value)}
                            placeholder="Enter your Father’s name"
                        />
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="fullname">
                            Mother’s Name<span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="text"
                            value={mothersName}
                            onChange={(e) => setMothersName(e.target.value)}
                            placeholder="Enter your Mother’s name"
                        />
                    </div>
                </div>

                <div className="form-row input_feild">
                    <div className="col-12 col-sm-4">
                        <label for="fullname">
                            Date of Birth<span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="date"
                            placeholder="Date of Birth"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-sm-4 gender">
                        {" "}
                        {/*need to dynamic */}
                        <label for="profession">Gender</label>
                        <select
                            className="form-control"
                            id="profession"
                            value={gender}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                        >
                            <option value="">Select Gender</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label for="profession">
                            Marital Status <span>*</span>
                        </label>
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={maritalStatus}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                        >
                            <option value="">Marital Status</option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarrid</option>
                        </select>
                        <i className="fas fa-chevron-down"></i>

                        {/* <input
                            className="multisteps-form__input form-control"
                            type="text"
                            placeholder="Place of Birth"
                            value={pob}
                            onChange={(e) => setPob(e.target.value)}
                        /> */}
                    </div>
                </div>
                <div className="form-row input_feild">
                    <div className="col-12 col-sm-4">
                        <label for="profession">Home Phone</label>
                        <input
                            className="multisteps-form__input form-control"
                            type="tel"
                            placeholder="Home Phone"
                            value={homePhone}
                            onChange={(e) => setHomePhone(e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-sm-4">
                        <label for="profession">
                            Cell Phone <span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="tel"
                            placeholder="Cell Phone"
                            disabled
                            value={cellPhone}
                        />
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="profession">Work Phone</label>
                        <input
                            className="multisteps-form__input form-control"
                            type="tel"
                            placeholder="Work Phone "
                            value={workPhone}
                            onChange={(e) => setWorkPhone(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className="form-row mt-4">
                    <div className="col-12 col-sm-4 blood-group"> */}
                {/* <input
                            className="multisteps-form__input form-control"
                            type="text"
                            placeholder="Blood Group "
                            value={bloodGroup}
                            onChange={(e) => setBloodGroup(e.target.value)}
                        /> */}
                {/* <label for="profession">Blood Group <span>*</span></label>
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={bloodGroup}
                            onChange={(e) => setBloodGroup(e.target.value)}
                        >
                            <option value="">Select Blood Group</option>
                            <option value="ABP">AB+</option>
                            <option value="ABN">AB-</option>
                            <option value="BP">B+</option>
                            <option value="BN">B-</option>
                            <option value="AP">A+</option>
                            <option value="AN">A-</option>
                            <option value="OP">O+</option>
                            <option value="ON">O-</option>
                        </select>
                        <i className="fas fa-sort-down"></i>
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="profession">
                            Personal Mail <span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="email"
                            placeholder="Personal Email "
                            disabled
                            value={personalEmail}
                        />
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="profession">
                            Professional Mail<span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="email"
                            placeholder="Professional Email"
                            value={professionalEmail}
                            onChange={(e) => setProfessionalEmail(e.target.value)}
                        />
                    </div>
                </div> */}
                <div className="form-row mt-4 input_feild">
                    {/* <div className="col-12 col-sm-4">
                        <label for="profession">
                            Blood Group <span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="text"
                            placeholder="Blood Group "
                        />
                    </div> */}
                    <div className="col-12 col-sm-4 blood">
                        <label for="profession">
                            Blood Group <span>*</span>
                        </label>
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={bloodGroup}
                            onChange={(e) => setBloodGroup(e.target.value)}
                        >
                            <option value="">Select Blood Group</option>
                            <option value="ABP">AB+</option>
                            <option value="ABN">AB-</option>
                            <option value="BP">B+</option>
                            <option value="BN">B-</option>
                            <option value="AP">A+</option>
                            <option value="AN">A-</option>
                            <option value="OP">O+</option>
                            <option value="ON">O-</option>
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="profession">
                            Personal Mail <span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="email"
                            placeholder="Personal Mail"
                            disabled
                            value={personalEmail}
                        />
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0">
                        <label for="profession">
                            Professional Mail<span>*</span>
                        </label>
                        <input
                            className="multisteps-form__input form-control"
                            type="email"
                            placeholder="Professional Mail"
                        />
                    </div>
                </div>
                <label className="input_feild" for="profession">
                    Mailing Address
                </label>
                <div className="form-row mb-4">
                    <div className="col-xl-3 col-12">
                        <input
                            className="multisteps-form__input form-control col"
                            type="email"
                            placeholder="Street"
                            value={mailingAddress}
                            onChange={(e) => setMailingAddress(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            placeholder="Area"
                            value={mailingArea}
                            onChange={(e) => setMailingArea(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            placeholder="Zip Code"
                            value={mailingZip}
                            onChange={(e) => setMailingZip(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        {/* <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            placeholder="Country"
                            value={mailingCountryID}
                            onChange={(e) => setMailingCountryID(e.target.value)}
                        /> */}
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={mailingCountryID}
                            onChange={(e) => setMailingCountryID(e.target.value)}
                        >
                            <option value="">Select Mailing Country</option>
                            {mailingCountryList.map((country) => (
                                <option value={country.id}>{country.countryName}</option>
                            ))}
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="col-xl-3 col-6">
                        {/* <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            placeholder="City"
                            value={mailingCityID}
                            onChange={(e) => setMailingCityID(e.target.value)}
                        /> */}
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={mailingCityID}
                            onChange={(e) => setMailingCityID(e.target.value)}
                        >
                            <option value="">Select Mailing City</option>
                            {mailingCityList.map((city) => (
                                <option value={city.id}>{city.stateName}</option>
                            ))}
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
                <label className="input_feild" for="profession">
                    Permanent Address
                </label>
                <div className="form-row">
                    <div className="col-xl-3 col-12">
                        <input
                            className="multisteps-form__input form-control col"
                            type="email"
                            placeholder="Street"
                            value={permanentAddress}
                            onChange={(e) => setPermanentAddress(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            placeholder="Area"
                            value={permanentArea}
                            onChange={(e) => setPermanentArea(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            value={permanentZip}
                            placeholder="Zip Code"
                            onChange={(e) => setPermanentZip(e.target.value)}
                        />
                    </div>
                    <div className="col-xl-3 col-6">
                        {/* <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            value={permanentCountryID}
                            placeholder="Country"
                            onChange={(e) => setPermanentCountryID(e.target.value)}
                        /> */}
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={permanentCountryID}
                            onChange={(e) => setPermanentCountryID(e.target.value)}
                        >
                            <option value="">Select Permanent Country</option>
                            {permanentCountryList.map((country) => (
                                <option value={country.id}>{country.countryName}</option>
                            ))}
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                    <div className="col-xl-3 col-6">
                        {/* <input
                            className="multisteps-form__input form-control col"
                            type="text"
                            value={permanentCityID}
                            placeholder="City"
                            onChange={(e) => setPermanentCityID(e.target.value)}
                        /> */}
                        <select
                            className="form-control list_item"
                            id="exampleFormControlSelect1"
                            style={{ "-webkit-appearance": "none" }}
                            value={permanentCityID}
                            onChange={(e) => setPermanentCityID(e.target.value)}
                        >
                            <option value="">Select Permanent City</option>
                            {permanentCityList.map((city) => (
                                <option value={city.id}>{city.stateName}</option>
                            ))}
                        </select>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div className="button-row text-right mt-4">
                    <button
                        className="ml-auto js-btn-SAVE"
                        type="button"
                        onClick={saveData}
                        title="SAVE"
                    >
                        SAVE
                    </button>

                    <button
                        className="ml-auto js-btn-next"
                        type="button"
                        // disabled={
                        //     !fullName.length ||
                        //     !fathersName.length ||
                        //     !mothersName.length ||
                        //     !dob.length ||
                        //     gender == "" ||
                        //     maritalStatus == "" ||
                        //     !cellPhone.length ||
                        //     bloodGroup == "" ||
                        //     !personalEmail.length ||
                        //     !professionalEmail.length
                        // }
                        onClick={saveData}
                        title="Next"
                    >
                        Next
                    </button>
                    {/* {nextStatus ? (
                        <button
                            className="ml-auto js-btn-next"
                            type="button"
                            onClick={saveData}
                            title="Next"
                        >
                            Next
                        </button>
                    ) : (
                        <button className="ml-auto " type="button" title="Next">
                            Next
                        </button>
                    )} */}
                </div>
            </div>
        </div>
    );
}

export default function Ama() {
    return ( <
        div className = "ama_event_part" >
        <
        div className = "container-fluid" >
        <
        div className = "row" >
        <
        div className = "col-md-6 col-sm-12" >
        <
        div className = "ama" >
        <
        h2 > Be updated with latest industry insights < /h2>  <
        p > Up to date information can deliver resources that’ ll be needed anytime!Insights can provide you a good look into feasibility! < /p>  <
        div className = "img_item d-flex" >
        <
        div className = "all_img_item" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_1.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  <
        div className = "all_img_item" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_2.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  <
        div className = "all_img_item xs-hidden" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_3.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  < /
        div > <
        a href = "/comming-soon" > Visit Now < /a>  <
        span > Explore our previous AMAs < /span> </div >
        <
        /div>  <
        div className = "col-md-6 col-sm-12 event text-center" >
        <
        h2 > Be a part of leading industry events < /h2>  <
        hr / >
        <
        p > Tech goes quick.Take participation of maximum events and don’ t miss anything. < /p>  <
        div className = "img_item d-flex" >
        <
        div className = "all_img_item" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_4.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  <
        div className = "all_img_item" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_5.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  <
        div className = "all_img_item xs-hidden" >
        <
        div className = "span_border" > < /div>  <
        img src = "images/ama_6.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  < /
        div > <
        a href = "/comming-soon" > Visit Now < /a>  <
        span > Visit our prevoius events < /span>  < /
        div > <
        /div>  < /
        div > <
        /div>
    );
};
import React, {useState, useEffect} from "react";
import { S3Image } from "aws-amplify-react";
import { API, Storage } from "aws-amplify";
import MdoucUrl from "./MdoucUrl";

export default function Explore(){
    const [listOfmagazine, setListOfmagazine] = useState();
    useEffect(async() => {
       
       await MagazineList();
    },[])
    const MagazineList = async() => {
        let letQuery = `query MyQuery {
            listMagazines {
              items {
                createdAt
                description
                documentUrl
                featuredArticle
                id
                image_url
                issue_name
                issue_no
                publicationDate
                updatedAt
                writers
              }
            }
          }
          `;
          
          let datamagazine = await API.graphql({query: letQuery})
           console.log(datamagazine.data.listMagazines, '----------------------------datamagazine')
        //  debugger
        let datamagazineData = datamagazine.data.listMagazines;
        datamagazineData.items.sort(function(a, b) {
            var keyA = new Date(a.publicationDate),
              keyB = new Date(b.publicationDate);
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });

          setListOfmagazine(datamagazineData);
          
    }
    
    return (
        <div className="explore_issue">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h2>CTO Magazine</h2>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="explore_rg_padding">
                        <a href="/comming-soon">Explore all issues </a>
                        <p>What goes around in the tech world? Analytical features, stories and innovations are always on the run on every page of our magazine. Focusing on solving new problems with innovation, some techy brains always put some thoughts and ideas that create insight for everyone who love tech. 
                        </p>
                        <p>Being a member, want to share your ideas to the world. Write! .</p>
                        <p>Explore</p>
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <div className="issue_slider">
                        <div className="issue_slider_item">
                                    <S3Image
                                            imgKey={listOfmagazine?.items[0].image_url}
                                            className=""
                                        />
                                {/* <img src="images/issue_2.png" alt="" /> */}
                                
                               {listOfmagazine&&<MdoucUrl data={listOfmagazine.items[0]} />}
                                
                            </div>
                        
                        
                            <div className="issue_slider_item">
                            <S3Image
                                            imgKey={listOfmagazine?.items[1].image_url}
                                            className=""
                                        />
                               
                                {listOfmagazine&& <MdoucUrl data={listOfmagazine.items[1]} />}
                                
                            </div>
                            <div className="issue_slider_item">
                            <S3Image
                                            imgKey={listOfmagazine?.items[2].image_url}
                                            className=""
                                        />
                               
                                {listOfmagazine&&<MdoucUrl data={listOfmagazine?.items[2]} />}
                            </div>

                            {/* <div className="issue_slider_item">
                                <img src="images/issue_2.png" alt="" />
                                <p>Issue <span>15, jan-April 2021</span></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import Layout from '../../../components/common/layout/layout';
import EventDetailsComponent from '../../../components/events/events/others-events/EventDetailsComponent';
import { useParams } from "react-router-dom";
const EventDetails = () => {
    let { id } = useParams();
    return (
        <Layout showBanner={false}>
            <EventDetailsComponent id={id} />
        </Layout>
    );
};

export default EventDetails;
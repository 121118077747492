import React from "react";

const Banner = () => {
    const userData = localStorage.getItem("userId");
    // console.log(userData, '-=--------------userData')
   
    return (
        <div className="banner_bg col-xl-12 col-md-12 col-sm-12 col-xs-12">
            <div className="container-fluid">
                <div className="banner_div">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="">
                                <h2>Become a Proud Member</h2>
                                <p>
                                    Blandit eget urna, a, turpis molestie quam sed quam. Morbi
                                    mattis in phasellus enim. Eleifend augue morbi nulla turpis
                                    aenean neque diam egestas. Risus, ultrices eros, purus, sit.
                                    Duis Blandit eget urna, a, turpis molestie quam sed quam. Morbi
                                    mattis in phasellus enim. Eleifend augue morbi nulla turpis
                                    aenean.{" "}
                                </p>
                                <p>
                                    Morbi mattis in phasellus enim. Eleifend augue morbi nulla
                                    turpis aenean.
                                </p>
                                {userData?
                                <a href="/membership/4146e5e4-d6d1-4354-92bf-6bfdd9b00fda">Register Now</a>
                                :
                                <a href="/signup">Register Now</a>
                                }
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="img">
                                <img src="images/become_banner.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;

export default function Ctoforum() {
    return ( <
        div className = "forum_part col-xl-12 col-md-12 col-sm-12 col-xs-12" >
        <
        div className = "container-fluid" >
        <
        div className = "row" >
        <
        div className = "col-md-4 col-sm-12" >
        <
        div className = "" >
        <
        img src = "images/mike.png"
        className = "img-fluid"
        alt = "" / >
        <
        /div>  < /
        div > <
        div className = "col-md-8 col-sm-12" >
        <
        div className = "about" >
        <
        h2 > About CTO Forum < /h2>   <
        p > The CTO Forum Bangladesh is an independent, non - profit, non - political organization dedicated to fostering a trusted, open and creative environment where the brightest minds of our time convene to address the banking industry 's important issues. The CTO Forum members collaborate and develop solutions that harness the extraordinary potential of technology. < /
        p > <
        p > The CTO Forum aims to provide a powerful platform where technology leaders and visionaries from around the world can explore topics of interest and share knowledge in an unbiased, trusted and intellectually charged environment.Members respect and support the mission of the organization.The support by members is vital to the continued success, growth and relevance of the Forum, as we seek to invigorate innovation and technological advancements in the modern world. < /p>  <
        a href = "/about" > Learn More < /a>   < /
        div > <
        /div>   < /
        div > <
        /div>   < /
        div >
    );
};
import React, {
    useState
} from "react";
export default function Innovation() {
    const [showMore, setShowMore] = useState(false);
    const ShowMoreFun = () => {
        setShowMore(true);
    }
    const Showless = () => {
        setShowMore(false);
    }

    return ( <
        div className = "innovation" >
        <
        div className = "container-fluid" >
        <
        div className = "row" >
        <
        div className = "col-md-6" >
        <
        h2 > CTO forum Innovation Hack - a - Thon < /h2>{" "}  <p >
        CTO forum Innovation Hackathon aims to connect talented youth tech professionals, students, entrepreneurs, leaders, and innovators with the top industry professionals in the rising fields of new technologies.Registration starting on 1 st August 2021, the event will be on the wheel on 4th September to 2nd October.Explore the tech world and put yourself in solving problems and making innovative technologies!{
            showMore == false && < span className = "read-more show-more-btn"
            onClick = { ShowMoreFun } > ...Read More < /span> } {
            showMore == true ?
            <
            >
            <
            br / >
            This hackathon is jointly collaborated by CTO Forum,
            Access to
            Information,
            Bangladesh Govt.and Bangladesh Hi - tech Park
            Authority. <
            br / >
            This Hackathon throws 6 challenges consist of many sub -
            challenges among which Virtual Assistant,
            Manual and automation
            process,
            Serverless emerging and innovative technology,
            Zero
            Hunger,
            Good Health and Well - being,
            Quality Education would be
            the main challenges. <
            br / >
            Having three rounds(idea, Prototype and Final) in process,
            the
            event will face completion awarding the best ideas and solutions. <
            br / >
            You may participate in the event solo or with a team.A team can be
            consisted of maximum 3 members.Registration fee is 500 / -
            for
            every entry.There are no restrictions on the choice of your team
            member as long as your team members are legal residents of
            Bangladesh. <
            br / >
            The winners will be awarded with attractive cash prizes,
            recognitions,
            and job opportunities. <
            br / >
            Go to this Registration Link shorturl.at / guPW7 and fill out the
            form before the Registration ends. < br / > {
                showMore == true && < span className = "read-more show-more-btn"
                onClick = { Showless } > Show less < /span> } < / >
                : ""
            } 
            </p>{" "} <
            div className = "calender text-center" >
            <
            img src = "images/calender.png"
            className = "img-fluid"
            alt = "" / >
            <
            h4 > 4th September to 2nd October,
            2021 < /h4>{" "} <
            p > {
                " "
            }
            Registration is on the wheel.Be a part before it disappears!{
                " "
            } <
            /p>{" "} 
            <a className="bgss" href = "https://accounts.google.com/ServiceLogin/webreauth?service=wise&passive=1209600&continue=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSd-5Z08UaYi0ZU39DXJ9SYlmyEYOo4u632EcbyCoeTWIr7OVA%2Fviewform%3Fpli%3D1&followup=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2Fe%2F1FAIpQLSd-5Z08UaYi0ZU39DXJ9SYlmyEYOo4u632EcbyCoeTWIr7OVA%2Fviewform%3Fpli%3D1&ltmpl=forms&authuser=0&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
            target = "_blank" > Register now < /a>{" "}
            <a  href = "https://www.ctoforumbd.org/innovation-hackathon"
            target = "_blank" > Event Details < /a>{" "}
             <
            span > Be stand
            for the challenge and win! < /span>{" "} < /
            div > {
                " "
            } <
            /div>{" "} <
            div className = "col-md-1" > < /div>{" "} <
            div className = "col-md-5" >
            <
            img src = "images/poster.png"
            className = "img-fluid"
            alt = "" / >
            <
            /div>{" "} < /
            div > {
                " "
            } <
            /div>{" "} < /
            div >
        );
    }
import React from "react";
import Layout from "../../components/common/layout/layout";
import ExecutiveCommitteeComponent from "../../components/executive-committee/executiveCommittee";
const executiveCommittee = () => {
    return (
        <Layout showBanner={false}>
            <ExecutiveCommitteeComponent />
        </Layout>
    );
};

export default executiveCommittee;

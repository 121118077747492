import { API } from "aws-amplify";
export async function eventList(search, filter){
   let query = `query MyQuery {
    listEvents {
      items {
        address
        createdAt
        eventName
        description
        fromDate
        id
        imageUrl
        isComplete
        toDate
        updatedAt
      }
    }
  }
  
  `;
  let responsive = await API.graphql({query: query})

  console.log(responsive, '----------------responsive')
  return responsive.data.listEvents.items;
}
export async function eventByID(id)
{
    let query = `query MyQuery {
                    getEvent(id: "${id}") {
                    address
                    createdAt
                    description
                    eventName
                    fromDate
                    id
                    imageUrl
                    isComplete
                    toDate
                    updatedAt
                    }
                }
      
      `;
      let responsive = await API.graphql({query: query})
    
    //   console.log(responsive, '----------------responsive')
      return responsive.data.getEvent;

    
}
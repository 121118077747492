import React from "react";
import Layout from "../../components/common/layout/layout";
import PublicationsComponent from "../../components/publications/publications/publications";

export default function Publications() {
    return (
        <Layout showBanner={false}>
            <PublicationsComponent />
        </Layout>
    );
}

import React from "react";
import { useParams } from "react-router-dom";

import Layout from "../../components/common/layout/layout";
import MemberDetailsComponent from "../../components/member-details/memberDetails";

export default function MemberDetails() {
    let { id } = useParams();

    return (
        <Layout showBanner={false}>
            <MemberDetailsComponent id={id} />
        </Layout>
        // <MemberDetailsComponent id={id} />
    );
}

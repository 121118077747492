import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function BlurNavbar() {
    const history = useHistory();
    const [userEmail, setUserEmail] = useState("");
    const [path, setPath] = useState();
    useEffect(() => {
        setPath(window.location.pathname);
        let email = localStorage.getItem("email");
        setUserEmail(email);
        console.log("path------", path);
    }, []);

    const logout = () => {
        localStorage.clear();
        history.push("/");
    };

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }
    return (
        // "nav_bg col-xl-12 col-md-12 col-sm-12 col-xs-12"
        
        <div className={path == "innovation-hackathon"?"nav_bg innovation-hackathon col-xl-12 col-md-12 col-sm-12 col-xs-12":"nav_bg col-xl-12 col-md-12 col-sm-12 col-xs-12"}>
            <nav className="navbar" style={{ background: "#d1d7e0" }}>
                <span className="brand_logo" style={{ fontSize: "30px", cursor: "pointer" }}>
                    <img src="images/toggle_bar.png" className="icon" alt="" onClick={openNav} />
                    <a href="/">
                        <img src="images/logo.png" alt="" className="logo" />
                    </a>
                   
                </span>
                
                <div id="mySidenav" className="sidenav">
                    <button className="closebtn" onClick={closeNav}>
                        &times;
                    </button>
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="index.html">
                                Home <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link dropdown-toggle"
                                href="/about"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                About Us
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a className="dropdown-item" href="about_history.html">
                                    CTO Forum in Brief
                                </a>
                                <a className="dropdown-item" href="about_grow.html">
                                    Executive Committee
                                </a>
                                <a className="dropdown-item" href="about_vission.html">
                                    Advisory Committee
                                </a>
                                <a className="dropdown-item" href="about_success.html">
                                    Previous Speakers
                                </a>
                                <a className="dropdown-item" href="/">
                                    Members List
                                </a>
                                <a className="dropdown-item" href="about_inquary.html">
                                    Inquiry
                                </a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/">
                                Activity Timeline
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/" tabIndex="-1" aria-disabled="true">
                                Trainings
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/" tabIndex="-1" aria-disabled="true">
                                Awards and Certification
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="/events"
                                tabIndex="-1"
                                aria-disabled="true"
                            >
                                Events
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="/publications"
                                tabIndex="-1"
                                aria-disabled="true"
                            >
                                Publications
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="/member-list"
                                tabIndex="-1"
                                aria-disabled="true"
                            >
                                Member
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/" tabIndex="-1" aria-disabled="true">
                                Partners
                            </a>
                        </li>
                        <li className="nav-item hidden-md hidden-lg">
                            <a className="nav-link" href="/ama" tabIndex="-1" aria-disabled="true">
                                AMA
                            </a>
                        </li>
                        <li className="nav-item hidden-md hidden-lg">
                            <a
                                className="nav-link"
                                href="/become-a-member"
                                tabIndex="-1"
                                aria-disabled="true"
                            >
                                Become a Member
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="right_side" id="">
                    <ul>
                        <li className="border_nav hidden-xs hidden-sm">
                            <a href="/ama">AMA</a>
                        </li>
                        <li className="border_nav">
                            <a href="/">resource feed</a>
                        </li>
                        <li className="border_nav hidden-xs hidden-sm">
                            <a href="/become-a-member">Become a Member</a>
                        </li>
                        {userEmail ? (
                            <li className="login">
                                <a onClick={logout}>Logout</a>
                            </li>
                        ) : (
                            <li className="login">
                                <a href="/login" className="">
                                    Login
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    );
}

import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { API } from "aws-amplify";
import { updateMember } from "../../graphql/mutations";

export default function SkillModal({
    setModal,
    skillsData,
    setSkillsData,
    skillOptions,
    memberData,
}) {
    const [skills, setSkills] = useState();

    useEffect(() => {
        console.log("-------------");
        console.log(skillsData);
        console.log("-------------");
        setSkills(skillsData);
    }, []);

    const handleSkillSelect = async (e) => {
        let createSkillQuery = `#graphql
            mutation MyMutation($name: String) {
                createCommonDDL(input: {ddlType: Skills, name: $name}) {
                    id
                    name
                }
            }
        `;
        if (e.__isNew__) {
            let newSkill = await API.graphql({
                query: createSkillQuery,
                variables: {
                    name: e.label,
                },
            });
            newSkill = newSkill.data.createCommonDDL;
            if (newSkill) {
                setSkills(...skills, { value: newSkill.id, label: newSkill.name });
            }
        } else {
            setSkills(e);
        }
    };

    const save = async () => {
        let updateMemberDetails = await API.graphql({
            query: updateMember,
            variables: {
                input: {
                    id: memberData.id,
                    skills: JSON.stringify(skills),
                },
            },
        });

        setSkillsData(skills);

        setModal(false);
    };

    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Edit Skills
                        </h5>
                        <button
                            type="button"
                            onClick={() => setModal(false)}
                            classname="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="17" r="17" fill="#3B54DB" />
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(11 11)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="about_part">
                            <div className="about_intro mb-4">
                                <label for="profession">Select your skills</label>
                                <CreatableSelect
                                    isMulti
                                    options={skillOptions}
                                    value={skills}
                                    formatCreateLabel={(e) => `Create "${e}" as Skill`}
                                    onChange={(e) => handleSkillSelect(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" data-dismiss="modal"
                        aria-label="Close" className="btn btn-primary" onClick={save}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

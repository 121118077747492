export default function Banner({ title, subTitle }) {
    return (
        <div className="banner_bg col-xl-12 col-md-12 col-sm-12 col-xs-12">
            <div className="container-fluid">
                <div className="banner_div">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="">
                                <h4>Techy Intellects</h4>
                                <h2>A vibrant <span>network of professionals</span> working to grow our technology </h2>
                                <p>Making world better being a tec professionals can be a good cause. CTO forum focuses on doing virtuous things implementing technological leadership and ideas. </p>
                                <a href="/become-a-member">Become a Member</a>

                                <p className="xl-hidden">A journey of inventing future! Take a seat! </p>
                                
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="img">
                                <img src="images/1 1.png" className="img-fluid" alt="" />
                            </div>

                        </div>
                    </div>
                    <p className="ban_p_position xs-hidden">A journey of inventing future! Take a seat! </p>
                </div>
            </div>
        </div>
    );
}

import React from "react";

export default function AddQuestion() {
    return (
        <div className="col-md-6 left_text">
            <div className="overflow-hidden">
                <div className="multisteps-form">
                    <div className="row d-none">
                        <div className="col-12 col-lg-8 ml-auto mr-auto mb-4">
                            <div className="multisteps-form__progress">
                                <button
                                    className="multisteps-form__progress-btn js-active"
                                    type="button"
                                    title="User Info"
                                >
                                    Personal info
                                </button>
                                <button
                                    className="multisteps-form__progress-btn"
                                    type="button"
                                    title="Address"
                                >
                                    Education
                                </button>
                                <button
                                    className="multisteps-form__progress-btn"
                                    type="button"
                                    title="Order Info"
                                >
                                    Job Info
                                </button>
                                <button
                                    className="multisteps-form__progress-btn"
                                    type="button"
                                    title="Comments"
                                >
                                    Payment
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <form className="multisteps-form__form">
                                <div
                                    className="multisteps-form__panel shadow rounded bg-white js-active step_one"
                                    data-animation="scaleIn"
                                >
                                    <div className="multisteps-form__content">
                                        <div className="form-row">
                                            <div className="col-12">
                                                <textarea
                                                    className="form-control"
                                                    id="exampleFormControlTextarea1"
                                                    rows="4"
                                                    placeholder="Ask your question about blockchain..."
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="button-row text-center">
                                            <button
                                                className="btn btn-block js-btn-next"
                                                type="button"
                                                title="Next"
                                            >
                                                Submit Your Question
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="multisteps-form__panel shadow rounded bg-white"
                                    data-animation="scaleIn"
                                >
                                    <div className="multisteps-form__content">
                                        <div className="form-row">
                                            <div className="col-12 video_part">
                                                {/* <img src="images/text_img.png" className="img-fluid" alt=""> */}
                                                <video
                                                    height="352"
                                                    controls
                                                    preload="auto"
                                                    poster="images/text_img.png"
                                                    data-setup=""
                                                    loop
                                                >
                                                    <source
                                                        src="videos/97r8gk4kjshx4a3.mp4"
                                                        type="video/mp4"
                                                    />
                                                    <source
                                                        src="videos/97r8gk4kjshx4a3.mp4"
                                                        type="video/ogg"
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-row text-center mt-4">
                                        <button
                                            className="btn btn-block js-btn-prev"
                                            type="button"
                                            title="Prev"
                                        >
                                            Ask Question
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import Banner from "../banner/banner";
import Footer from "../footer/footer";
import Navbar from "../navbar/navbar";
import BlurNavbar from "../navbar/navbarBlur";

export default function Layout({showBanner = true, blurNavbar = false, children}) {
    return (
        <div>
            {blurNavbar ? <BlurNavbar /> : <Navbar />}
            {showBanner && <Banner />}
            {children}
            <Footer />
        </div>
    );
}

import React from 'react';
import Layout from '../../components/common/layout/layout';
import PhotoGalleryComponent from '../../components/gallery/PhotoGalleryComponent'

const PhotoGallery = () => {
    return (
       <Layout showBanner={false}>
           <PhotoGalleryComponent />
       </Layout>
    );
};

export default PhotoGallery;
import React from "react";
import Layout from "../../components/common/layout/layout";
import MemberRegistrationComponent from "../../components/member-registration/member-registration/memberRegistration";

export default function MemberRegistration() {
    return (
        <Layout showBanner={false}>
            <MemberRegistrationComponent />
        </Layout>
    );
}

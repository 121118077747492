import React, { useEffect, useState } from "react";
export default function SubMenu(props) {
    const [memberId, setMemberId] = useState(props.id);
    useEffect(async () => {}, []);

    const setMemberShipType = (type) => {
        localStorage.setItem("memberType", type);
    };

    console.log(props.memberships, "memberships");
    return (
        <React.Fragment>
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    {props.memberships &&
                        props.memberships.items.map((member, index) => {
                            if (member.id === props.id) {
                                return (
                                    <a
                                        class="nav-item nav-link active"
                                        id="nav-Student_Member-tab"
                                        data-toggle="tab"
                                        href={"#" + member.memberType}
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected="true"
                                    >
                                        {member.memberType}
                                    </a>
                                );
                            } else {
                                return (
                                    <a
                                        class="nav-item nav-link"
                                        id="nav-Associate_Member-tab"
                                        data-toggle="tab"
                                        href={"#" + member.memberType}
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected="false"
                                    >
                                        {member.memberType}
                                    </a>
                                );
                            }
                        })}
                </div>
            </nav>

            {/* <div className="col-md-4 col-sm-12 col-xs-12 py-3 left_side">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
               
                {props.memberships&&props.memberships.items.map((member, index)=>{
                        if(member.id === props.id){
                            return (
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="home-tab"
                                        data-toggle="tab"
                                        href={"#"+member.memberType}
                                        onClick={() => setMemberShipType(member.memberType)}
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> {member.memberType}
                                    </a>
                                </li>
                            )
                        }else{
                            return (<li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="profile-tab"
                                        data-toggle="tab"
                                        href={"#"+member.memberType}
                                        onClick={() => setMemberShipType(member.memberType)}
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                    >
                                        <i className="fa fa-angle-right" aria-hidden="true"></i> {member.memberType}
                                    </a>
                                </li>)
                        }
                })}
                

                
               


            </ul>
        </div> */}
        </React.Fragment>
    );
}

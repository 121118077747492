import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Slider from "react-slick";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ChallengesEvent from './ChallengesEvent';
// import 'bootstrap/dist/css/bootstrap.min.css';
import CountdownTimer from './CountdownTimer';
import EventSchedule2022 from './EventSchedule2022';
// import pdf from "./images/magazine/c1.jpg";


const photos = [
  {
    src: require("../../images/hack2021/12.png"),
  },
  {
    src: require("../../images/hack2021/13.png"),
  },
  {
    src: require("../../images/hack2021/14.png"),
  },
];



const InnovationHackathonComponent2022 = () => {

  const [showMore, setShowMore] = useState(false);
  const [show, setShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const [show2, setShow2] = useState(false);

  const [show3, setShow3] = useState(false);

  // count down timer
  // const THREE_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;
  // const THREE_DAYS_IN_MS = new Date(2022, 4, 1).getTime() - new Date().getTime();
  // const NOW_IN_MS = new Date().getTime();

  const CountDownYear = 2022;
  const CountDownMonth = 4;
  const CountDownDate = 24;

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];


  const dateTimeAfterThreeDays = new Date(CountDownYear, CountDownMonth -1, CountDownDate).getTime();
  // count down timer



  const ShowMoreFun = () => {
    setShowMore(true);
  }
  const Showless = () => {
    setShowMore(false);
  }


  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.72373 0L4.77588 2.94795L14.3279 12.5L4.77588 22.0521L7.72373 25L20.2237 12.5L7.72373 0Z" fill="#CCCCCC" />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2761 0L16.224 2.94795L6.67192 12.5L16.224 22.0521L13.2761 25L0.776123 12.5L13.2761 0Z" fill="#CCCCCC" />
          </svg>
        </div>
      </div>
    )
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.72373 0L4.77588 2.94795L14.3279 12.5L4.77588 22.0521L7.72373 25L20.2237 12.5L7.72373 0Z" fill="#CCCCCC" />
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2761 0L16.224 2.94795L6.67192 12.5L16.224 22.0521L13.2761 25L0.776123 12.5L13.2761 0Z" fill="#CCCCCC" />
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
        }
      }
    ]

  };

  return (
    <div className="InnovationHackathonRasel">
      <div className="banner_slider">
        <div className="container-fluid">
          <div className="col-md-12">
            <h2 className="heading text-center mentor--heading">
              CTO Forum Innovation Hackathon 2022
            </h2>
            {/* <p>Orci suscipit sit urna egestas orci. Tincidunt blandit sapien, sit sed egestas nulla viverra vel. Ac id metus tristique egestas elementum tellus. Commodo ac tellus parturient nisl sagittis.</p> */}
          </div>
          <div className="banner_slider_item">
            <Slider {...settings}>
              <div>
                <img src="../img/1.jpg" alt="" />
              </div>
              <div>
                <img src="../img/2.jpg" alt="" />
              </div>
              <div>
                <img src="../img/3.jpg" alt="" />
              </div>
              <div>
                <img src="../img/4.jpg" alt="" />
              </div>
              <div>
                <img src="../img/5.jpg" alt="" />
              </div>
            </Slider>
          </div>

          <div className="col-md-12 btn_part">
            <button className='gallery' >
              <a href="/Innovation-Hackathon-Gallery-2022" target="blank">
                See Gallery
              </a>
            </button>
            <button className='apply'>
              {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSd-5Z08UaYi0ZU39DXJ9SYlmyEYOo4u632EcbyCoeTWIr7OVA/viewform?pli=1" target="_blank" rel="noreferrer">
                Apply Now
              </a> */}
              <a href="http://portal.ctoforumbd.org/" target="_blank" title='Apply Now' rel="noreferrer">
                Apply Now
              </a>
            </button>
          </div>
        </div>
      </div>
      {/* <section id="banner_section_Innovation">

  </section> */}

  <div className="hackathon_countdown">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h2>Our Next Hack-a-Thon will Announced Soon</h2>
          <p>10 Mar 2022 - {CountDownDate} {month[CountDownMonth -1]} {CountDownYear}</p>

          <CountdownTimer targetDate={dateTimeAfterThreeDays} />
        </div>
      </div>
    </div>
  </div>


      <div className="rulse_part section-pading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Rules of Hack-a-Thon</h2>
              {/* <p>Risus non at tempus, eget dolor lorem viverra justo. Nunc tellus bibendum <br /> ridiculus tortor vel nunc mattis.</p> */}
            </div>
            <div className="col-md-4">
              <ul>
                <li>The minimum team size is 1 member and maximum team size is 3 members. </li>
                <li>Team must participate in all the rounds if selected.</li>
                <li>Team can use any diagram tool like draw.io or visio or even PowerPoint wherever needed</li>
                <li>All code used must be placed in GitHub or referenced otherwise.</li>
                
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>Anything in GitHub must be incorporated into the video presentation. </li>
                <li>Presentation/Project Demo cannot exceed 4 minutes, anything after the 240 seconds marker will not be evaluated. </li>
                <li>Teams are allowed to use a reference Cloud Formations template to create their services but have to document the source and reference.</li>
                <li>Use English language on the project page (demonstrations can be in other languages but must include English language subtitles).</li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
              <li>Make sure that the project does not contain profanity or inappropriate language.</li>
                <li>Ensure Zero percentage of plagiarism or copy of content of others which violates copyrights or IPR and must cite all sources properly</li>
                <li>Any member of the team can not use any devices prior to providing the information about that to the authority. The Hackathon authority reserves the full rights to ban a team in case of any anomalies. </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="awards-section" id="awards">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h5 className="caption text-center">awards</h5>
              <h2 className="heading text-center">awards plan</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="custom_award_item">
                <div className="award">
                  <img src="./images/award.png" alt="" />
                </div>
                <div className="price">
                  <p>100000 ৳</p>
                </div>
                <div className="desc">
                  {/* <p>Iaculis aliquam, at odio in sed aliquam tortor. Pharetra tempus fringilla.</p> */}
                </div>
                <award_bg className="award_bg">
                  Winner
                </award_bg>
                <button onClick={() => setShow(true)}>
                  Details
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom_award_item">
                <div className="award">
                  <img src="./images/award.png" alt="" />
                </div>
                <div className="price">
                  <p>75000 ৳</p>
                </div>
                <div className="desc">
                  {/* <p>Iaculis aliquam, at odio in sed aliquam tortor. Pharetra tempus fringilla.</p> */}
                </div>
                <award_bg className="award_bg">
                  1st Runner up
                </award_bg>
                <button onClick={() => setShow1(true)}>
                  Details
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom_award_item">
                <div className="award">
                  <img src="./images/award.png" alt="" />
                </div>
                <div className="price">
                  <p>50000 ৳</p>
                </div>
                <div className="desc">
                  {/* <p>Iaculis aliquam, at odio in sed aliquam tortor. Pharetra tempus fringilla.</p> */}
                </div>
                <award_bg className="award_bg">
                  2nd Runner up
                </award_bg>
                <button onClick={() => setShow2(true)}>
                  Details
                </button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="custom_award_item">
                <div className="award">
                  <img src="./images/award.png" alt="" />
                </div>
                <div className="price">
                  <p>6+ Computer Gadget</p>
                </div>
                <div className="desc">
                  {/* <p>Iaculis aliquam, at odio in sed aliquam tortor. Pharetra tempus fringilla.</p> */}
                </div>
                <award_bg className="award_bg">
                  Special Award
                </award_bg>
                <button onClick={() => setShow3(true)}>
                  Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="payment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5">
              <img src="./images/Bkash.png" alt="" />
            </div>
            <div className="col-md-5">
              <h2>Hackathon Registration Fee and Process </h2>
              {/* <p>Aliquam mi vestibulum viverra quis elit dictum sit viverra at. Diam nec ultrices ultrices viverra porttitor semper. Aenean arcu lacus etiam vitae. Gravida metus nunc neque commodo.</p> */}
              <ul>
                <li>Registration Fee 1000TK per Team</li>
                <li>BKash No 01844143114 (Personal) </li>
                <li>Do the Send Money 1000tk, and Save the transaction ID</li>
                <li>Go to Registration Form and submit your transaction ID in appropriate Field. </li>
                <li>Reference: “Team Name”</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="round_process">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Rounds and Proccess</h2>
            </div>
            <div className="col-md-3">
              <span>01</span>
              <h3>Idea</h3>
              {/* <p>Iaculis aliquam, at odio in sed aliquam <br /> tortor. Pharetra tempus fringilla.</p> */}
            </div>
            <div className="col-md-3">
              <span>02</span>
              <h3>Prototype</h3>
              {/* <p>Iaculis aliquam, at odio in sed aliquam <br /> tortor. Pharetra tempus fringilla.</p> */}
            </div>
            <div className="col-md-3">
              <span>03</span>
              <h3>Demonstration</h3>
              {/* <p>Iaculis aliquam, at odio in sed aliquam <br /> tortor. Pharetra tempus fringilla.</p> */}
            </div>
            <div className="col-md-3">
              <span>04</span>
              <h3>Gala</h3>
              {/* <p>Iaculis aliquam, at odio in sed aliquam <br /> tortor. Pharetra tempus fringilla.</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="latest_notice section-pading">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Latest Notice</h2>
              <Table hover size="sm">
                <thead>
                  <tr>
                    <th>Sl</th>
                    <th>Name </th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Contestant's GUIDE</td>
                    <td>13-08-2022</td>
                    <td>
                      <a href="images/pdf/Contestant's_GUIDE .pdf" target="_blank" rel="noreferrer">View</a>
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* <a className="notice_all_button" href="#" target="_blank">View All</a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="reg_chk">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Who can Participate </h2>
              <p>The Hack-a-thon  is only open to legal residents of Bangladesh. Organizers’ employees and their immediate families (i.e. their spouses, siblings, children, parents, spouses’ parents and the spouses of any of those individuals) and their household members (related or not) are not eligible to be a participant in the Hack-a-thon.How to Participate</p>
            </div>
            <div className="col-md-4">
              <ul>
                <li>Select a challenge from the provided Challenges list <strong>[Challenges List is available in the section below ]</strong></li>
                <li>Decide if you are going to participate in this Hack-a-thon  as a solo contestant or Team. A Team can have a maximum of Three members. There are no restrictions on the choice of your team member as long as your team member/s are legal residents of Bangladesh</li>
                <li>Go to this Registration Link and fill out the form before the Registration deadline.</li>
                
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                  <li><strong>The Services being planned to use -</strong> What third party services are being planned to use and why.</li>
                  <li><strong>Architectural Diagram -</strong> Outline of solution architecture [Block Diagram, Use Case, BPM etc]</li>
                  <li><strong>Business Benefit -</strong> How this solution will impact the business.</li>
                  <li><strong>Challenges -</strong> What are the hurdles to overcome for the success</li>
                </ul>
            </div>
            <div className="col-md-4">
              <div className="reg_amount">
                <div className="fees">Admission Fees</div>
                <div className="amount">1000 BDT</div>
               
                <button>
                  <a href="http://portal.ctoforumbd.org/" title='Apply Now' target="_blank">Apply Now</a></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EventSchedule2022 />
      <section className="conference-section section-pading">
        <div className="conference">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="conference__txt">
                  <h5 className="caption">About Event</h5>
                  <h2 className="heading">CTO Forum Innovation Hackathon 2022</h2>
                  <p>
                    CTO forum Innovation Hackathon aims to connect talented youth tech professionals, students, entrepreneurs, leaders, and innovators with the top industry professionals in the rising fields of new technologies.Registration starting on 1 st August 2022, the event will be on the wheel on 4th September to 2nd October.Explore the tech world and put yourself in solving problems and making innovative technologies!

                    {showMore == false && <span className="read-more show-more-btn" onClick={ShowMoreFun} > ...Read More </span>}

                    {showMore == true ?
                      <p>  This hackathon is jointly collaborated by CTO Forum, Access to Information, Bangladesh Govt.and Bangladesh Hi - tech Park Authority.
                        This Hackathon throws 6 challenges consist of many sub - challenges among which Virtual Assistant, Manual and automation process, Serverless emerging and innovative technology, Zero Hunger, Good Health and Well - being, Quality Education would be the main challenges.
                        Having three rounds(idea, Prototype and Final) in process, the event will face completion awarding the best ideas and solutions.
                        You may participate in the event solo or with a team.A team can be consisted of maximum 3 members.Registration fee is 500 / - for every entry.There are no restrictions on the choice of your team member as long as your team members are legal residents of Bangladesh.
                        The winners will be awarded with attractive cash prizes, recognitions, and job opportunities.
                        Go to this Registration Link shorturl.at / guPW7 and fill out the form before the Registration ends.

                        <span className="read-more show-more-btn" onClick={Showless} > Show less </span>
                      </p>

                      : ""}
                  </p>
                  <div className="event-loc__bttp">
                    {/* <a className="combtn" href="https://docs.google.com/forms/d/e/1FAIpQLSd-5Z08UaYi0ZU39DXJ9SYlmyEYOo4u632EcbyCoeTWIr7OVA/viewform?pli=1" target="_blank" rel="noreferrer"> */}
                    <a className="combtn" href="http://portal.ctoforumbd.org/" title='Register Now' target="_blank" rel="noreferrer">
                      Register Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="conference__media">
                  <img className="img-responsive" src="/images/poster.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="member_tab">
        <div className="container-fluid">
          <div className="col-md-12">
            {/* <h2 className="heading text-center mentor--heading">
              Our Member
            </h2> */}
          </div>

          <Tabs>
            <TabList>
              <Tab>GUEST</Tab>
              <Tab>EXPERT</Tab>
              <Tab>JUDGES</Tab>
              <Tab>MENTOR</Tab>
              <Tab>ORGANIZING TEAM</Tab>
            </TabList>

            <TabPanel>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/img/mentors/junayed.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Chief Guest</span>
                        <h4 className="mentor__name"> Zunaid Ahmed Palak, MP</h4>
                        <h5 className="mentor__desi">Honorable State Minister <br /> ICT Division,  <br /> Ministry of Posts, Telecommunications and IT</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Tapan_Kanti_Sarkar.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Tapan Kanti Sarkar</h4>
                        <h5 className="mentor__desi">President <br /> CTO Forum Bangladesh</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/zeaul alam sir.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Guest of Honor</span>
                        <h4 className="mentor__name">N M Zeaul Alam PAA</h4>
                        <h5 className="mentor__desi">Senior Secretary <br />ICT Division</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Shyam Sunder Sikder.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Shyam Sunder Sikder</h4>
                        <h5 className="mentor__desi">Chairman, BTRC</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt ">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Bilkarna Kumar Ghosh.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Dr. Bikarna Kumar Ghosh</h4>
                        <h5 className="mentor__desi">Managing Director  <br /> Bangladesh Hi-Tech Park Authority</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Abdul Mannan PAA.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Dr. Md Abdul Mannan (PAA)</h4>
                        <h5 className="mentor__desi">Additional Secretary and <br />Project Director , a2i</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/youki 1.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Youki Kadobayashi (Ph.D.)</h4>
                        <h5 className="mentor__desi">Professor, NAIST, Japan</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Ruhul Alam Al Mahbub.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Special Guest</span>
                        <h4 className="mentor__name">Md. Ruhul Alam Al Mahbub</h4>
                        <h5 className="mentor__desi">Chairman, Fair Group </h5>
                      </div>
                    </div>
                  </div>



                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Syed Akhter Hossain.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Convener</span>
                        <h4 className="mentor__name">Dr. Syed Akhter Hossain</h4>
                        <h5 className="mentor__desi">Professor &am; Head, Dept. of CSE  <br /> University of Liberal Arts Bangladesh </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Arfe Elahi Manik.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span className="blank_span">.</span>
                        <h4 className="mentor__name">Arfe Elahi Manik</h4>
                        <h5 className="mentor__desi">Chief Technology Officer  <br /> a2i</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="container-fluid">
                <div className="row">
                  {/* part - 1 */}
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/img/mentors/Anir Chowdhury.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name"> Anir Chowdhury</h4>
                        <h5 className="mentor__desi">Policy Advisor of the a2i Programme <br /> A2i</h5>
                      </div>
                    </div>
                  </div>
                  {/* part - 2 */}
                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dipak Chakraborty.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Dipak Chakraborty</h4>
                        <h5 className="mentor__desi">CTO, Krishi Bank </h5>
                      </div>
                    </div>
                  </div>

                  {/* Part - 3 */}
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/img/mentors/Sohel Rahman.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Dr. M. Sohel Rahman</h4>
                        <h5 className="mentor__desi">Professor, BUET</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 4 */}
                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Faruq Ahmed Juwel.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Faruq Ahmed Juwel </h4>
                        <h5 className="mentor__desi">(Automation of Manual Processes) i-lab</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 5 */}
                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Md. Afzal Hossain Sarwar.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Md. Afzal Hossain Sarwar</h4>
                        <h5 className="mentor__desi">Policy Specialist (Educational Innovation) <br /> A2i</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 6 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof. Khondaker A. Mamun.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof. Khondaker A. Mamun</h4>
                        <h5 className="mentor__desi">Professor &amp; Director – AIMS Lab UIU</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 7 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Saifuddin Md.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Dr. Saifuddin Md. Tareeq</h4>
                        <h5 className="mentor__desi">Professor & Chairman <br /> Dept. CSE DU</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 8 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof. Mohammad Shorif Uddin.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof. Mohammad Shorif Uddin</h4>
                        <h5 className="mentor__desi">Professor JU</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 9 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Md. Mahbubul Alam Joarder.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Md. Mahbubul Alam Joarder</h4>
                        <h5 className="mentor__desi">Professor DU (IIT)</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 10 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof. Dr. Md. Abdur Razzaque.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof. Dr. Md. Abdur Razzaque</h4>
                        <h5 className="mentor__desi">Pro Vice Chancellor Green University</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 11 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof. M Omar Rahman.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof. M Omar Rahman</h4>
                        <h5 className="mentor__desi">Professor of Public Health & Demography ULAB</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 12 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof.  Dr. Sazzad Hossain.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof.  Dr. Sazzad Hossain</h4>
                        <h5 className="mentor__desi">Member, IMCT, Research Support & <br /> Publications Division UGC</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 13 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dipak Chakraborty.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Dr. Carmen Z. Lamagna </h4>
                        <h5 className="mentor__desi">VC AIUB</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 14 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Prof. Fayyaz Khan.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Expert</span>
                        <h4 className="mentor__name">Prof. Faiaz Khan</h4>
                        <h5 className="mentor__desi">VC BUBT</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="container-fluid">
                <div className="row">
                  {/* part - 1 */}
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/img/mentors/Professor-Dr.-M-Kaykobad.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name"> Dr. Mohammad Kaykobad</h4>
                        <h5 className="mentor__desi">Distinguished Professor <br /> Department of Computer Science and <br /> Engineering Brac University</h5>
                      </div>
                    </div>
                  </div>
                  {/* part - 2 */}
                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Touhid-Bhuiyan.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Professor Dr. Touhid Bhuiyan</h4>
                        <h5 className="mentor__desi">Professor & Head  <br />
                          Department of CSE <br />
                          Daffodil International University</h5>
                      </div>
                    </div>
                  </div>

                  {/* Part - 3 */}
                  {/* <div className="col-md-4">
            <div className="mentor__item ">
              <div className="mentor__img">
                <img src="/img/mentors/empty.png" />
                <div className="metor__overlay"></div>
                <ul className="mentor__socila-ac">
                  <li>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="mentor__txt">
                <span>JUDGE</span>
                <h4 className="mentor__name">Dr. Fizar Ahmed</h4>
                <h5 className="mentor__desi">Assistant Professor <br />
  Daffodil International University
  </h5>
              </div>
            </div>
          </div> */}

                  {/* part - 4 */}
                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Shamim Kaiser.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. M  Shamim Kaiser  </h4>
                        <h5 className="mentor__desi">Professor
                          Jahangirnagar University</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 5 */}
                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Mohammad Abu Yousuf.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Mohammad Abu Yousuf  </h4>
                        <h5 className="mentor__desi">Professor
                          Institute of Information Technology <br />
                          Jahangirnagar University
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 6 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Abul Kalam Al Azad.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Abul Kalam Al Azad</h4>
                        <h5 className="mentor__desi">Associate Professor ULAB
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 7 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Muhammad Golam Kibria.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Muhammad Golam Kibria</h4>
                        <h5 className="mentor__desi">Associate Professor
                          ULAB
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 8 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Saddam Hossain.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Saddam Hossain</h4>
                        <h5 className="mentor__desi">Assistant Professor
                          UIU
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 9 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Sheak Rashed Haider Noori.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Sheak Rashed Haider Noori</h4>
                        <h5 className="mentor__desi">Associate Professor and Associate Head <br /> Daffodil International University</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 10 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Zaman_vai.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Mahdee-Uz-Zaman</h4>
                        <h5 className="mentor__desi">Leader, Solution Architecture <br />
                          Amazon Web Service
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 11 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Mr. Abdur Sattar.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Mr. Abdus Sattar</h4>
                        <h5 className="mentor__desi">Assistant Professor <br />
                          Daffodil International University
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 12 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Fahad Faisal.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Mr. Fahad Faisal</h4>
                        <h5 className="mentor__desi">Assistant Professor  <br />
                          Daffodil International University
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 13 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Mr. Rezwanul Haque Jami.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Mr. Rezwanul Haque Jami</h4>
                        <h5 className="mentor__desi">Team Lead, Rural eCommerce & <br /> Head of Commercialization
                          i-Lab
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 14 */}
                  {/* <div className="col-md-4">
            <div className="mentor__item  mentor__item--mt">
              <div className="mentor__img">
                <img src="/img/mentors/Dr. Dip Nandi.jpg" />
                <div className="metor__overlay"></div>
                <ul className="mentor__socila-ac">
                  <li>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="mentor__txt">
                <span>JUDGE</span>
                <h4 className="mentor__name">Dr. Dip Nandi</h4>
                <h5 className="mentor__desi">Associate Professor and Director, <br />
  Faculty of Science and Technology <br />
  American International University-Bangladesh
  </h5>
              </div>
            </div>
          </div> */}

                  {/* part - 15 */}
                  {/* <div className="col-md-4">
            <div className="mentor__item  mentor__item--mt">
              <div className="mentor__img">
                <img src="/img/mentors/empty.png" />
                <div className="metor__overlay"></div>
                <ul className="mentor__socila-ac">
                  <li>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="mentor__txt">
                <span>JUDGE</span>
                <h4 className="mentor__name">Rabiul Alam Chowdhury</h4>
                <h5 className="mentor__desi">Head of IT <br />
  Plan International Bangladesh
  </h5>
              </div>
            </div>
          </div> */}

                  {/* part - 16 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Bilkarna Kumar Ghosh.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Bikarna Kumar Ghosh</h4>
                        <h5 className="mentor__desi">Managing Director BHTPA
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 17 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Farhana Sarkar_ Judge.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Farhana Sarker</h4>
                        <h5 className="mentor__desi">Assistant Professor
                          ULAB
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 18 */}
                  {/* <div className="col-md-4">
            <div className="mentor__item  mentor__item--mt">
              <div className="mentor__img">
                <img src="/img/mentors/empty.png" />
                <div className="metor__overlay"></div>
                <ul className="mentor__socila-ac">
                  <li>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="mentor__txt">
                <span>JUDGE</span>
                <h4 className="mentor__name">Prof. Yusuf Mahbubul Islam</h4>
                <h5 className="mentor__desi">Dean
  IUB
  </h5>
              </div>
            </div>
          </div> */}

                  {/* part - 19 */}
                  {/* <div className="col-md-4">
            <div className="mentor__item  mentor__item--mt">
              <div className="mentor__img">
                <img src="/img/mentors/empty.png" />
                <div className="metor__overlay"></div>
                <ul className="mentor__socila-ac">
                  <li>
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>
              <div className="mentor__txt">
                <span>JUDGE</span>
                <h4 className="mentor__name">Prof. Dr. H M Jahirul Haque</h4>
                <h5 className="mentor__desi">Senior Advisor to the Board of <br />	 Trustees of CUB</h5>
              </div>
            </div>
          </div> */}

                  {/* part - 20 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Nabeel Mohammed.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Dr. Nabeel Mohammed</h4>
                        <h5 className="mentor__desi"> Associate Professor <br /> North South University </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 21 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Sharmin Akter.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Sharmin Akter</h4>
                        <h5 className="mentor__desi">Lecturer <br /> Daffodil International University</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 22 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Sheikh Abujar.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>JUDGE</span>
                        <h4 className="mentor__name">Sheikh Abujar</h4>
                        <h5 className="mentor__desi">Lecturer <br /> Independent University Bangladesh</h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="container-fluid">
                <div className="row">
                  {/* part - 1 */}
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Dip Nandi.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Dr. Dip Nandi</h4>
                        <h5 className="mentor__desi">Associate Professor and Director, <br />
                          Faculty of Science and Technology <br />
                          American International University-Bangladesh
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* part - 2 */}
                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Md. Tanvir Quader.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Md. Tanvir Quader</h4>
                        <h5 className="mentor__desi">Senior Software Engineer, <br />
                          A2i
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* Part - 3 */}
                  {/* <div className="col-md-4">
              <div className="mentor__item ">
                <div className="mentor__img">
                  <img src="/img/mentors/Mijanur Rahman.jpg" />
                  <div className="metor__overlay"></div>
                  <ul className="mentor__socila-ac">
                    <li>
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </li>
                  </ul>
                </div>
                <div className="mentor__txt">
                <span>Mentor</span>
                  <h4 className="mentor__name"> Mijanur Rahman</h4>
                  <h5 className="mentor__desi">Founder, CEO <br /> Doodle Inc.</h5>
                </div>
              </div>
            </div> */}

                  {/* part - 4 */}
                  <div className="col-md-4">
                    <div className="mentor__item">
                      <div className="mentor__img">
                        <img src="/img/mentors/Nahid Iftekhar.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Nahid Iftekhar</h4>
                        <h5 className="mentor__desi">Chief Business Officer <br />
                          CodeMarshal IT Systems Ltd
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 5 */}
                  <div className="col-md-4">
                    <div className="mentor__item mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Mohammad Asif.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Mohammed Asif</h4>
                        <h5 className="mentor__desi">EC Member <br />
                          CTO Forum Bangladesh
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 6 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Iftekhar Ishaque.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Iftekhar Ishaque</h4>
                        <h5 className="mentor__desi">Deputy Chief Technology Officer <br />
                          SSL Wireless
                        </h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 7 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/Dr. Ananya Raihan.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Dr. Ananya Raihan</h4>
                        <h5 className="mentor__desi">CEO D.net</h5>
                      </div>
                    </div>
                  </div>

                  {/* part - 8 */}
                  <div className="col-md-4">
                    <div className="mentor__item  mentor__item--mt">
                      <div className="mentor__img">
                        <img src="/img/mentors/J M Taslim Kabir.jpg" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> J M Taslim Kabir</h4>
                        <h5 className="mentor__desi">Head Of Marketing <br /> Fair Group</h5>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="container-fluid">
                <div className="row">
                  {/* part - 1 */}
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/images/Dummy-Person.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Tommy B. Merrill</h4>
                        <h5 className="mentor__desi">Lorem ipsum dolor sit amet, <br />
                          Lorem ipsum dolor sit amet <br />
                          Lorem ipsum dolor sit amet
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/images/Dummy-Person.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Tommy B. Merrill</h4>
                        <h5 className="mentor__desi">Lorem ipsum dolor sit amet, <br />
                          Lorem ipsum dolor sit amet <br />
                          Lorem ipsum dolor sit amet
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mentor__item ">
                      <div className="mentor__img">
                        <img src="/images/Dummy-Person.png" />
                        <div className="metor__overlay"></div>
                        <ul className="mentor__socila-ac">
                          <li>
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </li>
                          <li>
                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                          </li>
                        </ul>
                      </div>
                      <div className="mentor__txt">
                        <span>Mentor</span>
                        <h4 className="mentor__name"> Tommy B. Merrill</h4>
                        <h5 className="mentor__desi">Lorem ipsum dolor sit amet, <br />
                          Lorem ipsum dolor sit amet <br />
                          Lorem ipsum dolor sit amet
                        </h5>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>


      <ChallengesEvent />

      <div className="Participants">
        <div className="container-fluid">
          <div className="col-md-12">
            <h2 className="heading text-center mentor--heading">
              Participants
            </h2>
          </div>

          <div className="fainalist_slieder_item">
            <Slider {...settings2}>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/ALL TEAM FOR Final Round.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH104.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH106.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH108.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH203.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH304.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH104.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH308.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH401.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH404.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH408.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH409.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH501.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH502.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH503.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH505.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH506.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH514.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH516.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH522.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH602.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH607.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH611.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CH614.png" />
                </div>
              </div>
              <div className="finalist_part">
                <div className="finalist__img">
                  <img src="../img/Team Banner for Final Round/CHX04.png" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>


      <div className="Participants">
        <div className="container-fluid">
          <div className="col-md-12">
            <h2 className="heading text-center mentor--heading">
              Winners
            </h2>
          </div>

          <div className="fainalist_slieder_item">
          <Slider {...settings2}>
              {photos.map((photo) => (
                <div className="finalist_part">
                  <div className="finalist__img">
                    <img src={photo.src.default} alt="" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="ceremony-section ">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="col-md-12 col-sm-12">
                <div className="ceremony-left">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="ceremony_txt">
                        <h3 className="ceremony-heading">Inaguration Round</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>

                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Date: 4th September, 2022
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  For Virtual Join: Zoom ID – 225 557 6605 Pass Code – 11 22 33
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Title Partner: Fair Technology | Hyundai
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Where: Dhaka, Bangladesh
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Organizing Partners: a2i &amp; Bangladesh Hi-Tech Park Authority
                                </span>
                              </li>

                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Sponsors &amp; Media Partners: Brain Station 23, Tappware, Orangebd, Dhaka COLO, Doodle Inc, Soft BD ltd
                                </span>
                              </li>

                            </ul>
                          </div>
                          {/* <div class="col-md-12">
                        <p class="text-left">Euismod quis vel nunc, pellentesque convallis. Tellus turpis aliquam
                          fringilla massa pretium. Nisl id tristique fusce donec sed aliquam. </p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="row ceremony_img_row">
                        <div className="col-md-12 text-center ceremony_img_item">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="img">
                                <img src="/img/homepagereg.png" alt="homepagereg" />
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 2.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                            <span className="cheif_guest">CHEIF GUEST</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 3.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 4.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 5.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="ceremony-right">
                  {/* <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="ceremony_txt">
                    <h3 className="ceremony-heading">Closing ceremony</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Faucibus sed diam aliquam sit leo gravida at cum eget. Egestas.
                            </span>
                          </li>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Mattis et rutrum in porta sodales aenean ut accumsan. Mauris.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Dui semper interdum eleifend consectetur diam in urna libero nulla.
                            </span>
                          </li>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Sit hendrerit viverra non facilisis eleifend vulputate dignissim.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12">
                        <p class="text-left">Aliquet consequat nec pharetra nullam dui nisl pharetra risus, neque.
                          Lobortis massa in dictum gravida cras tempor. Turpis mauris platea et. </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row ceremony_img_row">
                    <div className="col-md-12 d-flex text-center ceremony_img_item">
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 6.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>Tofayel Ahmed Chowdhury</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                        <span className="cheif_guest">CHEIF GUEST</span>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 7.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 8.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 9.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="ceremony-section gala">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="col-md-12 col-sm-12">
                <div className="ceremony-left">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="ceremony_txt">
                        <h3 className="ceremony-heading">Gala Round</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <ul>
                              <li>

                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Date: 4th September, 2022
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  For Virtual Join: Zoom ID – 225 557 6605 Pass Code – 11 22 33
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Title Partner: Fair Technology | Hyundai
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Where: Dhaka, Bangladesh
                                </span>
                              </li>
                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Organizing Partners: a2i & Bangladesh Hi-Tech Park Authority
                                </span>
                              </li>

                              <li>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                                  <g clip-path="url(#clip0)">
                                    <path
                                      d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                      fill="black" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0">
                                      <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>
                                  Sponsors & Media Partners: Brain Station 23, Tappware, Orangebd, Dhaka COLO, Doodle Inc, Soft BD ltd
                                </span>
                              </li>

                            </ul>
                          </div>
                          {/* <div class="col-md-12">
                        <p class="text-left">Euismod quis vel nunc, pellentesque convallis. Tellus turpis aliquam
                          fringilla massa pretium. Nisl id tristique fusce donec sed aliquam. </p>
                      </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <div className="row ceremony_img_row">
                        <div className="col-md-12 text-center ceremony_img_item">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="img">
                                <img src="/img/homepagereg.png" alt="homepagereg" />
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 2.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                            <span className="cheif_guest">CHEIF GUEST</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 3.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 4.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="ceremony_img">
                            <img src="/img/ceremony/Rectangle 5.png" className="img-fluid" alt="" />
                            <div className="ceremony_img_title">
                              <h2>John Smith</h2>
                              <p><span>CBDO</span> <br /> Doodle Inc.</p>
                            </div>
                          </div>
                        </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="ceremony-right">
                  {/* <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="ceremony_txt">
                    <h3 className="ceremony-heading">Closing ceremony</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Faucibus sed diam aliquam sit leo gravida at cum eget. Egestas.
                            </span>
                          </li>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Mattis et rutrum in porta sodales aenean ut accumsan. Mauris.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Dui semper interdum eleifend consectetur diam in urna libero nulla.
                            </span>
                          </li>
                          <li>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="11" cy="11" r="10" fill="#F7F7F7" stroke="black" stroke-width="2" />
                              <g clip-path="url(#clip0)">
                                <path
                                  d="M9.86665 14.6162C9.77425 14.7091 9.64818 14.761 9.51723 14.761C9.38627 14.761 9.2602 14.7091 9.1678 14.6162L6.37721 11.8251C6.0876 11.5355 6.0876 11.0659 6.37721 10.7768L6.72663 10.4273C7.01632 10.1377 7.48539 10.1377 7.77499 10.4273L9.51723 12.1696L14.225 7.46179C14.5147 7.17218 14.9842 7.17218 15.2734 7.46179L15.6228 7.8113C15.9124 8.1009 15.9124 8.57042 15.6228 8.85957L9.86665 14.6162Z"
                                  fill="black" />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width="9.68" height="9.68" fill="white" transform="translate(6.16 6.16)" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>
                              Sit hendrerit viverra non facilisis eleifend vulputate dignissim.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12">
                        <p class="text-left">Aliquet consequat nec pharetra nullam dui nisl pharetra risus, neque.
                          Lobortis massa in dictum gravida cras tempor. Turpis mauris platea et. </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row ceremony_img_row">
                    <div className="col-md-12 d-flex text-center ceremony_img_item">
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 6.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>Tofayel Ahmed Chowdhury</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                        <span className="cheif_guest">CHEIF GUEST</span>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 7.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 8.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                      <div className="ceremony_img">
                        <img src="/img/ceremony/Rectangle 9.png" className="img-fluid" alt="" />
                        <div className="ceremony_img_title">
                          <h2>John Smith</h2>
                          <p><span>CBDO</span> <br /> Doodle Inc.</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <section className="conference-section section-pading about-event-section">
        <div className="conference">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="about-event-play">
                  <div className="vedio-box">
                    <a data-lity data-lity-desc="Photo of a flower" href="https://youtu.be/EcYmLfrXVh0" target="_blank" rel="noreferrer">
                      <img src="vedio/aboutevent.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="conference__txt">

                  <h5 className="caption">Video</h5>
                  <h2 className="heading">CTO Forum Innovation Hackathon 2022</h2>
                  <p>
                    CTO forum Innovation Hackathon aims to connect talented youth tech professionals, students, entrepreneurs, leaders, and innovators with the top industry professionals in the rising fields of new technologies.Registration starting on 1 st August 2022, the event will be on the wheel on 4th September to 2nd October.Explore the tech world and put yourself in solving problems and making innovative technologies!
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div className="partner-section section-pading" id="partners">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h5 className="caption text-center">partners</h5>
              <h2 className="heading text-center white-color">
                Organizing Partnar
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/a2i-logo-5D16E2F1C3-seeklogo.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/bangladesh-hi-tech-park-authority-logo-A544921534-seeklogo.png" className="img-fluid hitech_park" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="partner-footer">
                <p>
                  Don’t hesitate, contact us for better help and services. <a href="">View
                    All Partners.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-section section-pading Title_Sponsor_padding" id="partners">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h5 className="caption text-center">partners</h5>
              <h2 className="heading text-center white-color">
                Media Partnar
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/71 tv bangladesh.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-6 col-md-6">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/Bhorer-Kagoj-logo-1.png" className="img-fluid vorer_kagoj" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="partner-footer">
                <p>
                  Don’t hesitate, contact us for better help and services. <a href="">View
                    All Partners.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-section section-pading Title_Sponsor_padding" id="partners">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* <h5 className="caption text-center">partners</h5> */}
              <h2 className="heading text-center white-color">
                Title Sponsor
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-6 m-auto">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/Hundai.png" className="img-fluid" />
              </div>
            </div>
            {/* <div className="col-6 col-md-3">
          <div className="partner-item">
            <div className="overlay-partner"></div>
            <img src="/img/partner/partner2.png" className="img-fluid" />
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="partner-item">
            <div className="overlay-partner"></div>
            <img src="/img/partner/partner3.png" className="img-fluid" />
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="partner-item">
            <div className="overlay-partner"></div>
            <img src="/img/partner/partner4.png" className="img-fluid" />
          </div>
        </div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="partner-footer">
                <p>
                  Don’t hesitate, contact us for better help and services. <a href="">View
                    All Partners.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-section section-pading Title_Sponsor_padding" id="partners">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* <h5 className="caption text-center">partners</h5> */}
              <h2 className="heading text-center white-color">
                Sponsor
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-md-4 m-auto">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/BS-Logo-Blue_Hr.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/tapp_logo.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/Orangebd.png" className="img-fluid hitech_park" />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/Dhaka COLO.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/logo black 1.png" className="img-fluid hitech_park" />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="partner-item">
                <div className="overlay-partner"></div>
                <img src="/img/partner/Softbd Logo 1.png" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="partner-footer">
                <p>
                  Don’t hesitate, contact us for better help and services. <a href="">View
                    All Partners.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

     


      <Modal className='award' show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Button variant="secondary" onClick={() => setShow(false)}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17" cy="17" r="17" fill="#3B54DB"/>
              <g clip-path="url(#clip0_3085_2531)">
              <path d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_3085_2531">
              <rect width="12" height="12" fill="white" transform="translate(11 11)"/>
              </clipPath>
              </defs>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <img src="./images/champion_trofy.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="details">
                <h2>Winner</h2>
                <span className="price">
                100000 ৳
                </span>
                <p>Aliquam mi vestibulum viverra quis elit dictum sit viverra at. Diam nec ultrices ultrices viverra porttitor semper. Aenean arcu lacus etiam vitae. Gravida metus nunc neque commodo.</p>
                <ul>
                  <li>You will receive BDT 1,0000</li>
                  <li>Free Bag</li>
                  <li>A Laptop with software</li>
                  <li>Gift Box</li>
                  <li>Full time membership</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>

      <Modal className='award' show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Button variant="secondary" onClick={() => setShow1(false)}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17" cy="17" r="17" fill="#3B54DB"/>
              <g clip-path="url(#clip0_3085_2531)">
              <path d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_3085_2531">
              <rect width="12" height="12" fill="white" transform="translate(11 11)"/>
              </clipPath>
              </defs>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-md-6">
              <img src="./images/champion_trofy.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="details">
                <h2>1st Runner up</h2>
                <span className="price">
                75000 ৳
                </span>
                <p>Aliquam mi vestibulum viverra quis elit dictum sit viverra at. Diam nec ultrices ultrices viverra porttitor semper. Aenean arcu lacus etiam vitae. Gravida metus nunc neque commodo.</p>
                <ul>
                  <li>You will receive BDT 1,0000</li>
                  <li>Free Bag</li>
                  <li>A Laptop with software</li>
                  <li>Gift Box</li>
                  <li>Full time membership</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>

      <Modal className='award' show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton>
          <Button variant="secondary" onClick={() => setShow2(false)}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17" cy="17" r="17" fill="#3B54DB"/>
              <g clip-path="url(#clip0_3085_2531)">
              <path d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_3085_2531">
              <rect width="12" height="12" fill="white" transform="translate(11 11)"/>
              </clipPath>
              </defs>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-md-6">
              <img src="./images/champion_trofy.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="details">
                <h2>2nd Runner up</h2>
                <span className="price">
                50000 ৳
                </span>
                <p>Aliquam mi vestibulum viverra quis elit dictum sit viverra at. Diam nec ultrices ultrices viverra porttitor semper. Aenean arcu lacus etiam vitae. Gravida metus nunc neque commodo.</p>
                <ul>
                  <li>You will receive BDT 1,0000</li>
                  <li>Free Bag</li>
                  <li>A Laptop with software</li>
                  <li>Gift Box</li>
                  <li>Full time membership</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>

      <Modal className='award' show={show3} onHide={() => setShow3(false)}>
        <Modal.Header closeButton>
          <Button variant="secondary" onClick={() => setShow3(false)}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="17" cy="17" r="17" fill="#3B54DB"/>
              <g clip-path="url(#clip0_3085_2531)">
              <path d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_3085_2531">
              <rect width="12" height="12" fill="white" transform="translate(11 11)"/>
              </clipPath>
              </defs>
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-md-6">
              <img src="./images/champion_trofy.png" alt="" />
            </div>
            <div className="col-md-6">
              <div className="details">
                <h2>Special Award</h2>
                <span className="price">
                6+ Computer Gadget
                </span>
                <p>Aliquam mi vestibulum viverra quis elit dictum sit viverra at. Diam nec ultrices ultrices viverra porttitor semper. Aenean arcu lacus etiam vitae. Gravida metus nunc neque commodo.</p>
                <ul>
                  <li>You will receive BDT 1,0000</li>
                  <li>Free Bag</li>
                  <li>A Laptop with software</li>
                  <li>Gift Box</li>
                  <li>Full time membership</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InnovationHackathonComponent2022;

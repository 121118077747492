import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



const ChallengesEvent = () => {
  useEffect(() => {
    let eventChallengeTab = document.getElementsByClassName(
      "eventChallengeTab"
    );
    // let challenges_itemLink = document.getElementsByClassName(
    //   "challenges-item"
    // );
    eventChallengeTab[0].style.display = "block";
    // default tab is active
    let challengeItems = document.getElementsByClassName("challenges-item")[0]
    challengeItems.className = challengeItems.className + " active_challange"
    // challenges_itemLink[0].className += " active-challenges_item";
  });
  let openEventChallengeTap = (evt, tabName) => {
    var i, eventChallengeTab, eventlink;
    eventChallengeTab = document.getElementsByClassName("eventChallengeTab");
    console.log(eventChallengeTab);
    for (i = 0; i < eventChallengeTab.length; i++) {
      eventChallengeTab[i].style.display = "none";
    }
    document.getElementById(tabName).style.display = "block";
    //document.getElementById(tabName).className = " active-eventlink";

    let challengeItems = document.getElementsByClassName("challenges-item")
    for (var i = 0; i < challengeItems.length; i++) {
      challengeItems[i].className = challengeItems[i].className.replace(" active_challange", "");
    }
    // debugger
    if (tabName == "eventChallenge1") {
      let c = document.getElementsByClassName("challenges-item--1st")
      c[0].className = c[0].className + " active_challange";
    }
    if (tabName == "eventChallenge2") {
      let c = document.getElementsByClassName("challenges-item--2nd")
      c[0].className = c[0].className + " active_challange";
    }
    if (tabName == "eventChallenge3") {
      let c = document.getElementsByClassName("challenges-item--3rd")
      c[0].className = c[0].className + " active_challange";
    }

    // debugger;
  };
  return (
    <div className="challenges-section" id="challanges">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h5 className="caption text-center">challanges</h5>
            <h2 className="heading text-center">Hackathon challanges</h2>
          </div>
        </div>
        <div className="row challenges_bg">
          <div className="col-4">
            <div
              onClick={(e) => openEventChallengeTap(e, "eventChallenge1")}
              className="challenges-item challenges-item--1st"
            >
              <h3 className="challenges-item__title">Challange: 01</h3>
              <h3 className="challenges-item__txt">
                No <br /> Poverty
              </h3>
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={(e) => openEventChallengeTap(e, "eventChallenge2")}
              className="challenges-item challenges-item--2nd"
            >
              <h3 className="challenges-item__title white-color">
                Challange: 02
              </h3>
              <h3 className="challenges-item__txt">
                Zero <br /> Hunger
              </h3>
            </div>
          </div>
          <div className="col-4">
            <div
              onClick={(e) => openEventChallengeTap(e, "eventChallenge3")}
              className="challenges-item challenges-item--3rd"
            >
              <h3 className="challenges-item__title white-color">
                Challange: 03
              </h3>
              <h3 className="challenges-item__txt">
                Good Health
                <br /> & Well Being
              </h3>
            </div>
          </div>
          <div id="eventChallenge1" className="col-md-12 eventChallengeTab">
          <Tabs>
            <TabList>
              <Tab>Challenge -1</Tab>
              <Tab>Challenge -2</Tab>
              <Tab>Challenge -3</Tab>
              <Tab>Challenge -4</Tab>
              <Tab>Challenge -5</Tab>
              <Tab>Challenge -6</Tab>
            </TabList>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                <div className="challenges__article_item">
                    <div className="article_right">
                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                           Virtual Assistant
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              Virtual Assistant or Chatbot is the new way of digital interaction round the clock. A well designed Chatbot with an intelligent algorithm and NLP can be very effective for almost all organizations.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              End users do not need human intervention for getting general information. They can get a basic guideline about the working process or to do in order to complete or receive a service.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              A Virtual Assistant, providing support direct to the end-user and capable of human interaction with the help of artificial intelligence and NLP.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                          A:  -Certificate Notarization and Verification for Online Learning platform Muktapath
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              At present there is no mechanism for Certificates Verification and  Notarization for the documents produced in online learning platform Muktapath. That will create a scope of Fraudulent activity and misuse of the certificates or to place fake certificates.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              Here the Beneficiaries are mostly citizens or users of Muktapath from the demand side and from the supply side beneficiaries are Muktapath.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">

                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              Online Learning platform Muktapath is creating different certificates on a regular basis. This certificate needs to be created in a systematic way which can be verified and Notarized. Solution should be realistic and Functional with a business perspective.
                            </p>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            </TabPanel>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">

                
            <div className="challenges__article_item">
              <div className="article_right">
                <div className="text_part">
                  <h4 className="challenges__article_item__sub-heading">
                    Automation of Manual Processes
                  </h4>
                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Problem Statement.jpg" />
                    </div>
                    <div className="text_part">

                      <h3 className="challenges__article_item__heading">
                        Problem Statement:
                      </h3>
                      <p className="challenges__article_item__p">
                        Robotic Process Automation has transformed automation of processes in business, operations and technology to a new height of dynamic excellence breaking the boundaries of expectations. More than technology RPA is becoming the synonym of increased productivity, service quality, faster delivery, cost efficiency and so on.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Benificiary 2.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Beneficiary:
                      </h3>
                      <p className="challenges__article_item__p">
                        Less dependency on human activity, faster process, efficient and quality service.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Solution Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Solution Statement:
                      </h3>
                      <p className="challenges__article_item__p">
                        Automation of any existing process (Selected by the participant), which is cumbersome and complex and would require minimal human intervention.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text_part">
                  <h4 className="challenges__article_item__sub-heading">
                    A:  Complete eCommerce Service and Payment cycle using Call Center
                  </h4>
                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Problem Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Problem Statement:
                      </h3>
                      <p className="challenges__article_item__p">
                        At present there is no compact solution to provide a customer an over-call support to complete his purchase including payment in any eCommerce platform. Though there was some over-call support by eCommerce platform for only order confirmation. Again some Financial Institutes are using their call center for online money transfer. But there is no intre connection between the eCommerce platforms and Banks to complete a full purchase cycle including payments. User Authentication and verification are also challenging issues here.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">
                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Benificiary 2.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Beneficiary:
                      </h3>
                      <p className="challenges__article_item__p">
                        Here the Beneficiaries are mostly citizens or users on ecommerce platforms from demand side and from supply side beneficiaries are eCommerce Platforms. Moreover the major stakeholders are financial institutes and regulators.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Solution Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Solution Statement:
                      </h3>
                      <p className="challenges__article_item__p">
                        A complete solution where Customers will be able to purchase and make payment from the eCommerce platform by simply calling in a single short/long code phone no. Automated IVR or on voice agent support is preferable but not mandatory while maintaining the Security and Policy Guidelines. [Functional Idea Prototype is also acceptable]
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">
                  <h4 className="challenges__article_item__sub-heading">
                    B:  Early detection of integrated platform failure
                  </h4>
                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Problem Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Problem Statement:
                      </h3>
                      <p className="challenges__article_item__p">
                        “myGov citizen service delivery platform(mcsdp)” uses “NID verification service(NVS)” to verify citizen’s identity. Citizens cannot apply for service in myGov without verifying their identity using the NID verification service. So if all of a sudden NVS stops working then mcsdp will not be able to provide any service to anyone.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Problem Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Existing process for this scenario is:
                      </h3>
                      <p className="challenges__article_item__p">
                        hundreds of Citizens call/email/report myGov support service <br />
                        Support service do the initial investigation <br />
                        Support service forward the issues to technical team <br />
                        Technical team do the detail investigation and identify the actual cause to designate the responsible individual/team to solve the issue <br />
                        Responsible individual/team solves the issue <br />
                        Citizens can verify their identity again and service becomes up and running again for citizens. <br />
                        But in Reality no one in mcsdp knows about this issue unless it was reported by a citizen.
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Benificiary 2.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Beneficiary:
                      </h3>
                      <p className="challenges__article_item__p">
                        End Users of Integrated Platform (less down time). <br />
                        Integrated platform managers (notified before incident, less down time).
                      </p>
                    </div>
                  </div>

                </div>

                <div className="text_part">

                  <div className="display_flex_part">
                    <div className="img_part">
                      <img src="img/article/Solution Statement.jpg" />
                    </div>
                    <div className="text_part">
                      <h3 className="challenges__article_item__heading">
                        Solution Statement: To detect the problem automatically as soon as it occurs and inform the concerning authority.
                      </h3>
                      <p className="challenges__article_item__p">
                        Problem is identified by an automated process / robot within a few minutes/seconds of the incident. <br />
                        The detecting robot sends notification (sms/email) to responsible persons/teams about the incident with specific details. <br />
                        Within a few minutes the emergency response team starts collaborating/working to resolve the issue. <br />
                        Issue solved with few or no end user noticed. <br />
                      </p>
                    </div>
                  </div>


                </div>

              </div>
            </div>
            </div>
              </div>
            </div>
            </TabPanel>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                  
                
            <div className="challenges__article_item">
                    <div className="article_right">
                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                          Any Server-less Emerging and Innovative Technology
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              Server-less Technology/Cloud Computing has revolutionized the development, incubation and launching of emerging technologies with enormous in-built resources supported by robust, secured and highly flexible/scalable technology infrastructure. You can pick and choose to demonstrate your innovative ideas and thoughts leveraging the benefits of Server-less technologies.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">

                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              More secured system with scalability, less dependency on the server, no monolithic property,  no sevier management issues along with the growth of the application, Smoother and efficient transitions in all platforms.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              An application of choice, which is an innovative and end-to-end solution with serverless technologies.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
            </TabPanel>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                  
                
            <div className="challenges__article_item">
                    <div className="article_right">
                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                          Zero Hunger
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              Rapid economic growth and increased agricultural productivity over the past two decades have seen the number of undernourished people drop by almost half. Many developing countries that used to suffer from famine and hunger can now meet the nutritional needs of the most vulnerable. Central and East Asia, Latin America and the Caribbean have all made huge progress in eradicating extreme hunger. The SDGs aim to end all forms of hunger and malnutrition in Bangladesh by 2030, making sure all people – especially children – have access to sufficient and nutritious food all year round.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">

                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              Decreased food wastage, sharing food in the society to resolve hunger problem making it a better place to live on.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">

                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              An application of choice which will be able to help in achieving this goal.
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
            </TabPanel>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                  
                
            <div className="challenges__article_item">
                    <div className="article_right">
                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                          GOOD HEALTH AND WELL-BEING
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              We have made huge strides in reducing child mortality, improving maternal health and fighting HIV/AIDS, malaria and other diseases. Since 1990, there has been an over 50 percent decline in preventable child deaths globally. Maternal mortality also fell by 45 percent worldwide. New HIV/AIDS infections fell by 30 percent between 2000 and 2013, and over 6.2 million lives were saved from malaria. Still we need major improvement in this sector. As the recent pandemic taught us that we need more help in this area.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              Better preparation to address any major health related threat in future, proper online guidance of how to do things with any health related issues, availability of accurate related resources with locations and contacts.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              An application of choice which will be able to help people with any health related issues with as much automation as possible.
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
            </TabPanel>

            <TabPanel>
            <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                  
               
            <div className="challenges__article_item">
                    <div className="article_right">
                      <div className="text_part">
                        <h4 className="challenges__article_item__sub-heading">
                          QUALITY EDUCATION
                        </h4>
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Problem Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Problem Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              Since 2000, there has been enormous progress in achieving the target of universal primary education. The total enrolment rate in developing regions reached 91 percent in 2015, and the worldwide number of children out of school has dropped by almost half. There has also been a dramatic increase in literacy rates, and many more girls are in school than ever before. These are all remarkable successes. However the ongoing pandemic is taking its toll in our education sector. Many of us are now getting used to the Online Education System. But what about the quality!
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Benificiary 2.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Beneficiary:
                            </h3>
                            <p className="challenges__article_item__p">
                              Availability of quality education, increased literacy rate, less dropouts and all these maintaining a good quality education.
                            </p>
                          </div>
                        </div>

                      </div>

                      <div className="text_part">
                        <div className="display_flex_part">
                          <div className="img_part">
                            <img src="img/article/Solution Statement.jpg" />
                          </div>
                          <div className="text_part">
                            <h3 className="challenges__article_item__heading">
                              Solution Statement:
                            </h3>
                            <p className="challenges__article_item__p">
                              An application of choice which will be able to provide and assess quality education online.
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
            </TabPanel>
          </Tabs>
            {/* <div className="Hack_scroolling">
              <div className="Hack_scrooling_part">
                <div className="challenges__article-wrapper">
                  
                  

                 

                  

                 

                 
                </div>
              </div>
            </div> */}
          </div>
          <div id="eventChallenge2" className="col-md-12 eventChallengeTab">
            <div className="challenges__article-wrapper">
              <div className="challenges__article_item">
                <img src="img/article/articlepic1.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1a1
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Jobs for All
                  </h3>
                  <p className="challenges__article_item__p">
                    A job-sourcing app for those who need it the most. The
                    pandemic has caused many layoffs in the informal sector.
                    There are garment workers, mechanics, construction workers
                    that don’t have access to bdjobs.com. How can we give them
                    access to employment opportunities?
                  </p>
                </div>
              </div>
              <div className="challenges__article_item">
                <img src="img/article/articlepic2.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1b
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Start and ReStart
                  </h3>
                  <p className="challenges__article_item__p">
                    Provide consolidated access to all programs and funds for starting your own business. The government and many private sector companies provide many subsidies and programs to help accelerate the economic growth all over Bangladesh. How can we connect the population across urban and rural areas to these programs?
                  </p>
                </div>
              </div>
              <div className="challenges__article_item">
                <img src="img/article/articlepic3.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1c
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Contactless E-Commerce Solution
                  </h3>
                  <p className="challenges__article_item__p">
                    An e-commerce solution which is contactless for all customers; a platform which is proactive and
                    predictive, and will elevate small businesses by solving their e-commerce challenges. Ex: website,
                    digital payment, account verification, delivery and logistics.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="eventChallenge3" className="col-md-12 eventChallengeTab">
            <div className="challenges__article-wrapper">
              <div className="challenges__article_item">
                <img src="img/article/articlepic1.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1a3
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Jobs for All
                  </h3>
                  <p className="challenges__article_item__p">
                    A job-sourcing app for those who need it the most. The
                    pandemic has caused many layoffs in the informal sector.
                    There are garment workers, mechanics, construction workers
                    that don’t have access to bdjobs.com. How can we give them
                    access to employment opportunities?
                  </p>
                </div>
              </div>
              <div className="challenges__article_item">
                <img src="img/article/articlepic2.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1b
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Start and ReStart
                  </h3>
                  <p className="challenges__article_item__p">
                    Provide consolidated access to all programs and funds for starting your own business. The government and many private sector companies provide many subsidies and programs to help accelerate the economic growth all over Bangladesh. How can we connect the population across urban and rural areas to these programs?
                  </p>
                </div>
              </div>
              <div className="challenges__article_item">
                <img src="img/article/articlepic3.png" />
                <div className="article_right">
                  <h4 className="challenges__article_item__sub-heading">
                    Challenge 1c
                  </h4>
                  <h3 className="challenges__article_item__heading">
                    Contactless E-Commerce Solution
                  </h3>
                  <p className="challenges__article_item__p">
                    An e-commerce solution which is contactless for all customers; a platform which is proactive and
                    predictive, and will elevate small businesses by solving their e-commerce challenges. Ex: website,
                    digital payment, account verification, delivery and logistics.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengesEvent;

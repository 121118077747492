import React from "react";
import Layout from "../../components/common/layout/layout";
import InquiryComponent from "../../components/inquiry/inquiry";

export default function Inquiry() {
    return (
        <Layout showBanner={false}>
            <InquiryComponent />
        </Layout>
    );
}

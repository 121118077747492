export default function Benefits() {
    return (
        <>
            <div className="benifits_part">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <h2>What Benefits Do You Receive?</h2>
                        </div>
                    </div>
                    <div className="row margin xs-hidden">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item active">
                                <img src="images/Connecting-With-the-top-CTOs.png" alt="" />
                                <h4>
                                Connecting With the top CTOs
                                </h4>
                                <p>
                                It’s your network that may be a factor changes your way of being ambitious. Connect to the experienced CTOs, explore the experiences and journey. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item">
                                <img src="images/Access-to-the-innovation-centre.png" alt="" />
                                <h4>
                                    Access to the innovation centre
                                </h4>
                                <p>
                                Innovation finds the keys to futuristic solution. CTO forum let you access to the innovation center; where Ideas grow, greatness create. 
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <div className="benifits_padding benifit_item">
                                <img src="images/Resource-and-Insight-Privilege.png" alt="" />
                                <h4>
                                Resource and Insight Privilege
                                </h4>
                                <p>
                                The way of innovating, resources you need can be found here with the data and insights-what makes your journey easy and understandable? 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="benifits_part_slider margin xl-hidden">
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                        <div className="benifits_padding benifit_item active">
                            <img src="images/benifit_2.png" alt="" />
                            <h4>
                                Access to the <br /> innovation centre
                            </h4>
                            <p>
                                Dis vel congue amet, tempus enim pretium nullam. Duis et ac
                                interdum adipiscing sit et fermentum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

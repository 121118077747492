/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "ap-southeast-1",
//     "aws_cognito_identity_pool_id": "ap-southeast-1:c7e6e201-f5ba-4883-8d6f-6c899bd0097e",
//     "aws_cognito_region": "ap-southeast-1",
//     "aws_user_pools_id": "ap-southeast-1_L47BvNSrv",
//     "aws_user_pools_web_client_id": "3pgekj17vgtpdg5la2p24is5r3",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://yh6wtryednejro73mvkqk2wdne.appsync-api.ap-southeast-1.amazonaws.com/graphql",
//     "aws_appsync_region": "ap-southeast-1",
//     "aws_appsync_authenticationType": "API_KEY",
//     "aws_appsync_apiKey": "da2-s2gn2kfzzrcgpali62hxu62pkm",
//     "aws_user_files_s3_bucket": "ctostaticbucket172404-dev",
//     "aws_user_files_s3_bucket_region": "ap-southeast-1"
// };


// export default awsmobile;

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    //"aws_cognito_identity_pool_id": "ap-southeast-1:c7e6e201-f5ba-4883-8d6f-6c899bd0097e", 
    "aws_cognito_identity_pool_id": "ap-southeast-1:1116a9f3-c106-4eb5-82fd-425b15f59d87", //newone
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_L47BvNSrv",
    //"aws_user_pools_id": "ap-southeast-1_Qu6gXFWHM", //newone
    //"aws_user_pools_web_client_id": "3pgekj17vgtpdg5la2p24is5r3",
    "aws_user_pools_web_client_id": "6ml7eetunu5p65efomm453dstd",
    //"aws_user_pools_web_client_id": "65mldo7fovdc4lki2jt7ejhcki", //newone
    "oauth": {},
    //"aws_appsync_graphqlEndpoint": "https://yh6wtryednejro73mvkqk2wdne.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_graphqlEndpoint": "https://3p2rgfp2hva6vbybz3qxitpnvy.appsync-api.ap-southeast-1.amazonaws.com/graphql", //newone
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-xx5qinfdibfitfoxc52eojccma",
    //"aws_user_files_s3_bucket": "ctostaticbucket172404-dev",
    "aws_user_files_s3_bucket": "ctostaticbucket183112-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;

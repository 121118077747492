import React, { useEffect } from 'react';
import Navbar from '../common/navbar/navbar';





const InnovationHackathonGallery2021 = () => {

    // const pathddd = require('../../images/hack2021/1.png');

const photos = [
    {
        src: require('../../images/hack2021/1.png')
    },
    {
        src: require('../../images/hack2021/2.png')
    },
    {
        src: require('../../images/hack2021/3.png')
    },
    {
        src: require('../../images/hack2021/4.png')
    },
    {
        src: require('../../images/hack2021/5.png')
    },
    {
        src: require('../../images/hack2021/6.png')
    },
    {
        src: require('../../images/hack2021/7.png')
    },
    {
        src: require('../../images/hack2021/8.png')
    },
    {
        src: require('../../images/hack2021/9.png')
    },
    {
        src: require('../../images/hack2021/10.png')
    },
    {
        src: require('../../images/hack2021/11.png')
    },
    {
        src: require('../../images/hack2021/12.png')
    },
    {
        src: require('../../images/hack2021/13.png')
    },
    {
        src: require('../../images/hack2021/14.png')
    },
    
];

    useEffect(() => {
        console.log('hhhhh', photos);
    })

    return (
        <>
        <Navbar/>
        <div className="container-fluid">
            <div className="Galery_part_Rasel">
                <div className="gallery-page">
                    <div className="allbum">
                        <div className="text">
                            <h2>Images of Our Events</h2>
                        </div>
                        <div className="row">
                            {
                                photos.map(photo =>
                                    <div className='col-md-3'>
                                        <img src={photo.src.default} alt="" /> 
                                    </div>)
                            }
                            {/* <div className='col-md-3'>
                                        <img src={pathddd.default}
                                         alt="ss"
                                        /> 
                                    </div> */}
                            <div className='col-md-3'>
                                <div className="load">
                                    <p>Load All Images</p>
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="30" cy="30" r="30" fill="white" fill-opacity="0.1" />
                                        <path d="M40 26.3583L37.6416 24L30 31.6416L22.3584 24L20 26.3583L30 36.3583L40 26.3583Z" fill="white" />
                                    </svg>

                                </div>
                            </div>
                        </div>



                    </div>


                    <div className="allbum video_gal">
                        <div className="text">
                            <h2>Videos of Our Events</h2>

                        </div>
                        <div className="row">
                            {
                                photos.map(photo =>
                                    <div className='col-md-3'>
                                        <img src={photo.src.default} alt="" />
                                        <div className="overlay">
                                        <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="42" cy="42" r="36" fill="white"/>
<path d="M71.6992 12.3008C55.2982 -4.10026 28.7018 -4.10026 12.3008 12.3008C-4.10026 28.7018 -4.10026 55.2982 12.3008 71.6992C28.7018 88.1003 55.2982 88.1003 71.6992 71.6992C88.1003 55.2982 88.1003 28.7018 71.6992 12.3008ZM60.3514 44.571L47.2306 52.1066L34.3757 59.5536C33.9325 59.9082 33.3119 60.0855 32.6913 60.0855C31.0069 60.0855 29.677 58.7557 29.677 57.0712V42V26.8401C29.677 26.3082 29.7657 25.8649 30.0317 25.333C30.8295 23.9145 32.6913 23.3826 34.1098 24.2691L47.0533 31.7161C47.142 31.7161 47.142 31.8047 47.2306 31.8047L60.3514 39.3404C60.7947 39.6063 61.1493 39.9609 61.504 40.4042C62.3018 41.9113 61.7699 43.7731 60.3514 44.571Z" fill="#3B54DB"/>
</svg>


                                        </div>
                                    </div>)
                            }
                            <div className='col-md-3'>
                                <div className="load">
                                    <p>Load All Images</p>
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="30" cy="30" r="30" fill="white" fill-opacity="0.1" />
                                        <path d="M40 26.3583L37.6416 24L30 31.6416L22.3584 24L20 26.3583L30 36.3583L40 26.3583Z" fill="white" />
                                    </svg>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        </>
    );
};

export default InnovationHackathonGallery2021;
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { updateEducation, updateMember } from "../../graphql/mutations";

export default function EducationModal({ setModal, educationData, setEducationData }) {
    const [qualification, setQualification] = useState();
    const [institution, setInstitution] = useState();
    const [board, setBoard] = useState();
    const [passingYear, setPassingYear] = useState();

    useEffect(() => {
        setQualification(educationData.qualification);
        setInstitution(educationData.institution);
        setBoard(educationData.board);
        setPassingYear(educationData.passingYear);
    }, []);

    const save = async () => {
        let updateEducationData = await API.graphql({
            query: updateEducation,
            variables: {
                input: {
                    id: educationData.id,
                    qualification,
                    institution,
                    board,
                    passingYear,
                },
            },
        });

        setEducationData({
            ...educationData,
            qualification,
            institution,
            board,
            passingYear,
        });
         setModal(false);
    };

    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Edit Last Education
                        </h5>
                        <button
                            type="button"
                            onClick={() => setModal(false)}
                            classname="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="17" r="17" fill="#3B54DB" />
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(11 11)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="about_part">
                            <div className="about_intro mb-4">
                                <h4>Qualification</h4>
                                <input
                                    class="form-control"
                                    placeholder="Tell something about you"
                                    value={qualification}
                                    onChange={(e) => setQualification(e.target.value)}
                                />
                            </div>
                            <div className="about_intro mb-4">
                                <h4>Institution</h4>
                                <input
                                    class="form-control"
                                    value={institution}
                                    onChange={(e) => setInstitution(e.target.value)}
                                />
                            </div>
                            <div className="about_intro mb-4">
                                <h4>Board</h4>
                                <input
                                    class="form-control"
                                    value={board}
                                    onChange={(e) => setBoard(e.target.value)}
                                />
                            </div>
                            <div className="about_intro mb-4">
                                <h4>Passing Year</h4>
                                <input
                                    class="form-control"
                                    value={passingYear}
                                    onChange={(e) => setPassingYear(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" data-dismiss="modal"
                        aria-label="Close" className="btn btn-primary" onClick={save}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

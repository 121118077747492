import React from "react";

export default function EventsComponent() {
    return (
        <div>
            <div className="events_bg banner_bg">
                <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src="images/magazine/upcoming-event.jpg"
                                className="d-block w-100"
                                alt="..."
                            />
                        </div>
                    </div>
                    <div className="carousel-caption d-md-block">
                        <div className="event-desc about-desc">
                            <h2 className="animate__animated animate__bounceInDown">
                                Upcoming event title
                            </h2>
                            <span className="d-block animate__animated animate__bounceInUp">
                                will be here
                            </span>
                            <div className="event-list-item animate__animated animate__bounceInUp">
                                <ul>
                                    <li>
                                        <span className="event-icon">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fal"
                                                data-icon="globe"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 496 512"
                                                className="svg-inline--fa fa-globe fa-w-16 fa-5x"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"
                                                    className=""
                                                ></path>
                                            </svg>
                                        </span>
                                        dhaka, bangladesh{" "}
                                    </li>
                                    <li>
                                        <span className="event-icon">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fal"
                                                data-icon="calendar-alt"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                className="svg-inline--fa fa-calendar-alt fa-w-14 fa-5x"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
                                                    className=""
                                                ></path>
                                            </svg>
                                        </span>
                                        January 5 to 9, 2019
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="cto-event-area">
                <div className="enent-middle">
                    <div className="event-heading">
                        <h2>Upcoming event</h2>
                    </div>
                    <div className="event-single-main">
                        <div className="event-single-left">
                            <img src="images/magazine/event1.jpg" alt="img" />
                        </div>
                        <div className="event-single-right">
                            <div className="enent-top-desc">
                                <h3>CTO Forum innvation hackathon 2021</h3>
                                <div className="event-list-item2 event-list-item">
                                    <ul>
                                        <li>
                                            <span className="event-icon">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fal"
                                                    data-icon="globe"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 496 512"
                                                    className="svg-inline--fa fa-globe fa-w-16 fa-5x"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"
                                                        className=""
                                                    ></path>
                                                </svg>
                                            </span>
                                            dhaka, bangladesh{" "}
                                        </li>
                                        <li>
                                            <span className="event-icon">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fal"
                                                    data-icon="calendar-alt"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                    className="svg-inline--fa fa-calendar-alt fa-w-14 fa-5x"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
                                                        className=""
                                                    ></path>
                                                </svg>
                                            </span>
                                            January 5 to 9, 2019
                                        </li>
                                    </ul>
                                </div>
                                <p className="event-lorem-desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                                    ipsum suspendisse ultrices gravida. Risus commodo viverra
                                    maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Quis ipsum
                                    suspendisse ultrices gravida. Risus commodo viverra maecenas
                                    accumsan lacus vel facilisis.{" "}
                                </p>
                            </div>

                            <div className="key-point">
                                <h4>Key point</h4>
                                <div className="key-list-item">
                                    <ul>
                                        <li>
                                            <span>
                                                <i className="fab fa-angellist"></i>
                                            </span>{" "}
                                            Ipsum dolor sit amet, consectetur adipiscing elit
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fab fa-angellist"></i>
                                            </span>{" "}
                                            Ipsum dolor sit amet, consectetur{" "}
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fab fa-angellist"></i>
                                            </span>{" "}
                                            Ipsum dolor sit amet, consectetur adipiscing elit
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fab fa-angellist"></i>
                                            </span>{" "}
                                            Ipsum dolor sit amet, consectetur adipiscing
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="chief-gust-area">
                                <h4>Chief Guest</h4>

                                <div className="chief-gust-single-main">
                                    <div className="guest-single">
                                        <img src="images/magazine/man.jpg" alt="img" />
                                        <h5>Name Here</h5>
                                        <span>Designation</span>
                                    </div>
                                    <div className="guest-single">
                                        <img src="images/magazine/man.jpg" alt="img" />
                                        <h5>Name Here</h5>
                                        <span>Designation</span>
                                    </div>
                                    <div className="guest-single">
                                        <img src="images/magazine/man.jpg" alt="img" />
                                        <h5>Name Here</h5>
                                        <span>Designation</span>
                                    </div>
                                    <div className="guest-single">
                                        <img src="images/magazine/man.jpg" alt="img" />
                                        <h5>Name Here</h5>
                                        <span>Designation</span>
                                    </div>
                                    <div className="guest-single">
                                        <img src="images/magazine/man.jpg" alt="img" />
                                        <h5>Name Here</h5>
                                        <span>Designation</span>
                                    </div>
                                </div>
                            </div>

                            <div className="mag-social-link">
                                <ul>
                                    <li>
                                        <a href="/">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="ext-row-event">
                        <div className="event-heading">
                            <h2>Previous event</h2>
                        </div>
                        <div className="event-single-main2 event-single-main">
                            <div className="event-single-right2 event-single-right">
                                <div className="enent-top-desc2 enent-top-desc">
                                    <h3>CTO Forum innvation hackathon 2021</h3>
                                    <div className="event-list-item2 event-list-item">
                                        <ul>
                                            <li>
                                                <span className="event-icon">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fal"
                                                        data-icon="globe"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 496 512"
                                                        className="svg-inline--fa fa-globe fa-w-16 fa-5x"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm193.2 152h-82.5c-9-44.4-24.1-82.2-43.2-109.1 55 18.2 100.2 57.9 125.7 109.1zM336 256c0 22.9-1.6 44.2-4.3 64H164.3c-2.7-19.8-4.3-41.1-4.3-64s1.6-44.2 4.3-64h167.4c2.7 19.8 4.3 41.1 4.3 64zM248 40c26.9 0 61.4 44.1 78.1 120H169.9C186.6 84.1 221.1 40 248 40zm-67.5 10.9c-19 26.8-34.2 64.6-43.2 109.1H54.8c25.5-51.2 70.7-90.9 125.7-109.1zM32 256c0-22.3 3.4-43.8 9.7-64h90.5c-2.6 20.5-4.2 41.8-4.2 64s1.5 43.5 4.2 64H41.7c-6.3-20.2-9.7-41.7-9.7-64zm22.8 96h82.5c9 44.4 24.1 82.2 43.2 109.1-55-18.2-100.2-57.9-125.7-109.1zM248 472c-26.9 0-61.4-44.1-78.1-120h156.2c-16.7 75.9-51.2 120-78.1 120zm67.5-10.9c19-26.8 34.2-64.6 43.2-109.1h82.5c-25.5 51.2-70.7 90.9-125.7 109.1zM363.8 320c2.6-20.5 4.2-41.8 4.2-64s-1.5-43.5-4.2-64h90.5c6.3 20.2 9.7 41.7 9.7 64s-3.4 43.8-9.7 64h-90.5z"
                                                            className=""
                                                        ></path>
                                                    </svg>
                                                </span>
                                                dhaka, bangladesh{" "}
                                            </li>
                                            <li>
                                                <span className="event-icon">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fal"
                                                        data-icon="calendar-alt"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                        className="svg-inline--fa fa-calendar-alt fa-w-14 fa-5x"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"
                                                            className=""
                                                        ></path>
                                                    </svg>
                                                </span>
                                                January 5 to 9, 2019
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="event-lorem-desc">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                                        commodo viverra maecenas accumsan lacus vel facilisis. Lorem
                                        ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        Quis ipsum suspendisse ultrices gravida. Risus commodo
                                        viverra maecenas accumsan lacus vel facilisis.{" "}
                                    </p>
                                </div>

                                <div className="key-point">
                                    <h4>Key point</h4>
                                    <div className="key-list-item">
                                        <ul>
                                            <li>
                                                <span>
                                                    <i className="fab fa-angellist"></i>
                                                </span>{" "}
                                                Ipsum dolor sit amet, consectetur adipiscing elit
                                            </li>
                                            <li>
                                                <span>
                                                    <i className="fab fa-angellist"></i>
                                                </span>{" "}
                                                Ipsum dolor sit amet, consectetur{" "}
                                            </li>
                                            <li>
                                                <span>
                                                    <i className="fab fa-angellist"></i>
                                                </span>{" "}
                                                Ipsum dolor sit amet, consectetur adipiscing elit
                                            </li>
                                            <li>
                                                <span>
                                                    <i className="fab fa-angellist"></i>
                                                </span>{" "}
                                                Ipsum dolor sit amet, consectetur adipiscing
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="chief-gust-area">
                                    <h4>Chief Guest</h4>

                                    <div className="chief-gust-single-main">
                                        <div className="guest-single">
                                            <img src="images/magazine/man.jpg" alt="img" />
                                            <h5>Name Here</h5>
                                            <span>Designation</span>
                                        </div>
                                        <div className="guest-single">
                                            <img src="images/magazine/man.jpg" alt="img" />
                                            <h5>Name Here</h5>
                                            <span>Designation</span>
                                        </div>
                                        <div className="guest-single">
                                            <img src="images/magazine/man.jpg" alt="img" />
                                            <h5>Name Here</h5>
                                            <span>Designation</span>
                                        </div>
                                        <div className="guest-single">
                                            <img src="images/magazine/man.jpg" alt="img" />
                                            <h5>Name Here</h5>
                                            <span>Designation</span>
                                        </div>
                                        <div className="guest-single">
                                            <img src="images/magazine/man.jpg" alt="img" />
                                            <h5>Name Here</h5>
                                            <span>Designation</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="event-single-left2 event-single-left">
                                <img src="images/magazine/event1.jpg" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

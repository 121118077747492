import React from 'react';
import Layout from '../../components/common/layout/layout';
import GalleryMoreDetailsInovationComponent from '../../components/gallery/GalleryMoreDetailsInovationComponent'

const GalleryMoreDetailsInovation = () => {
    return (
        <Layout showBanner={false}>
            <GalleryMoreDetailsInovationComponent />
        </Layout>
    );
};

export default GalleryMoreDetailsInovation;
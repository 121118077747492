import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { updateMember } from "../../graphql/mutations";

export default function AwardModal({ setModal, memberData, awardData, setAwardData }) {
    const [awards, setAwards] = useState([
        { awardName: "", year: "", description: "" },
        { awardName: "", year: "", description: "" },
        { awardName: "", year: "", description: "" },
        { awardName: "", year: "", description: "" },
        { awardName: "", year: "", description: "" },
    ]);

    useEffect(() => {
        console.log("============");
        console.log(awardData);
        console.log("============");
        if (awardData.length > 0) {
            setAwards(awardData);
        }
    }, []);

    const handleInputChange = (e, id, fieldName) => {
        let d = awards;
        d[id][fieldName] = e.target.value;
        setAwards([...d]);
    };

    const save = async () => {
        let update = await API.graphql({
            query: updateMember,
            variables: {
                input: {
                    id: memberData.id,
                    awards: JSON.stringify(awards),
                },
            },
        });
        // debugger;
        // console.log(awards);

        setAwardData(awards);
        // setCertificates(certificates);
        setModal(false);
    };
    console.log(awards, '-------------------------------awards')
    return (
        <div
            className="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            Add / Edit Awards
                        </h5>
                        <button
                            type="button"
                            onClick={() => setModal(false)}
                            classname="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="17" r="17" fill="#3B54DB" />
                                <g clip-path="url(#clip0)">
                                    <path
                                        d="M23 12.4141L21.5859 11L17 15.5859L12.4141 11L11 12.4141L15.5859 17L11 21.5859L12.4141 23L17 18.4141L21.5859 23L23 21.5859L18.4141 17L23 12.4141Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                            transform="translate(11 11)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* <p>{JSON.stringify(certificates)}</p> */}
                        <div className="about_part">
                            {awards &&
                                awards.map((award, index) => (
                                    <div>
                                        <div className="about_intro mb-4">
                                            <h4>Award Name</h4>
                                            <input
                                                class="form-control"
                                                value={award.awardName}
                                                onChange={(e) =>
                                                    handleInputChange(e, index, "awardName")
                                                }
                                            />
                                        </div>
                                        <div className="about_intro mb-4">
                                            <h4>Year</h4>
                                            <input
                                                class="form-control"
                                                value={award.year}
                                                onChange={(e) =>
                                                    handleInputChange(e, index, "year")
                                                }
                                            />
                                        </div>
                                        <div className="about_intro mb-4">
                                            <h4>Description</h4>
                                            <textarea
                                                class="form-control"
                                                rows="3"
                                                value={award.description}
                                                onChange={(e) =>
                                                    handleInputChange(e, index, "description")
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={save}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
